<template>
	<div 
		v-if="!meetingHandler.meeting.chat.isStarted"
		class="alert alert-danger" 
		role="alert">
		It looks like we experienced an error when loading chat. Please refresh your browser and rejoin the meeting to use this feature.
	</div>

	<div v-else class="chat-room">
		<div class="chat-messages" ref="chatMessagesDiv" @scroll="chatScrolling">
			<div id="contents" ref="contents" class="col-12 content-box position-relative">
				<div v-if="meetingChatEnabled">
					<inline-loader
						v-if="activeChatChannelId == 'default' && meetingHandler.defaultHasMoreMessages"
						message="Loading more messages..." />
					<chat-message
						v-for="(message, idx) in filteredMessages"
						ref="messages"
						:key="message.id"
						:message="message"
						:previous-message="activeChannel?.messages[idx - 1]"
						@preview="openPreview"></chat-message>
				</div>
			</div>
		</div>
		<div class="col-auto">
			<div class="col-12">
				<div class="input-group" v-if="canToggleChat">
					<button
						id="btn-disable-chat"
						class="btn btn-default btn-disable-chat"
						type="button"
						v-on:click="toggleChat">
						{{ meetingChatEnabled ? "Disable chat" : "Enable chat" }}
					</button>
				</div>
				<div v-if="!meetingChatEnabled" id="disable-chat" class="disable-chat text-center">
					<span style="margin: 10px 30px 10px 30px; color: black"> Chat has been disabled. </span>
				</div>
				<div v-else class="d-flex chat-controls-container">
					<div
						id="dropzone"
						ref="dropzone"
						:class="dropzoneClass"
						@dragenter="areaDragEnter"
						@dragleave="areaDragLeave"
						@dragover.prevent
						@drop="areaDrop">
						<div v-if="dragging" class="drag-text dragging">Drag file here</div>
						<div v-if="dragover" class="drag-text on">Drop file</div>
						<div class="d-flex chat-users-container">
							<div class="d-flex mb-2 align-items-center" style="color: black; margin-left: 0.5em">
								<span style="font-family: Inter_Bold; margin-right: 5px">To:</span>
								<select
									class="form-select text-white chat-select"
									aria-label="Default select example"
									v-model="activeAttendeeId"
									v-on:mousedown="chatAttendeesClicked">
									<option
										value="default"
										:key="'default'"
										:class="hasNewMessageForAttendee('default') ? 'pulse' : ''">
										Everyone
										<span v-if="hasNewMessageForAttendee('default')"> (*) </span>
									</option>
									<option
										v-for="attendee in possibleSendToAttendees"
										:key="attendee.id"
										:value="attendee.id"
										:class="hasNewMessageForAttendee(attendee.id) ? 'pulse' : ''">
										{{ attendee.displayName }}
										<span
											v-if="hasNewMessageForAttendee(attendee.id)"
											style="color: red; font-weight: bold">
											(*)
										</span>
									</option>
								</select>
							</div>
							<div class="d-flex">
								<textarea
									autofocus
									ref="chatInput"
									type="text"
									class="chat-input"
									:disabled="isChannelCreating"
									autocomplete="off"
									:placeholder="!isChannelCreating ? 'Type message here...' :''"
									v-on:keydown.enter.prevent="sendChatMessage()"
									v-model="chatMessage"></textarea>
							</div>
						</div>
						<div class="d-flex chat-actions">
							<div v-if="chatAttachmentsEnabled" class="d-flex chat-file-container position-relative">
								<button
									id="btn-attach-file"
									class="btn btn-default chat-send-btn"
									type="button"
									v-on:click="pickFile()">
									<i class="fal fa-paperclip"></i>
								</button>
								<form ref="fileForm">
									<input
										id="file-input"
										type="file"
										ref="fileInput"
										multiple
										v-on:change="chatFileChanged"
										:disabled="isChannelCreating"
										class="chat-file"
										accept="image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats,application/zip,application/json" />
								</form>
								<div
									class="files-count"
									v-if="filesCount > 0"
									:title="filesCount + ' Attached file(s)'">
									{{ filesCount }}
								</div>
							</div>
							<div class="d-flex send-container">
								<button
									id="btn-send-chat"
									class="btn btn-default chat-send-btn"
									:class="filesCount > 0 ? 'pulse-button' : ''"
									:disabled="isChannelCreating"
									type="button"
									v-on:click="sendChatMessage()"
									ref="sendMessage">
									<i class="fab fa-telegram-plane"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="fade">
			<div
				class="modal fade show"
				id="modal-show-img"
				tabindex="-1"
				role="dialog"
				@click.self="previewMessage = undefined; previewMessageUrl = undefined;"
				v-show="previewMessage"
				aria-modal="true">
				<div class="modal-dialog modal-xl modal-img" role="document">
					<div class="modal-content modal-content-img">
						<button
							type="button"
							class="close close-channel close-full-img"
							data-dismiss="modal"
							@click="previewMessage = undefined; previewMessageUrl = undefined;"
							aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						<div class="row full-img-wrapper">
							<img
								v-if="previewMessage?.isImage"
								:alt="previewMessage?.fileName"
								:src="previewMessage.inlineContent"
								id="full-img"
								class="full-img" />
							<video
								v-else-if="previewMessage?.isVideo"
								:src="previewMessageUrl"
								controls
								autoplay
								class="full-img">
								<source :src="previewMessageUrl" type="video/mp4;" />
							</video>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts" setup>
	import { computed, onMounted, ref, watch, nextTick, type PropType } from "vue";
	import ChatMessage from "./ChatMessage.vue";
	import InlineLoader from "./InlineLoader.vue";
	import type { ChatMessage as ChatMessageSdk } from "@liveswitch/sdk";
	import {
		toggleChatEnableAsync,
		chatEnabled,
		chatOpened,
		chatDirty,
		isChannelCreating,
		hasNewMessageForAttendee,
		activeChannel,
		resetNewChatMessages,
		sendMessageAsync,
		activeAttendeeId,
		possibleSendToAttendees,
		canToggleMeetingChat,
		loadPreviousMessagesForActiveChannelAsync,
		setupNewMessageCallback,
		activeChatChannelId,
		chatAttachmentsEnabled,
	} from "@/composables/useChat";
	import Swal from "sweetalert2";
	import useEventBus from "@/composables/useEventBus";
	import type MeetingHandler from "@/classes/MeetingHandler";

	const filesCount = ref(0);
	const chatMessage = ref("");
	const chatMessagesDiv = ref<HTMLDivElement>();
	const sendMessage = ref<HTMLButtonElement>();
	const MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;
	const attachment = ref<File>();
	const chatInput = ref<HTMLTextAreaElement>();
	const fileInput = ref<HTMLInputElement>();
	const fileForm = ref<HTMLFormElement>();
	const dragover = ref(false);
	const dragging = ref(false);
	const dragCounter = ref(0);
	const dropCounter = ref(0);
	let chatFiles: File[] = [];
	const fileWhitelistRegex =
		/(image\/|video\/|application\/pdf|application\/msword|application\/vnd\.openxmlformats|application\/zip|application\/json)/;

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	const canToggleChat = computed(() => {
		return false
		return (
			props.meetingHandler?.localAttendee?.role == "HOST" ||
			props.meetingHandler?.localAttendee?.role == "MODERATOR"
		);
	});

	const meetingChatEnabled = computed(() => {
		return props.meetingHandler.chatEnabled;
	});

	const filteredMessages = computed(() => {
		var messages = activeChannel.value?.messages.filter((x) => x.text?.indexOf("so_cmd") == -1);
		return messages;
	});

	const dropzoneClass = computed(() => {
		let classes = "dropzone bordered";
		classes += dragging.value ? " dragging" : "";
		classes += dragover.value ? " on" : "";
		return classes;
	});

	setupNewMessageCallback(async (channelId: string) => {
		if (chatOpened.value && activeChatChannelId.value === channelId) {
			if (chatMessagesDiv.value) {
				await nextTick();

				if (chatMessagesDiv.value.offsetHeight === chatMessagesDiv.value.scrollHeight) {
					resetNewChatMessages();
				} else if (
					chatMessagesDiv.value.offsetHeight + chatMessagesDiv.value.scrollTop >=
					chatMessagesDiv.value.scrollHeight - 100
				) {
					await nextTick();
					chatMessagesDiv.value.scrollTop = chatMessagesDiv.value.scrollHeight;
				}
			}
		}
	});

	const scrollDownHandler = async () => {
		// if the panel isn't open we don't need to scroll
		if (!props.meetingHandler.sidePanelOpen) {
			return;
		}
		await nextTick();

		if (chatMessagesDiv.value) {
			chatMessagesDiv.value.scrollTop = chatMessagesDiv.value.scrollHeight - chatMessagesDiv.value.offsetHeight;
		}

		if (chatEnabled.value) {
			resetNewChatMessages();
		}
	};

	const previewMessage = ref<ChatMessageSdk>();
	const previewMessageUrl = ref<string>();


	onMounted(async () => {
		await scrollDownHandler();

		useEventBus().onEvent("toggle-chat", async () => {
			await scrollDownHandler();

			setTimeout(() => {
				chatInput.value?.focus();
			}, 500);
		});

		document.body.addEventListener("dragenter", (e) => {
			e.preventDefault();
			++dragCounter.value;
			dragging.value = true;
		});

		document.body.addEventListener("dragleave", (e) => {
			e.preventDefault();
			--dragCounter.value;
			if (dragCounter.value == 0) {
				dragging.value = false;
			}
		});

		document.body.addEventListener("dragover", (e) => {
			e.preventDefault();
		});

		document.body.addEventListener("drop", (e) => {
			if (dragging.value && !dragover.value) {
				e.preventDefault();
				dragging.value = false;
				dragCounter.value = 0;
				dropCounter.value = 0;
			}
		});
	});

	watch(activeAttendeeId, async () => {
		scrollDownHandler();
	});

	async function toggleChat() {
		await toggleChatEnableAsync();
		await nextTick();
		scrollDownHandler();
		filesCount.value = 0;
	}

	function chatScrolling() {
		if (chatMessagesDiv.value) {
			if (chatMessagesDiv.value.scrollTop === 0) {
				// Scrolled to top
				chatMessagesDiv.value.scrollTop = 5;
				loadPreviousMessagesForActiveChannelAsync();
			}
			if (
				chatMessagesDiv.value.offsetHeight + chatMessagesDiv.value.scrollTop >=
				chatMessagesDiv.value.scrollHeight
			) {
				// Scrolled to bottom
				resetNewChatMessages();
			}
		}
	}

	function openPreview(m: ChatMessageSdk) {
		previewMessage.value = m;
		previewMessageUrl.value = URL.createObjectURL(m.file);
	}

	function pickFile() {
		const fileInput = document.querySelector("#file-input") as HTMLInputElement;
		fileInput.click();
	}

	function chatFileChanged(e: Event) {
		const inputElement = e.target as HTMLInputElement;
		filesCount.value = inputElement.files?.length ?? 0;

		if (inputElement.files && filesCount.value > 0) {
			const file = inputElement.files[0];
			attachment.value = file;

			if (file.size && file.size > MAX_FILE_SIZE_BYTES) {
				Swal.fire({
					title: "Error",
					text: "The file is too large. Maximum upload file size: 5 MB",
					confirmButtonText: "Close",
				});

				inputElement.value = "";
				filesCount.value = 0;
				fileForm.value?.reset();
				attachment.value = undefined;
			}

			sendMessage.value?.focus();
		}
	}

	async function sendChatMessage() {
		if (!chatMessage.value && !filesCount.value) {
			// No files and no message
			return;
		}

		let files: File[] = [];
		const fileInput = document.querySelector("#file-input") as HTMLInputElement;

		if (filesCount.value > 0) {
			for (let i = 0; i < filesCount.value; i++) {
				const file = fileInput.files?.item(i);
				if (file) {
					files.push(file);
				}
			}
		}

		if (files.length == 0 && attachment.value) {
			files.push(attachment.value);
		}

		// add the drag and drop files
		if (chatFiles.length > 0) {
			for (let i = 0; i < chatFiles.length; i++) {
				files.push(chatFiles[i]);
			}
		}

		const messageSendPromise = sendMessageAsync(chatMessage.value, files, activeAttendeeId.value);
		chatMessage.value = "";
		fileInput.files = null;
		fileInput.value = "";
		filesCount.value = 0;
		chatFiles = [];
		attachment.value = undefined;
		await messageSendPromise;
		scrollDownHandler();
	}

	function chatAttendeesClicked() {
		chatDirty.value = !chatDirty.value;
	}

	watch(activeChannel, async () => {
		scrollDownHandler();
		setTimeout(() => {
			scrollDownHandler();
		}, 1000);
	});

	function areaDragEnter(e) {
		++dropCounter.value;
		e.preventDefault();
		dragover.value = true;
	}
	function areaDragLeave(e) {
		--dropCounter.value;
		e.preventDefault();
		if (dropCounter.value == 0) {
			dragover.value = false;
		}
	}
	function areaDrop(e) {
		e.preventDefault();
		dragover.value = false;
		dragging.value = false;
		dropCounter.value = 0;
		dragCounter.value = 0;
		filesCount.value -= chatFiles.length;
		chatFiles = [];
		if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			const fileSize = [...e.dataTransfer.files].map((f) => f.size).reduce((a, b) => a + b);
			if (e.dataTransfer.files.length > 1 && fileSize > MAX_FILE_SIZE_BYTES) {
				Swal.fire({
					title: "Error",
					text: `The combined file size is too large. Maximum upload size: 5 MB`,
					confirmButtonText: "Close",
				});
				return;
			}

			for (let i = 0; i < e.dataTransfer.files.length; i++) {
				const file = e.dataTransfer.files[i];
				if (!fileWhitelistRegex.test(file.type)) {
					Swal.fire({
						title: "Error",
						text: `'${file.name}''s file type ${
							file.type?.length > 0 ? `'${file.type}'` : ""
						} is not supported`,
						confirmButtonText: "Close",
					});
				} else if (file.size && file.size > MAX_FILE_SIZE_BYTES) {
					Swal.fire({
						title: "Error",
						text: `The file '${file.name}' is too large. Maximum upload file size: 5 MB`,
						confirmButtonText: "Close",
					});
				} else {
					chatFiles.push(file);
				}
			}
			filesCount.value += chatFiles.length;
			sendMessage.value?.focus();
			return;
		}
	}
</script>

<style lang="scss">
	.row > * {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding-right: calc(var(--bs-gutter-x) * 0.5);
		padding-left: calc(var(--bs-gutter-x) * 0.5);
		margin-top: var(--bs-gutter-y);
	}

	@media (min-width: 1200px) {
		.modal-xl {
			max-width: 1140px;
		}
	}

	@media (min-width: 992px) {
		.modal-lg,
		.modal-xl {
			max-width: 800px;
		}
	}

	@media (min-width: 576px) and (orientation: portrait) {
		.modal-dialog {
			max-width: 500px;
			margin: 1.75rem auto;
		}
	}

	@media (min-width: 576px) and (orientation: landscape) {
		.modal-dialog {
			max-width: 1000px !important;
			margin: 1.75rem auto;
		}
	}

	.modal-img {
		width: 100%;
		height: 90%;
	}
	.full-img {
		width: 100%;
		height: 100%;
		-o-object-fit: scale-down;
		object-fit: scale-down;
		object-position: center;
	}
	img {
		max-width: 100%;
		/* max-height: 100%; */
	}

	.modal-dialog {
		position: relative;
		margin: 0.5rem;
		pointer-events: none;
	}
	.modal-img {
		width: 100% !important;
		height: 90% !important;
	}
	.modal-content {
		border-radius: 15px;
	}
	.modal-content-img {
		background: #000 !important;
		height: 100%;
		max-height: 100%;
		max-width: 100%;
	}
	.full-img-wrapper {
		width: 100%;
		height: 100%;
		padding: 5%;
		margin: 0;
	}
	.close {
		float: right;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		opacity: 0.5;
	}
	button.close {
		padding: 0;
		background-color: transparent;
		border: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	.close-full-img {
		color: #fff;
	}
	.close-channel {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	/** */

	.chat-room {
		color: #8a94a6;
		display: flex;
		flex-direction: column;
		padding: 0 0.7em;
		flex: 1;
		overflow: auto;
		overflow-x: hidden;
	}
	.chat-messages {
		/* height: calc(100vh - 380px); */
		flex: 1;
		overflow-y: auto;
		padding-right: 4px;
		margin-bottom: 4px;
	}

	#chat {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	#chat-content {
		height: calc(100% - 80%);
		margin-right: -1px;
		margin-left: -1px;
		display: block;
		min-height: 110px;
		margin-top: 5px;
	}

	#chat-control {
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #eeeeee;
		align-self: flex-end;
	}

	.chat-controls-container {
		flex-direction: row;
		flex: 1;
	}

	.chat-users-container {
		flex-direction: column;
		flex: 1;
	}

	.chat-actions {
		flex-direction: column;
		margin-left: 12px;
	}

	.chat-actions button {
		height: 36px;
		width: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		border: solid 1px #e4e4e4;
		color: #8a94a6;
		font-size: 12pt;
	}

	.chat-file-container {
		margin-bottom: 8px;
	}

	.chat-input {
		height: 92px;
		flex: 1;
		border: none !important;
		outline: none !important;
		resize: none;
		margin-left: 0.5em;
		margin-bottom: 0.5em;
	}

	.chat-file {
		display: none;
	}

	.btn-disable-chat {
		width: 100%;
		flex-grow: 0;
		margin: 10px 20px;
		padding: 12px 40px !important;
		border-radius: 10px !important;
		background-color: #f3f3f3 !important;
		font-size: 12pt;
		font-family: "Inter_Medium";
		cursor: pointer;
	}

	#contents {
		overflow-y: auto;
	}
	.files-count {
		position: absolute;
		display: flex;
		justify-content: center;
		top: -10px;
		right: -10px;
		background-color: red;
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		font-size: 12px;
	}
	select.chat-select {
		-moz-appearance: none !important;
		-webkit-appearance: none !important;
		appearance: none !important;
		padding-right: 15px;
		border-radius: 10px;
		background-color: #436dd9;

		option {
			background-color: white;
			color: black;
		}
	}

	.form-select {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
	}

	.dropzone {
		background: transparent;
		border: 2px solid transparent;
		border-radius: 20px;
		width: 100%;
		position: relative;
		z-index: 12;
	}
	.dropzone .fill {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
	}
	.dropzone.bordered {
		width: 100%;
		display: flex;
	}

	.drag-text {
		position: absolute;
		text-align: center;
		width: 100%;
		vertical-align: middle;
		color: black;
		font-weight: bolder;
		font-size: 36px;
		background: lightgray;
		padding-top: 10%;
		padding-bottom: 10%;
		border-radius: 11px;
		border: 2px dashed #346ee0;
	}

	.drag-text.dragging {
		z-index: 10;
	}

	.drag-text.on {
		z-index: 11;
	}

	@media (orientation: landscape) and (max-height: 420px) {
		.chat-input {
			height: 24px;
		}
	}

	.send-container {
		height: 100%;
		margin: 0 auto 0;
		-webkit-perspective: 1000;
		-webkit-backface-visibility: hidden;
		background: #fff;
	}

	.pulse-button {
		position: relative;
		top: 50%;
		left: 50%;
		margin-left: -18px;
		margin-top: -50px;
		display: block;
		width: 100px;
		height: 100px;
		color: white;
		border: none;
		border-radius: 50%;
		background: #346ee0;
		cursor: pointer;
		box-shadow: 0 0 0 0 rgba(#346ee0, 0.5);
		-webkit-animation: pulse 1.5s infinite;
	}
	.pulse-button:hover {
		-webkit-animation: none;
		border: solid 1px #e4e4e4;
	}

	.chat-select:has(option.pulse) {
		position: relative;
		display: block;
		border: none;
		box-shadow: 0 0 0 0 rgba(#346ee0, 0.5);
		-webkit-animation: pulseselect 1.5s infinite;
	}
	.chat-select:has(option.pulse):hover {
		-webkit-animation: none;
		border: solid 1px #e4e4e4;
	}

	@-webkit-keyframes pulse {
		0% {
			transform: scale(0.9);
		}
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 5px rgba(#346ee0, 0);
		}
		100% {
			transform: scale(0.9);
			box-shadow: 0 0 0 0 rgba(#346ee0, 0);
		}
	}

	@-webkit-keyframes pulseselect {
		0% {
			transform: scale(0.99);
		}
		70% {
			transform: scale(1);
			box-shadow: 0 0 0 5px rgba(#346ee0, 0);
		}
		100% {
			transform: scale(0.99);
			box-shadow: 0 0 0 0 rgba(#346ee0, 0);
		}
	}
</style>
