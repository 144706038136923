import type { IChannelService } from "@/classes/ChannelService";
import type IMyWorldService from "@/classes/MyWorldService";
import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { InjectionKey } from "vue";
import type {IAuthenticationService} from "@/classes/AuthenticationService";

export const authenticationServiceKey = Symbol() as InjectionKey<IAuthenticationService>;
export const channelServiceKey = Symbol() as InjectionKey<IChannelService>;

export const InjectionKeyAppInsights = Symbol() as InjectionKey<ApplicationInsights>;
export const InjectionKeyMyWorldService = Symbol() as InjectionKey<IMyWorldService>;