<template>
	<div class="modal fade" :class="{ show: previewOpen }" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog my-world-modal" role="document">
			<div class="modal-content modal-image-preview">
				<div class="modal-body">
					<div class="image-preview-label" v-if="previewImageTitle != ''">
						<h4 class="modal-title ff-orpheuspro" id="exampleModalLabel">&nbsp;{{ previewImageTitle }}</h4>
					</div>
					<i class="fal fa-times close" @click="closeImagePreview"></i>
					<div class="text-center">
						<img v-bind:src="previewImageUrl" class="img-preview mt-2" />
					</div>
				</div>
				<div @click="nextImage" class="icon-chevron-right">
					<i class="fas fa-chevron-right fa-2x"></i>
				</div>
				<div @click="previousImage" class="icon-chevron-left">
					<i class="fas fa-chevron-left fa-2x"></i>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal -->
	<div
		class="modal fade my-world-container"
		:class="{ show: modalOpen }"
		tabindex="-1"
		role="dialog"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header no-border" v-if="!myWorldEmpty">
					<h5 class="modal-title ff-orpheuspro">{{ myWorldModel?.name }}</h5>
					<i class="fal fa-times close" @click="useEventBus().emitEvent('modal-close', modalId)"></i>
				</div>
				<div class="modal-body">
					<div>
						<div class="d-flex justify-content-end" v-if="myWorldEmpty">
							<i class="fal fa-times close" @click="useEventBus().emitEvent('modal-close', modalId)"></i>
						</div>

						<div
							class="form-group mw-info-view text-center"
							id="myworld-preview-empty-message"
							v-if="myWorldEmpty">
							MyWorld information is not currently available for this participant.
						</div>
						<div v-if="!myWorldEmpty">
							<div
								class="form-group mw-info-view text-center"
								id="myworld-preview-error-message"
								style="display: none">
								<div class="flex-column">
									<span class="guest-sign-in">Looks like you aren't logged in</span><br />
									<span class="guest-sign-in-info">Please Sign in or Register</span><br />
									<div class="form-content">
										<button
											class="in-call-world-btn"
											id="sign-in-call"
											onclick="openCallSignInModal()">
											Sign In
										</button>
										<span class="guest-or-lbl" style="display: block">Or</span>
										<button class="in-call-world-btn" id="join-call" onclick="openCallJoinModal()">
											Join Now
										</button>
									</div>
									<button
										type="button"
										class="close"
										id="guest-return-btn"
										data-dismiss="modal"
										aria-label="Close">
										<span class="guest-return">Return to call as a guest</span>
									</button>
								</div>
							</div>
							<div
								class="form-group mw-info-view mw-info-view-user-details ff-orpheuspro"
								v-show="!myWorldEmpty">
								<div
									class="input-group mw"
									v-show="myWorldModel?.location !== '' && myWorldModel?.location !== null">
									<div class="container row">
										<div class="col-2 col-sm-1">
											<i class="fa fa-home mw-icon"></i>
										</div>
										<div class="col pl-1">
											<h5 class="mt-2">{{ myWorldModel?.location }}</h5>
										</div>
									</div>
								</div>
								<div
									class="input-group mw"
									v-show="myWorldModel?.school !== '' && myWorldModel?.school !== null">
									<div class="container row">
										<div class="col-2 col-sm-1">
											<i class="fas fa-graduation-cap mw-icon"></i>
										</div>
										<div class="col pl-1">
											<h5 class="mt-2">{{ myWorldModel?.school }}</h5>
										</div>
									</div>
								</div>
								<div
									class="input-group mw"
									v-show="myWorldModel?.work !== '' && myWorldModel?.work !== null">
									<div class="container row">
										<div class="col-2 col-sm-1">
											<i class="fas fa-suitcase mw-icon"></i>
										</div>
										<div class="col pl-1">
											<h5 class="mt-2">{{ myWorldModel?.work }}</h5>
										</div>
									</div>
								</div>
								<div
									class="input-group mw"
									v-show="myWorldModel?.jobTitle !== '' && myWorldModel?.jobTitle !== null">
									<div class="container row">
										<div class="col-2 col-sm-1">
											<i class="fas fa-id-card-alt mw-icon"></i>
										</div>
										<div class="col pl-1">
											<h5 class="mt-2">{{ myWorldModel?.jobTitle }}</h5>
										</div>
									</div>
								</div>
							</div>
							<div class="mw-info-view mt-4" v-show="myWorldModel?.images.length > 0">
								<div class="row mt-1 photo-row">
									<div v-for="(photo, index) in myWorldModel?.images" class="photo-container">
										<div class="myworld-image-div">
											<img
												class="myworld-image"
												v-on:click="openPreview(photo, index)"
												v-bind:src="photo.imageUrl"
												v-bind:title="photo.pinName"
												v-if="photo.imageUrl !== ''" />
										</div>
									</div>
								</div>
							</div>
							<div
								class="form-group mw-info-view mt-4"
								v-for="fact in myWorldModel?.funFacts"
								v-show="myWorldModel?.funFacts.length > 0">
								<div class="input-group mw">
									<h5 class="fun-fact-question ml-3 mr-3 mt-2 ff-orpheuspro">
										{{ fact.pinName }}
									</h5>
									<div class="mw-label fun-fact ml-3 inter-grey-text">
										{{ fact.pinContent }}
									</div>
								</div>
							</div>
							<div
								class="form-group mw-info-view ff-orpheuspro mt-4 social-media-info"
								v-show="!allSocialMediaIsEmpty">
								<div class="mw ml-3">
									<h5 class="mt-2">Find me on social media and on the web</h5>
									<div class="social-icon-container">
										<a
											v-show="
												myWorldModel?.linkedinLink !== '' && myWorldModel?.linkedinLink !== null
											"
											v-bind:href="addHttp(myWorldModel?.linkedinLink)"
											target="_blank"
											class="social-link"
											id="link-linkedin">
											<i
												id="linked-in-icon"
												class="myworld-social-icon mic-on fab fa-linkedin-in mr-4"></i>
										</a>
										<a
											v-show="
												myWorldModel?.facebookLink !== '' && myWorldModel?.facebookLink !== null
											"
											v-bind:href="addHttp(myWorldModel?.facebookLink)"
											target="_blank"
											class="social-link"
											id="link-facebook">
											<i
												id="facebook-icon"
												class="myworld-social-icon mic-on fab fa-facebook-f mr-4"></i>
										</a>
										<a
											v-show="
												myWorldModel?.twitterLink !== '' && myWorldModel?.twitterLink !== null
											"
											v-bind:href="addHttp(myWorldModel?.twitterLink)"
											target="_blank"
											class="social-link"
											id="link-twitter">
											<i
												id="twitter-icon"
												class="myworld-social-icon mic-on fab fa-twitter mr-4"></i>
										</a>
										<a
											v-show="
												myWorldModel?.instagramLink !== '' &&
												myWorldModel?.instagramLink !== null
											"
											v-bind:href="addHttp(myWorldModel?.instagramLink)"
											target="_blank"
											class="social-link"
											id="link-instagram">
											<i
												id="instagram-icon"
												class="myworld-social-icon mic-on fab fa-instagram mr-4"></i>
										</a>
										<a
											v-show="
												myWorldModel?.websiteLink !== '' && myWorldModel?.websiteLink !== null
											"
											v-bind:href="addHttp(myWorldModel?.websiteLink)"
											target="_blank"
											class="social-link"
											id="link-website">
											<i
												id="website-icon"
												class="myworld-social-icon mic-on fas fa-globe mr-4"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import { onMounted, ref, type PropType, computed, inject, onUnmounted } from "vue";
	import type { MyWorldImage, MyWorldModel } from "@/classes/MyWorldService";
	import type IMyWorldService from "@/classes/MyWorldService";
	import { InjectionKeyAppInsights, InjectionKeyMyWorldService } from "@/composables/injectKeys";
	import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
	import useHelpers from "@/composables/useHelpers";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const myWorldService: IMyWorldService = inject(InjectionKeyMyWorldService) as IMyWorldService;
	const modalId: string = "remote-my-world";
	const modalOpen = ref(false);
	const previewOpen = ref(false);
	const myWorldEmpty = ref(false);
	const myWorldModel = ref(undefined as MyWorldModel | undefined);
	const previewImageTitle = ref("");
	const previewImageUrl = ref("");
	let previewImageIndex = 0;

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	const allAboutYouIsEmpty = computed(() => {
		return (
			(myWorldModel.value?.location === null || myWorldModel.value?.location === "") &&
			(myWorldModel.value?.school === null || myWorldModel.value?.school === "") &&
			(myWorldModel.value?.work === null || myWorldModel.value?.work === "") &&
			(myWorldModel.value?.jobTitle === null || myWorldModel.value?.jobTitle === "")
		);
	});

	const allSocialMediaIsEmpty = computed(() => {
		return (
			!isValidLink(myWorldModel.value?.linkedinLink) &&
			!isValidLink(myWorldModel.value?.facebookLink) &&
			!isValidLink(myWorldModel.value?.twitterLink) &&
			!isValidLink(myWorldModel.value?.instagramLink) &&
			!isValidLink(myWorldModel.value?.websiteLink)
		);
	});

	onMounted(async () => {
		useEventBus().onEvent("toggle-remote-my-world", async (userAccountId: string) => {
			let modelPopulated = false;

			try {
				const model = await myWorldService.getAsync(userAccountId);
				let status: string = "";

				modelPopulated =
					model && Object.values(model).findIndex((value) => value !== null && value?.length !== 0) !== -1;
				if (modelPopulated) {
					myWorldModel.value = model;
					myWorldEmpty.value = false;
					status = "Loaded";
					appInsights.startTrackEvent("MyWorldViewRemote");
				} else {
					myWorldModel.value = undefined;
					myWorldEmpty.value = true;
					status = "Empty";
				}

				appInsights.trackMetric(
					{
						name: `MyWorldRemote${status}`,
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
			} catch (ex: any) {
				myWorldModel.value = undefined;
				myWorldEmpty.value = true;
			}

			modalOpen.value = true;
		});

		useEventBus().onEvent("modal-close", (id: string) => {
			if (id == modalId) {
				modalOpen.value = false;
				appInsights.stopTrackEvent("MyWorldViewRemote", useHelpers().getLoggingProperties());
			}
		});
	});

	function addHttp(url: string) {
		if (url == null || url == "") {
			return;
		}

		if (url.toLowerCase().includes("http")) {
			return url;
		} else {
			return `https://${url}`;
		}
	}

	function isValidLink(url: string | undefined) {
		if (url != "" && url != null && isUrlValid(url)) {
			return true;
		} else {
			return false;
		}
	}

	function isUrlValid(url: string) {
		var pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		return !!pattern.test(url);
	}

	function openPreview(image: MyWorldImage, index: number) {
		previewImageIndex = index;
		setPreviewImage();
		previewOpen.value = true;
		modalOpen.value = false;
		appInsights.trackMetric(
			{
				name: "MyWorldRemoteOpenImage",
				average: 1,
			},
			useHelpers().getLoggingProperties()
		);
	}

	function closeImagePreview() {
		previewOpen.value = false;
		modalOpen.value = true;
	}

	function nextImage() {
		previewImageIndex += 1;

		if (previewImageIndex > myWorldModel.value.images.length - 1) {
			previewImageIndex = 0;
		}

		setPreviewImage();
		appInsights.trackMetric(
			{
				name: "MyWorldRemoteNextImage",
				average: 1,
			},
			useHelpers().getLoggingProperties()
		);
	}

	function previousImage() {
		previewImageIndex -= 1;

		if (previewImageIndex < 0) {
			previewImageIndex = myWorldModel.value.images.length - 1;
		}

		setPreviewImage();
		appInsights.trackMetric(
			{
				name: "MyWorldRemotePreviousImage",
				average: 1,
			},
			useHelpers().getLoggingProperties()
		);
	}

	function setPreviewImage() {
		const image = myWorldModel.value.images[previewImageIndex];
		previewImageTitle.value = image.pinName;
		previewImageUrl.value = image.imageUrl;
	}
</script>

<style scoped>
	.modal-dialog {
		width: 40%;
	}

	.my-world-container {
		color: black;
		font-family: "Inter_Regular";
	}

	#myworld-preview-empty-message {
		padding: 1em;
		font-family: "Inter_Medium";
	}

	.mw-info-view {
		border-radius: 15px;
		box-shadow: 0 50px 77px 0 rgb(176 183 195 / 22%);
		border: solid 2px #fafbfc;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
	}

	.mw-icon {
		margin-top: 10px;
	}

	.inter-grey-text {
		font-family: Inter_Medium;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #8a94a6;
	}

	.mw h5,
	.fun-fact,
	.fun-fact-question,
	.modal-title {
		word-wrap: break-word;
		word-break: break-word;
	}

	.mw-label {
		margin: 8px;
		font-size: 18px;
	}

	.photo-row {
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
	}

	.photo-container {
		margin: 10px;
		width: 149px;
		height: 110px;
	}

	.myworld-image-div {
		position: relative;
	}

	.myworld-image {
		width: 149px;
		height: 110px;
		border-radius: 15px;
	}

	.fun-fact-question {
		width: 100%;
		word-break: break-word;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.fun-fact {
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}

	.social-media-info {
		align-content: center !important;
		justify-content: start !important;
		align-items: start !important;
		padding: 1em;
	}

	.social-icon-container {
		justify-content: center;
		display: flex;
	}

	.myworld-social-icon {
		color: #4e5d78;
		font-size: 25px;
		margin: 0 1rem;
	}

	.icon-chevron-right {
		position: absolute;
		right: -50px;
		top: calc(100% / 2);
		color: #ffffff;
	}

	.icon-chevron-left {
		position: absolute;
		left: -50px;
		top: calc(100% / 2);
		color: #ffffff;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.modal-dialog {
			width: 100%;
		}

		.my-world-modal {
			width: 85%;
		}

		.icon-chevron-left {
			left: -25px;
		}

		.icon-chevron-right {
			right: -25px;
		}
	}
</style>
