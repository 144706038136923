import APIServiceClient, { APIServiceRequest } from "@/api/api-service";
import useHelpers from "@/composables/useHelpers";
import { useCookies } from "vue3-cookies";
import { ResponseMessage } from "@/classes/AuthenticationService";

export class ChannelService implements IChannelService {
	private static readonly apiServiceClient: APIServiceClient = new APIServiceClient(
		ChannelService.getApiEndpoint()
	);

	public static getApiEndpoint(): string {
		//const endpoint = ChannelService.apiEndpoints[window.location.host];
		const endpoint = import.meta.env.VITE_API_URL;

		if (endpoint) {
			return endpoint;
		}

		throw `API endpoint not found for ${window.location.host}.`;
	}

	public async getChannelAsync(channelKey: string): Promise<ChannelDetails> {
		const path = `api/channel/getdetails/${channelKey}`;
		const response = await ChannelService.apiServiceClient.executeRequest<ChannelDetails>(
			path,
			"GET",
			null,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			}
		);

		if(window.location.href.toLocaleLowerCase().indexOf('cleardevices=true') > -1){
			response.data.ClearDevices = true
		}

		return Promise.resolve(response.data);
	}

	public async getChannelInviteTemplateAsync(): Promise<string> {
		const response = await ChannelService.apiServiceClient.executeRequest<any>(
			"api/MessageTemplate/TemplateKey/CHANNEL_INVITATION_CALL",
			"GET",
			null,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			}
		);
		const template = response.data.Body;
		return Promise.resolve(template);
	}

	public async getReferralCode(userAccountId: string): Promise<string> {
		const path = "api/ChannelParticipant/ReferralCode";
		const response = await ChannelService.apiServiceClient.executeRequest<ResponseMessage>(
			path,
			"POST",
			userAccountId,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			}
		);

		return response.data.Result as string;
	}

	public async submitCallRating(userAccountId: string, callRating: number, ratingDescription: string | undefined, retrieveReferralCode: boolean = false): Promise<string | undefined> {
		const channel = JSON.parse(localStorage.getItem('Channel'));

		if (!channel) {
			return undefined;
		}

		const channelParticipant = {
			channelId: channel.id,
			id: useHelpers().emptyUuid(),
			rating: callRating,
			ratingDescription: ratingDescription,
			roomId: useHelpers().emptyUuid(),
			serviceNodeId: channel.ServiceNodeId,
			updatedBy: userAccountId,
			userAccountId: userAccountId
		} as ChannelParticipant;

		const request = {
			channelParticipant: channelParticipant,
			retrieveReferralCode: retrieveReferralCode
		} as CallRatingRequest;

		const path = 'api/ChannelParticipant/UpdateCallRating';
		const response = await ChannelService.apiServiceClient.executeRequest<ResponseMessage>(
			path,
			"POST",
			request,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			}
		);

		console.log(`Call Rating Response: ${JSON.stringify(response)}`);
		return response.data.Result as string;
	}

	public async getOrAddMediaServerRoom(roomKey: string, showErrorMessagePopUp = true) {
		return await ChannelService.apiServiceClient.execute<any>(new APIServiceRequest<any>({
			path: `api/Channel/GetOrAddMediaServerRoom/${roomKey}`,
			method: "GET",
			showErrorMessagePopUp: showErrorMessagePopUp
		}));
	}

	public async reportIssueAsync(report: IssueReport) {
		const response = await ChannelService.apiServiceClient.executeRequest<ResponseMessage>(
			"api/issue",
			"POST",
			report,
			{
				Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
			}
		);

		console.log(response);
	}
}

export interface IChannelService {
	getChannelAsync(channelKey: string): Promise<ChannelDetails>;
	getChannelInviteTemplateAsync(): Promise<string>;
	getReferralCode(userAccountId: string): Promise<string>;
	submitCallRating(userAccountId: string, callRating: number, ratingDescription: string | undefined, retrieveReferralCode: boolean | undefined): Promise<string | undefined>;
	reportIssueAsync(report: IssueReport): void;
}

export interface BaseIssueReport {
	meetingId: string;
	attendeeId: string;
	userAccountId: string;
	accountType: string;
	attendeeCount: number;
	sdkVersion: string,
	roomKey: string;
	category: string;
	notes: string;
	additionalData: any;
	reportDate: Date;
}

export interface IssueReport extends BaseIssueReport {
	displayName: string;
	email: string;
}

export interface ChannelInbound {
	Id: string;
	PhoneNumber: string;
	Passcode: string;
	CountryId: string;
	CountryName: string;
	CountryCode: string;
}

export interface TenantSetting {
	Id: string;
	TenantId: string;
	SettingName: string;
	SettingType: string;
	SettingValue: string;
}

export interface ChannelDetails {
	EnableWaitingroomWebhook: boolean;
	ChannelId?: string;
	ChannelName?: string;
	UserAccountId?: string;
	ChannelKey?: string;
	ChannelStatus?: string;
	MeetSubject?: string;
	ServiceNodeId?: string;
	DisplayName?: string;
	ChannelPasscodeRequired: boolean;
	MeetingOwner: boolean;
	TenantLogo: TenantSetting;
	IsLobbyEnabled: boolean;
	EnableSMSInvites: boolean;
	BlurEnabled: boolean;
	ClearDevices: boolean;
}

export interface ChannelParticipant {
	id: string;
	userAccountId: string;
	serviceNodeId: string;
	channelId: string;
	roomId: string;
	rating: number;
	ratingDescription: string;
	updatedBy: string;
}

export interface CallRatingRequest {
	channelParticipant: ChannelParticipant,
	retrieveReferralCode: boolean
}