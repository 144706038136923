import {
	authenticationServiceKey,
	channelServiceKey,
	InjectionKeyAppInsights,
	InjectionKeyMyWorldService
} from "@/composables/injectKeys";
import { ChannelService } from "../classes/ChannelService";
import type { App } from "vue";
import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { MyWorldService } from "@/classes/MyWorldService";
import {AuthenticationService} from "@/classes/AuthenticationService";

export default function useDefaultInjections(app: App<Element>, appInsights: ApplicationInsights) {
	app.provide(channelServiceKey, new ChannelService());
	app.provide(authenticationServiceKey, new AuthenticationService());
	app.provide(InjectionKeyMyWorldService, new MyWorldService(appInsights));
}
