<script setup lang="ts">
	import Meeting from "@/components/Meeting.vue";
	import ModalBundle from "@/components/modals/ModalBundle.vue";
	import useHelpers from "@/composables/useHelpers";
	import type { ScreenOrientation } from "@/classes/EventContracts";
	import { videoStatsEnabled, persistenAttendeeEnabled } from "@/composables/useLocalStorage";

	import { onMounted, ref } from "vue";
	import { useRoute } from "vue-router";
	import useEventBus from "../composables/useEventBus";

	const callEnded = ref(false);

	onMounted(() => {
		if (useRoute().query._so_debug == "true") {
			videoStatsEnabled.value = true;
			persistenAttendeeEnabled.value = false;
			console.log("Stats enabled");
		}

		window.onpageshow = function (event) {
			console.log("pageShow");

			if (event.persisted) {
				console.log("Page cached. Reloading");
				window.location.reload();
			}
		};

		useEventBus().onEvent("leave-call", () => {
			callEnded.value = true;
		});

		if (screen.orientation) {
			screen.orientation.onchange = () => {
				console.log(`Orientation=${screen.orientation}`);
				useEventBus().emitEvent("orientation-change", screen.orientation);
			};
		} else {
			var mql = window.matchMedia("(orientation: portrait)");

			mql.addEventListener("change", (query) => {
				if (query.matches) {
					console.log(`Orientation=Portrait`);
					useEventBus().emitEvent("orientation-change", { type: "portrait" } as ScreenOrientation);
				} else {
					console.log(`Orientation=Landscape`);
					useEventBus().emitEvent("orientation-change", { type: "landscape" } as ScreenOrientation);
				}
			});
		}

		/*if (!useHelpers().isMobileBrowser()) {
			var mql567 = window.matchMedia("(max-height: 567px)");

			mql567.addEventListener("change", (query) => {
				useEventBus().emitEvent("desktop-resize");
				console.debug(
					`MQL: Query=max-height: 567px, Matches=${query.matches}, Height=${document.body.offsetHeight}`
				);
			});

			var mql767 = window.matchMedia("(max-height: 767px)");

			mql767.addEventListener("change", (query) => {
				useEventBus().emitEvent("desktop-resize");
				console.debug(
					`MQL: Query=max-height: 767px, Matches=${query.matches}, Height=${document.body.offsetHeight}`
				);
			});
		}*/

		useHelpers().removeQueryParams(["_gl"]);
	});
</script>

<template>
	<div class="wrapper" :class="{ 
		'call-rating': callEnded,
		'mobile': useHelpers().isMobileBrowser(),
		'safari': useHelpers().isSafari()
	}">
		<Meeting />
		<ModalBundle />
	</div>
</template>

<style scoped>
	.wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: #323b4b;
	}

	.call-rating {
		background-color: #fee8c1;
	}

	body {
		background-color: #323b4b !important;
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		body {
			background-color: #323b4b !important;
		}
	}

	/* body {
		background-color: #323b4b !important;
	}

	#app {
		background-color: #323b4b !important;
	} */
</style>
