import APIServiceClient from "@/api/api-service";
import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useCookies } from "vue3-cookies";
import useHelpers from "@/composables/useHelpers";

export class MyWorldService implements IMyWorldService {
    private static readonly resolutionServiceClient: APIServiceClient = new APIServiceClient(import.meta.env.VITE_RESOLUTION_URL);
    private static readonly apiServiceClient: APIServiceClient = new APIServiceClient(MyWorldService.getApiEndpoint());

    private readonly appInsights: ApplicationInsights;

    constructor(appInsights: ApplicationInsights) {
        this.appInsights = appInsights;
    }

    public static getApiEndpoint(): string {
        const endpoint = import.meta.env.VITE_API_URL;

        if (endpoint) {
            return endpoint;
        }

        throw `API endpoint not found for ${window.location.host}.`;
    }

    async getAsync(userAccountId: string): Promise<MyWorldModel | undefined> {
        let path: string = `api/pins/user-account/${userAccountId}?code=${import.meta.env.VITE_RESOLUTION_CODE}`;

        try {
            let response = await MyWorldService.resolutionServiceClient.executeRequest<MyWorldModel>(path, 'GET', null);
            return Promise.resolve(response.data);
        } catch (ex: any) {
            this.appInsights.trackException({
                exception: ex,
                id: "MyWorldError",
                severityLevel: SeverityLevel.Error
            }, useHelpers().getLoggingProperties("MyWorldError", "Error retrieving MyWorld info"));

            throw Error(ex);
        }
    }

    async updateAsync(model: MyWorldModel): Promise<Object | undefined> {
        let path: string = `/api/myworld/user-account/${model.userAccountId}?code=${import.meta.env.VITE_RESOLUTION_CODE}`;

        try {
            let response = await MyWorldService.resolutionServiceClient.executeRequest<MyWorldModel>(path, 'POST', model);
            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.resolve(undefined);
        }
    }

    async getFunFactQuestions(): Promise<string[] | undefined> {
        let path = `api/DomainNameValueCustom/List`;

        try {
            let response = await MyWorldService.apiServiceClient.executeRequest<Object[]>(path, 'POST',
                {
                    'domain': 'FunFactDefaultQuestions'
                },
                {
                    'Authorization': `bearer ${useCookies().cookies.get('Authorization')}`
                });

            let questions: string[] = [];

            if (response.data) {
                response.data.forEach(q => questions.push(q.Value));
            }

            return questions;
        } catch (err) {
            return undefined;
        }
    }

    async uploadImageAsync(form: FormData): Promise<ImageUploadResponse | undefined> {
        let path = `api/uploadimage?code=${import.meta.env.VITE_RESOLUTION_CODE}`;

        try {
            let response = await MyWorldService.resolutionServiceClient.executeRequest<ImageUploadResponse>(path, 'POST', form, {
                'Content-Type': 'multipart/form-data'
            })

            if (response.data) {
                return Promise.resolve(response.data);
            }
        } catch (err) {
            console.log(err);
            return Promise.resolve(undefined);
        }
    }
}

export default interface IMyWorldService {
    getAsync(userAccountId: string): Promise<MyWorldModel | undefined>;
    getFunFactQuestions(): Promise<string[] | undefined>;
    updateAsync(model: MyWorldModel): Promise<Object | undefined>;
    uploadImageAsync(form: FormData): Promise<ImageUploadResponse | undefined>;
}

export interface ImageUploadResponse {
    objectUri: string;
}

export interface MyWorldImage {
    imageUrl: string;
    pinContent: string;
    pinHelp: string;
    pinId: string;
    pinName: string;
    pinStatus: string;
    pinType: string;
    roomId: string;
    roomPinId: string;
}

export class MyWorldModel {
    public name: string = '';
    public location: string = '';
    public school: string = '';
    public work: string = '';
    public jobTitle: string = '';
    public linkedinLink: string = '';
    public facebookLink: string = '';
    public twitterLink: string = '';
    public instagramLink: string = '';
    public websiteLink: string = '';
    public userAccountId: string = '';
    public funFacts: FunFact[] = [];
    public images: MyWorldImage[] = [];
}

export interface FunFact {
    pinName: string;
    pinContent: string;
    sortOrder: number;
}