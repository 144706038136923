<template>
	<transition name="fade">
		<div class="custom-modal modal show fade" tabindex="-1" aria-hidden="true" v-show="show">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header justify-content-center">
						<h5 class="modal-title" id="exampleModalLabel">Confirmation!</h5>
					</div>
					<div class="modal-body" v-html="body"></div>
					<div class="modal-footer justify-content-center">
						<button
							type="button"
							class="btn btn-primary rounded"
							data-bs-dismiss="modal"
							v-text="actionText"
							@click="
								$emit('execute');
								close();
							"
						></button>
						<button type="button" class="btn rounded btn-primary" @click="close()">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script lang="ts" setup>
	import { useSlots } from "vue";

	defineProps(["show", "body", "actionText"]);
	const emits = defineEmits(["execute", "update:show"]);

	function close() {
		emits("update:show", false);
	}
</script>
<style lang="scss" scoped>
	.custom-modal {
		.modal-dialog {
			max-width: 380px;
		}

		.modal-title {
			font-family: OrpheusPro_Bold !important;
		}

		.modal-footer,
		.modal-header {
			border: none;
		}
		.modal-body {
			color: #444;
		}
		button {
			width: 45%;
			padding: 10px;
		}
	}
</style>
