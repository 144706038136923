<template>
	<div class="d-flex col-12 parent-rating-container align-items-center justify-content-center">
		<div class="container col-md-8 col-sm-12 d-flex rating-container" v-if="!referralCode && !referralLink">
			<div class="row">
				<div class="col-12 rate-label">How was your experience?</div>
			</div>
			<div class="row">
				<div class="col-12 rate">
					<input type="radio" id="star5" name="rate" value="5" v-model="starRating" />
					<label for="star5" title="5">5 stars</label>
					<input type="radio" id="star4" name="rate" value="4" v-model="starRating" />
					<label for="star4" title="4">4 stars</label>
					<input type="radio" id="star3" name="rate" value="3" v-model="starRating" />
					<label for="star3" title="3">3 stars</label>
					<input type="radio" id="star2" name="rate" value="2" v-model="starRating" />
					<label for="star2" title="2">2 stars</label>
					<input type="radio" id="star1" name="rate" value="1" v-model="starRating" />
					<label for="star1" title="1">1 star</label>
				</div>
			</div>
			<div class="row rating-details">
				<div>
					<textarea
						name="rating-description"
						class="form-control"
						rows="4"
						style="resize: none"
						maxlength="2000"
						placeholder="Additional Feedback (optional)"
						v-model="ratingDescription"></textarea>
				</div>
			</div>
			<div class="row">
				<button
					type="submit"
					:disabled="starRating === 0"
					class="btn btn-primary btn-submit-rating"
					@click="submitStarRating">
					Send Feedback
				</button>
			</div>
			<div class="row">
				<button type="submit" class="btn btn-primary btn-submit-rating" @click="skipFeedback">
					Skip Feedback
				</button>
			</div>
		</div>
		<div class="container col-sm-12 col-md-8 d-flex rating-container" v-if="referralCode || referralLink">
			<div class="row rating-label">
				<span class="font-weight-bold detail-label">We're glad you are enjoying LiveSwitch Video!</span>
			</div>
			<div class="referral-div">
				<!--TODO-->
				<!--				<div><span>Friend's Email:</span></div>-->
				<!--				<div><input name="referral-email" type="text" v-model="referralEmail"/></div>-->
				<!--				<div><span>OR</span></div>-->
				<span>Share LiveSwitch Video with your friends using your referral link:</span>
				<div class="row submit-rating">
					<div class="col-sm-12">
						<button type="submit" class="btn btn-primary btn-submit-rating" @click="copyLink()">
							Copy Referral Link <i class="fas fa-copy pl-2"></i>
						</button>
					</div>
					<div class="col-sm-12">
						<button type="submit" class="btn btn-primary btn-submit-rating" @click="skipFeedback">
							Back to homepage
						</button>
					</div>
				</div>
			</div>
			<!--TODO-->
			<!--			<div class="row submit-referral">-->
			<!--				<div class="col-sm-12 col-md-3">-->
			<!--					<button type="submit" class="btn btn-primary btn-submit-referral" @click="submitReferralEmail">-->
			<!--						Submit-->
			<!--					</button>-->
			<!--				</div>-->
			<!--			</div>-->
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ChannelService, type IChannelService } from "@/classes/ChannelService";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import Swal from "sweetalert2";
	import useHelpers from "@/composables/useHelpers";
	import { onBeforeMount, ref, type PropType } from "vue";
import useLocalStorage from "@/composables/useLocalStorage";

	const referralCode = ref("");
	const referralLink = ref("");
	const starRating = ref(0);
	const ratingDescription = ref("");

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	onBeforeMount(async () => {
		const params = useHelpers().getParams();
		if (useHelpers().isAuthenticatedUser() && params.has("referral")) {
			referralCode.value = "temp";
			referralLink.value = "temp";
			if (useLocalStorage().getUserAccountId()) {
				const service: IChannelService = new ChannelService();
				referralCode.value = await service.getReferralCode(useLocalStorage().getUserAccountId() as string);
				referralLink.value = `https://www.liveswitch.com/friends?referralCode=${referralCode.value}`;
			} else {
				window.location.href = `${import.meta.env.VITE_CLIENT_SITE_URL}/register?view=guestFeedback`;
			}
			return;
		}
	});

	async function submitStarRating() {
		if (starRating.value === 0) {
			return;
		}

		if (starRating.value < 4) {
			await submitRatingDescription(false);
		} else {
			if (useHelpers().isAuthenticatedUser()) {
				referralCode.value = await submitRatingDescription(true);
				referralLink.value = `https://www.liveswitch.com/friends?referralCode=${referralCode.value}`;
			} else {
				window.location.href = `${import.meta.env.VITE_CLIENT_SITE_URL}/register?view=guestFeedback`;
			}
		}
	}

	async function submitRatingDescription(retrieveReferralCode: boolean = false) {
		useEventBus().emitEvent("loading", "Submitting...");
		const service: IChannelService = new ChannelService();
		const referralCodeValue = await service.submitCallRating(
			props.meetingHandler.userAccountId,
			starRating.value,
			ratingDescription.value,
			retrieveReferralCode
		);

		if (!retrieveReferralCode) {
			window.location = import.meta.env.VITE_USER_SITE_URL;
		}

		useEventBus().emitEvent("loading-complete");

		if (retrieveReferralCode && !referralCodeValue) {
			console.warn("Failed to retrieve referral code.");
		}

		return referralCodeValue;
	}

	function copyLink() {
		navigator.clipboard.writeText(referralLink.value);

		Swal.fire({
			title: "Copied successfully",
			text: "Your Refer-a-Friend link has been copied to your clipboard.",
			type: "success",
		});
	}

	function skipFeedback() {
		window.location.href = import.meta.env.VITE_USER_SITE_URL;
	}

	// TODO
	// async function submitReferralEmail() {
	// 	if(referralEmail.value) {
	// 	}
	// }
</script>

<style>
	body {
		background-color: #fee8c1;
	}

	.wrapper {
		background-color: rgb(255, 242, 216);
	}

	.logo {
		filter: invert(35%) sepia(78%) saturate(1437%) hue-rotate(204deg) brightness(90%) contrast(94%);
	}

	.parent-rating-container {
		height: 100vh;
	}

	.rating-container {
		color: black;
		min-height: 350px;
		background-color: #fff;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-radius: 15px;
		padding: 6em 0;
	}

	.rate-label {
		font-family: "Inter_Medium";
		font-size: 24px;
	}

	.rate:not(:checked) > label:before {
		content: "★ ";
	}

	.rate:not(:checked) > label {
		float: right;
		width: 1em;
		overflow: hidden;
		white-space: nowrap;
		cursor: pointer;
		font-size: 30px;
		color: #ccc;
	}

	.rate > input:checked ~ label {
		color: #ffc700;
	}

	.rate:not(:checked) > input {
		position: absolute;
		top: -9999px;
	}

	.rating-label {
		width: 60%;
		display: block;
		text-align: center;
		font-family: "Inter_Medium";
		font-size: 24px;
	}

	.rating-details {
		width: 45%;
		margin-top: 12px;
		font-size: 14px;
	}

	.rating-details textarea {
		margin-top: 12px;
		font-family: "Inter_Medium";
	}

	.btn-submit-rating {
		padding-left: 3em;
		padding-right: 3em;
		margin-top: 12px !important;
		font-family: "Inter_Medium";
	}

	.btn-feedback {
		border: none;
		min-width: 140px;
	}

	.btn-submit-referral {
		padding-left: 3em;
		padding-right: 3em;
		margin-top: 12px !important;
		font-family: "Inter_Medium";
	}

	.referral-div {
		text-align: center;
	}

	.referral-link-textbox {
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.05);
		border: solid 1px #f8f8f8;
		/*height: calc(45rem/16);*/
		font-weight: 500;
		color: #8a94a6;
		font-size: 18px;
		font-family: "Inter_Medium";
		margin-top: 12px;
		word-break: break-word;
	}

	.referral-copy-link-span {
		margin-left: 4px;
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.rating-details {
			width: 100%;
			margin-top: 12px;
			font-size: 14px;
		}

		.rating-label {
			width: 100%;
			font-size: 18px;
		}

		.btn-submit-rating {
			width: 100% !important;
		}

		.submit-rating {
			width: 100%;
		}

		.btn-submit-referral {
			width: 100% !important;
		}

		.submit-referral {
			width: 100%;
		}
	}
</style>
