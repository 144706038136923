<template>
	<router-view> </router-view>
	<loader :value="loading" :message="loadingMessage"></loader>
</template>
<script lang="ts" setup>
	import Loader from "./components/Loader.vue";
	import useEventBus from "@/composables/useEventBus";
	import "@/assets/css/liveswitch.css";
	import { ref } from "vue";

	const loading = ref(false);
	const loadingMessage = ref("");
	const eventBus = useEventBus();
	const wakeLock = ref<WakeLockSentinel>();

	eventBus.onEvent("loading", (m: string) => {
		loadingMessage.value = m;
		loading.value = true;
	});
	eventBus.onEvent("loading-complete", () => {
		loading.value = false;
	});
	document.body.addEventListener("mousedown", () => eventBus.emitEvent("close-popup"));

	document.addEventListener("visibilitychange", async () => {
		if (document.visibilityState === "visible") {
			if ("wakeLock" in navigator) {
				try {
					wakeLock.value = await navigator.wakeLock.request("screen");

					wakeLock.value.addEventListener("release", () => {
						console.debug("Wake lock released");
					});
				} catch (err: any) {
					console.error("Error acquiring WakeLock", err);
				}
			}
		}
	});

	window.addEventListener("beforeunload", async () => {
		if (wakeLock.value && wakeLock.value != null) {
			try {
				await wakeLock.value.release();
			} catch (err: any) {
				console.error("Error releasing WakeLock", err);
			}
		}
	});
</script>

<style>
	body {
		/* background-color: #323b4b !important; */
		font-family: "Inter_Regular";
	}

	@font-face {
		font-family: "OrpheusPro_Bold";
		src: url("./assets/fonts/OrpheusPro-Bold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Black";
		src: url("./assets/fonts/Inter-Black.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Bold";
		src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_ExtraBold";
		src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_ExtraLight";
		src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Light";
		src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Medium";
		src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Regular";
		src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_SemiBold";
		src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
	}

	@font-face {
		font-family: "Inter_Thin";
		src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
	}

	@font-face {
		font-family: "fal";
		src: url("./assets/fonts/fa-light-300.ttf") format("truetype");
	}

	@font-face {
		font-family: "fas";
		src: url("./assets/fonts/fa-solid-900.ttf") format("truetype");
	}

	@font-face {
		font-family: "far";
		src: url("./assets/fonts/fa-regular-400.ttf") format("truetype");
	}

	@font-face {
		font-family: "fab";
		src: url("./assets/fonts/fa-brands-400.ttf") format("truetype");
	}

	@font-face {
		font-family: "OrpheusPro";
		src: url("./assets/fonts/OrpheusPro-Bold.ttf") format("truetype");
	}

	/*Fontawesome fonts*/
	.far {
		font-family: far !important;
	}

	.fas {
		font-family: fas !important;
	}

	.fal {
		font-family: fal !important;
	}

	.fab {
		font-family: fab !important;
	}
</style>
