<template>
	<div
		class="video-grid-container"
		v-if="!useHelpers().isMobileBrowser()"
		:type="'screen'">
		<!-- desktop, if remote screenshare, show the screenshare in large format -->
		<!-- <div class="remote-screen">
			<Video
				v-for="video in remoteUserAllVideos"
				:video="video"
				:videoCount="remoteUserAllVideos.length"
				:meetingHandler="meetingHandler"
				:videoType="'screen'"
				:key="video.id" />
		</div> -->


		<div class="remote-screen video-grid">

			<Video
				v-for="video in (meetingHandler.remoteScreenSharing ? remoteUserSpeakerVideos : remoteUserAllVideos)"
				:video="video"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				videoType="gallery"
				:key="video.id"
				:testVideo="testing" />

		</div>
		<!-- desktop -->
		<div
			class="video-grid"
			:class="{
				'thumbnail-container': true
			}">

<Video
				v-if="meetingHandler.localUserMedia"
				:video="meetingHandler.localUserMedia"
				:videoCount="1"
				:meetingHandler="meetingHandler"
				videoType="thumbnail"
				:key="meetingHandler.localUserMedia.id"
				:localMediaBlurred="false" />

			<!-- local screen share-->
			<!-- <Video
				v-if="meetingHandler.localScreenSharing"
				:video="meetingHandler.meeting.localDisplayMedia"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="'screen'"
				:key="meetingHandler.meeting.localDisplayMedia.id" /> -->

			<!-- self view - at the top in screenshare view, left top in normal view -->
			<!-- <Video
				v-if="meetingHandler.localUserMedia"
				:video="meetingHandler.localUserMedia"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
				:key="meetingHandler.localUserMedia.id"
				:localMediaBlurred="false" /> -->

			<!-- speakers on the call; in screenshare mode, we bring speakers to top, but
				in "normal" mode, we just show everyone
			 -->


			<!-- all non-speakers on the call when in screen share mode - empty otherwise -->
			<Video
				v-for="video in (meetingHandler.remoteScreenSharing ? remoteUserNonSpeakerVideos : [])"
				:video="video"
				:videoCount="videoCount"
				:meetingHandler="meetingHandler"
				:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
				:key="video.id"
				:testVideo="testing" />
		</div>
	</div>
	<div 
		class="mobile-video-container" 
		v-if="mobileDisplayController"
	>
		<!-- mobile layout; screenshare first-->
		<div class="mobile-video-screenshare-container">
			<!-- remote screenshare -->
			<div class="remote-screen" v-if="meetingHandler.remoteScreenSharing">
				<Video
					v-for="video in meetingHandler.visibleDisplayMedias"
					:video="video"
					:videoCount="1"
					:meetingHandler="meetingHandler"
					:videoType="'screen'"
					:key="video.id" />
			</div>
			<!-- local screenshare -->
			<div class="local-screen" v-if="meetingHandler.localScreenSharing">
				<Video
					v-for="video in meetingHandler.visibleDisplayMedias"
					:video="video"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="'screen'"
					:key="video.id" />
			</div>
		</div>
		<!-- pagination -->
		<div @click="mobileDisplayController.getPreviousPage" class="mobilePagingButton left" 
			:class="{ 'disabled' : !mobileDisplayController.prevButtonVisible.value  }">
			<span class="chevron chevron-left"><i class="fas fa-chevron-left"></i></span>
		</div>
		<div @click="mobileDisplayController.getNextPage" class="mobilePagingButton right" 
			:class="{ 'disabled' : !mobileDisplayController.nextButtonVisible.value }">
			<span class="chevron chevron-right"><i class="fas fa-chevron-right"></i></span>
		</div>
		<!-- why do we have this while also the remote screenshare above?? -->
		<div class="mobile-video-grid-container"
			:class="{
				'remote-screenshare' : meetingHandler.remoteScreenSharing
			}"
			 ref="mobileGridContainerDOM">
				<Video
					v-if="meetingHandler.localUserMedia"
					:video="meetingHandler.localUserMedia"
					:videoCount="videoCount"
					:meetingHandler="meetingHandler"
					:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
					:key="meetingHandler.localUserMedia.id"
					:localMediaBlurred="false" />
				<Video
					v-if="remoteUserNonSpeakerVideosMobile.length > 0"
					:video="remoteUserNonSpeakerVideosMobile[0]"
					:videoCount="1"
					:meetingHandler="meetingHandler"
					:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
					:key="remoteUserNonSpeakerVideosMobile[0].id"
					:testVideo="testing" />
				<Video
					v-if="remoteUserNonSpeakerVideosMobile.length > 1"
					:video="remoteUserNonSpeakerVideosMobile[1]"
					:videoCount="1"
					:meetingHandler="meetingHandler"
					:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
					:key="remoteUserNonSpeakerVideosMobile[1].id"
					:testVideo="testing" />
				<Video
					v-if="remoteUserNonSpeakerVideosMobile.length > 2"
					:video="remoteUserNonSpeakerVideosMobile[2]"
					:videoCount="1"
					:meetingHandler="meetingHandler"
					:videoType="meetingHandler.remoteScreenSharing ? 'thumbnail' : 'gallery'"
					:key="remoteUserNonSpeakerVideosMobile[2].id"
					:testVideo="testing" />
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { type Ref, ref, type PropType, onMounted, getCurrentInstance, watch, type ComputedRef } from "vue";
	import { RemoteMedia, Media, type MediaCollection, UserMedia } from "@liveswitch/sdk";
	import Video from "@/components/Video.vue";
	import { computed } from "@vue/reactivity";
	import useHelpers from "@/composables/useHelpers";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	// import "vue3-carousel/dist/carousel.css";
	// import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		localMediaBlurred: {
			type: Boolean,
		},
		screenShare: {
			type: Boolean,
		},
	});

	const testing = ref(true);
	const orientation = ref(screen.orientation || useHelpers().getScreenOrientation());
	const maxDesktopVideos = props.meetingHandler.useLargeGrid ? 9999 : 9;
	const instance = getCurrentInstance();
	//const currentPage = ref(0);
	// const mobileLocalVideoFloating = ref(false);
	// const carousel = ref(null);
	// const sliding = ref(false);
	let maxMobileVideos = parseInt(import.meta.env.VITE_MAX_MOBILE_USERS);
	
	const speakerVideos = ref<Media[]>([]);
	let mobileDisplayController : MobileVideoDisplayController | null = null;

	const maxVideos : ComputedRef<number> = computed<number>(() => {
		let mobileVideos = maxMobileVideos;

		if (props.meetingHandler.remoteScreenSharing) {
			if (orientation.value.type.indexOf("landscape") != -1 && window.screen.width < 1000) {
				mobileVideos = 0;
			} else {
				mobileVideos = maxMobileVideos;
			}
		}

		return useHelpers().isMobileBrowser() ? mobileVideos : maxDesktopVideos;
	});

	let mobileGridContainerDOM : Ref<any> = ref();

	class MobileVideoDisplayController
	{
		public remoteVideoSources : Ref<Array<Media>> = ref([]);

			
		public nextButtonVisible : Ref<boolean> = ref(false);
		public prevButtonVisible : Ref<boolean> = ref(false);

		private videosPerPage : number = (maxVideos.value - 1);
		private currentPage : number = 1;
		private startX: number | null = null;
		private startY: number | null = null;
		private _meetingHandler: MeetingHandler;
		private _maxSpeakers: number = 3
		
		public constructor(meetingHandler: MeetingHandler) {
			this._meetingHandler = meetingHandler
			this.bindEvents();
		}

		private getNonLocalMedias() : Media[] {
			return props.meetingHandler.remoteUserMediasAll;
		}

		private calculateTotalPages(): number {
			// when dealing w/ speakers, we show them all on the first page
			// so when there are less than or equal to that many remote people (plus yourself),
			// we only have one page. as soon as we have > than that many people, we enable paging
			// but add an extra +1 to the count to allow for the first page
			if(this.getNonLocalMedias().length <= this._maxSpeakers){
				return 1
			}
			return Math.ceil(this.getNonLocalMedias().length / this.videosPerPage) + 1 // the +1 here is to account for the first page being speakers
		}

		public getCurrentPage(): number {
			return this.currentPage;
		}

		public getTotalPages(): number {
			return this.calculateTotalPages();
		}

		public setPage(pageNumber: number): void {
			const totalPages = this.calculateTotalPages();
			this.currentPage = Math.max(1, Math.min(pageNumber, totalPages));
		}

		public async getNextPage(): Promise<void> {
			const oldCurrentPage = this.currentPage;
			const totalPages = this.calculateTotalPages();
			this.currentPage = Math.min(this.currentPage + 1, totalPages);
			console.log('getting page ' + this.currentPage + ' vs old page ' + oldCurrentPage)
			if (oldCurrentPage != this.currentPage) {
				this.determineButtonVisibility();
				await this.setMediaElementsForCurrentPage();
			}
		}

		public async getPreviousPage(): Promise<void> {
			const oldCurrentPage = this.currentPage;
			this.currentPage = Math.max(this.currentPage - 1, 1);
			if (oldCurrentPage != this.currentPage) {
				this.determineButtonVisibility();
				await this.setMediaElementsForCurrentPage();
			}
		}

		public hasNextPage(): boolean {
			const totalPages = this.calculateTotalPages();
			return this.currentPage < totalPages;
		}

		public hasPreviousPage(): boolean {
			return this.currentPage > 1;
		}

		private determineButtonVisibility () : void {
			this.nextButtonVisible.value = this.hasNextPage();
			this.prevButtonVisible.value = this.hasPreviousPage();
		}

		private async determineVideoPriority(){
			let visible = this.getVideosForCurrentPage()
			let hidden = this._meetingHandler.remoteUserMediasAll.filter(x=> visible.findIndex(y=>y.id == x.id) == -1)
			await this.setVideoPriority(visible, 'high')
			await this.setVideoPriority(hidden, 'off')
		}

		private async setVideoPriority(mediaArr : Media[], priority : 'off' | 'high') : Promise<void> {
			for (var i = 0, l = mediaArr.length; i < l; i++) {
				const video = mediaArr[i];
				if (video instanceof RemoteMedia) {
					await (video as RemoteMedia).videoTrack?.setPriority(priority);
				}
			}
		}

		private findAddedRecords<T>(newCopy: T[], oldCopy: T[]): T[] {
			const addedRecords = newCopy.filter((record) => !oldCopy.includes(record));
			return addedRecords;
		}

		private getVideosForCurrentPage(): Media[] {
			//const startIndex = (this.currentPage - 1) * this.videosPerPage;
			console.log('getting videos for current page')
			if(this.currentPage == 1){
				// special handling, on the first page, we get the active speakers
				let speakers = this._meetingHandler.remoteUserMediasSpeakers
				console.log('found ' + speakers.length + ' speakers, and ' + this._meetingHandler.remoteUserMediasNonSpeakers.length + ' non-speakers')
				if(speakers.length < this._maxSpeakers){
					speakers = speakers.concat(this._meetingHandler.remoteUserMediasNonSpeakers.slice(0, this._maxSpeakers - speakers.length))
				}
				console.log('found ' + speakers.length + ' speakers after augment')
				return speakers
			}else{
				
				// on the second page, we start with everyone, including the speakers
				// if we want to always just pagingate, no handling of speakers
				let medias = this._meetingHandler.remoteUserMediasAll
				console.log('we are on the second page; length is ' + medias.length)
				
				// start at current page - 1 (to get zero-index) and then -1 to deal with the first page being speakers
				let startIndex = (this.currentPage - 2) * this.videosPerPage
				let endIndex = startIndex + this.videosPerPage;

				console.log(`getting page ${this.currentPage} using index ${startIndex} to ${endIndex} on a list of length ${medias.length}`)

				return medias.slice(startIndex, endIndex);
			}
		}

		private async setMediaElementsForCurrentPage() : Promise<void> {
			
			this.remoteVideoSources.value = this.getVideosForCurrentPage();
			
			//console.log('getting media elements per page', this.currentPage, newMedia.length)
			/*if (newMedia.length === 0 && this.currentPage > 1) {
				return this.getPreviousPage();
			}*/
			//await this.setVideoPriority(newMedia, "high");
			//this.remoteVideoSources.value = newMedia;
			this.determineButtonVisibility();
			await this.determineVideoPriority()
		}

		private bindEvents = () =>
		{	
			/* If this class starts being created in Async contexts. This has to switch to WatchEffect with a UnWatch call on onBeforeUnmount. Otherwise there's a memory leak. */
			watch(() => this._meetingHandler.visibleUserMedias, async (visibleUserMedias, oldVal) => {
				if (visibleUserMedias.length > 0) {
					await this.setMediaElementsForCurrentPage();	
				}
				
			});

			watch(() => this._meetingHandler.remoteUserMediasSpeakers, async () => {
				console.log('speakers changed - update if on first page', this.currentPage)
				if(this.currentPage == 1){
					this.setMediaElementsForCurrentPage()
				}
			})

			useEventBus().onEvent("car-mode-off", async () => {
				console.log('setting car mode off!!')
				await this.setMediaElementsForCurrentPage();
			})
		}

		// Section here deals with paging by swipe on mobile. 
		// You can swipe up and down, or left and right.
		handleTouchStart(event: TouchEvent) {
			const touch = event.touches[0];
			this.startX = touch.clientX;
			this.startY = touch.clientY;
		}

		async handleTouchMove(event: TouchEvent) {
			if (!this.startX || !this.startY) {
				return;
			}
			

			const touch = event.touches[0];
			const deltaX = touch.clientX - this.startX;
			const deltaY = touch.clientY - this.startY;

			// Set a threshold for swipe distance
			const threshold = 6;

			// Check if swipe is horizontal
			if (Math.abs(deltaX) > threshold) {
				if (deltaX > 0) {
					await this.getPreviousPage();
				} else {
					await this.getNextPage();
				}
			}

				// Check if swipe is vertical
				if (Math.abs(deltaY) > threshold) {
				if (deltaY > 0) {
					await this.getPreviousPage();
				} else {
					await this.getNextPage();
				}
			}

			// Check if the touched element has the class 'btn'
			const touchedElement = document.elementFromPoint(this.startX, this.startY);
			if (touchedElement && touchedElement.classList.contains('btn')) {
				(touchedElement as HTMLElement).click();
				event.preventDefault();
			}

			// Reset start coordinates
			this.startX = null;
			this.startY = null;
		}

		public bindDomEvents = () => 
		{
			const grid = mobileGridContainerDOM.value;
			grid.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
			grid.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
		}
	}
	if (useHelpers().isMobileBrowser()) {
		mobileDisplayController = new MobileVideoDisplayController(props.meetingHandler);
	}

	/*const linkCopied = ref(false);

	const allVideos = computed(() => {
		let videos: Media[] = [];
		const filtered = props.meetingHandler.visibleUserMedias.filter(
			(x) => x.attendee?.id != props.meetingHandler.localAttendeeId
		);

		for (var i = 0; i < filtered.length; i++) {
			const video = filtered[i];
			videos.push(video);
		}

		return videos;
	});*/

	const videoCount = computed(() => {
		let count = props.meetingHandler.visibleUserMedias.length

		if (props.meetingHandler.localScreenSharing && props.meetingHandler.localScreenShareVisible) {
			count += 1;
		}

		return count -1;
	});

	/*const maxVisibleUserMedia = computed(() => {
		if (useHelpers().isSafari()) {
			const max = Number.parseInt(import.meta.env.VITE_MAX_SAFARI_USERS);
			return max;
		} else {
			return props.meetingHandler.maxVisibleUserMedias ?? 49;
		}
	});*/

	const remoteUserNonSpeakerVideosMobile = computed(() => {
		return mobileDisplayController?.remoteVideoSources.value || []
	})
	
	const visibleDisplayMedias = computed(() => {
		return props.meetingHandler.visibleDisplayMedias
	})

	const remoteUserAllVideos = computed(() => {
		return props.meetingHandler.remoteUserMedias
	})
	const remoteUserSpeakerVideos = computed(() => {
		return props.meetingHandler.remoteUserMediasSpeakers
	});

	const remoteUserNonSpeakerVideos = computed(() => {
		return props.meetingHandler.remoteUserMediasNonSpeakers
	});

	onMounted(() => {
		init();
		if (mobileDisplayController) {
			mobileDisplayController.bindDomEvents();
		}
		if(!props.meetingHandler.localUserMedia){
			console.error('local user media is not set')
		}
	});


	function init() {
		useEventBus().onEvent("orientation-change", async (newOrientation: ScreenOrientation) => {
			orientation.value = newOrientation;
			await instance?.proxy?.$forceUpdate();
		});

	}

	const copyLink = async () => {
		'use strict';
		const copyMeetingButton = document.getElementById('button-copy-meeting');
		if(!copyMeetingButton){
			return
		}
		try {
			copyMeetingButton.innerText = 'Loading...';
			await props.meetingHandler.copyMeetingLink();
			copyMeetingButton.innerText = 'Copied successfully!';
			setTimeout(() => {
				copyMeetingButton.innerHTML = 'Copy Meeting Link <i class="fas fa-copy pl-2"></i>';
			}, 2000);
		} catch (error) {
			// Handle any errors that occurred during the copy process
			console.error('Error copying content:', error);
			copyMeetingButton.innerHTML = 'Copy Meeting Link <i class="fas fa-copy pl-2"></i>';
		}
	}

	 function getTestVideos(limit: number) {
	 	let testVids: Media[] = [];
	 	let video: Media = props.meetingHandler.localUserMedia;
	 	for (var i = 0; i < limit; i++) {
	 		testVids.push(video);
	 	}
		return testVids
	 }

	
</script>

<style scoped>
	.video-grid-container {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
	}

	.remote-screen {
		width: 80%;
	}

	.mobile-grid .remote-screen {
		width: 100%;
	}

	.video-grid {
		display: flex;
		width: 100%;
		height: 100%;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		flex-direction: row;
	}

	.thumbnail-container {
		display: flex !important;
		flex-direction: row;
		align-items: center;
		height: 100%;
		width: 20%;
		max-height: 100%;
		overflow-y: scroll;
	}

	.screen-share {
		height: 50% !important;
	}

	.solo-mobile-invite {
		display: none;
		margin-top: 24px;
		text-align: center;
		color: white;
		max-width: 100%;
	}

	.solo-mobile-invite .share-text {
		font-family: "Inter_Light";
	}

	.solo-mobile-invite .meeting-link {
		border-right: 1px solid #343b4a;
		height: 100%;
		display: flex;
		align-items: center;
		max-width: 85%;
		padding: 12px;
		font-size: 13px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.solo-mobile-invite .meeting-link .fa-lock {
		opacity: 0.5;
		margin-right: 8px;
	}

	.solo-mobile-invite .meeting-link .meeting-link-url {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.solo-mobile-invite .copy-meeting-link {
		display: flex;
		flex: 0;
		justify-content: center;
		font-size: 18px;
		padding: 12px 16px;
		cursor: pointer;
	}

	.solo-mobile-invite .share-meeting {
		margin-top: 16px;
		font-family: "Inter_Light";
	}

	.solo-mobile-invite .share-meeting .share-invite {
		background-color: #346ee0;
		border-radius: 10px;
		padding: 8px 16px;
		color: white;
		font-size: 14px;
		line-height: 2;
	}

	@media (min-width: 320px) and (max-width: 767px) {

		.solo-mobile-invite {
			display: flex !important;
			flex-direction: column;
		}

		.solo-mobile-invite .link-copied {
			font-size: 9px;
		}
	}

	@media (min-width: 1600px) and (max-height: 1200px) and (orientation: landscape) {
		.mobile-grid {
			flex-wrap: nowrap;
		}

		.mobile-grid .video[type="user"],
		.mobile-grid canvas {
			width: auto !important;
			height: auto !important;
			min-height: 50%;
			max-height: 100% !important;
			min-width: 25%;
			max-width: 50%;
		}

		.mobile-grid.flex-row .video[type="user"],
		.mobile-grid.flex-row canvas {
			height: 100% !important;
		}

		.video-container-mobile-screen .video-grid[type="screen"]{
			height: 70%;
		}

		.video-container-mobile-screen .video-grid[type="user"] {
			height: 30%;
		}
	}

	@media (min-width: 1200px) and (max-height: 2000px) and (orientation: portrait) {
		.video-container-mobile-screen .video-grid[type="screen"] {
			height: 40%;
		}

		.video-container-mobile-screen .video-grid[type="user"] {
			height: 60%;
		}
	}

	.mobile-grid {
		height: 50%;
	}

	@media (orientation: landscape) and (max-height: 420px) {
		.video-grid[type="screen"] {
			height: 100%;
		}

		.mobile-grid[type="user"] {
			display: none;
		}

		.mobile-grid[type="screen"] {
			height: 100%;
		}

		.solo-mobile-invite {
			display: block;
		}
	}

	@media (min-height: 500px) {
		.solo-mobile-invite {
			display: none;
		}
	}

	@media (orientation: portrait) {
		.video-grid.mobile-grid {
			flex-direction: column !important;
		}
	}

	.mobile-video-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.mobile-video-grid-container {
		display: -ms-inline-grid;
		display: inline-grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 1fr;
		vertical-align: middle;
		flex-grow: 1;
		flex-shrink: 1;
		height:100%;
	}

	.mobile-video-grid-container.remote-screenshare{
		height:50%;
	}

	/* 4 quadrants */
	.mobile-video-grid-container:has(> :last-child:nth-child(1)) {
		grid-template-columns: 1fr;
	}
	.mobile-video-grid-container:has(> :last-child:nth-child(2)) {
		grid-template-columns: 1fr;
	}
	@media screen and (orientation : landscape) {
		/* side by side on landscape */
		.mobile-video-grid-container:has(> :last-child:nth-child(2)) {
			grid-template-columns: 1fr 1fr;
		}
	}
	.mobile-video-grid-container:has(> :last-child:nth-child(3)) {
		grid-template-columns: 1fr 1fr;
	}

	/* in landscape, left to right */
	@media screen and (orientation : landscape) {
		
		div.mobile-video-container > .mobilePagingButton {
			top: 75px;
		}

		div.mobile-video-container:has(> .mobile-video-screenshare-container:not(:empty)) .mobile-video-grid-container,
		div.mobile-video-container:has(> .mobile-video-screenshare-container:not(:empty)) .mobilePagingButton {
			display: none;
		}
		
	}

	

	.mobile-video-screenshare-container {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex-grow: 0;
		flex-shrink: 0;
		align-items: center;
	}

	.mobile-video-screenshare-container:not(:empty) {
		height: 50%;
		width: 100%;
	}

	@media screen and (orientation: landscape) {
		/* on mobile, in landscape, we take the whole space for the screenshare; overrides the 50% above */
		.mobile-video-screenshare-container:not(:empty){
			height:100%;
		}
	}

	/* This makes the assumption there wont be more then 1 screen at a time. */
	.mobile-video-screenshare-container > .remote-screen, 
	.mobile-video-screenshare-container  > .local-screen,
	.mobile-video-screenshare-container > .remote-screen > div.video,
	.mobile-video-screenshare-container  > .local-screen > div.video {
		width: 100%;
		height: 100%;
	}

	.mobilePagingButton {
		position: absolute;
		z-index: 99;
		top: 80px;
		background-color: #323b4b;
		padding:8px;
	}

	.mobilePagingButton.left {
		left: 0px;
		border-top-right-radius: 8px;
    	border-bottom-right-radius: 8px;
	}

	.mobilePagingButton.right {
		right: 0px;
		border-top-left-radius: 8px;
    	border-bottom-left-radius: 8px;
	}

	.mobilePagingButton.disabled {
		pointer-events: none;
		color: lightgrey;
		display: none;
	}

	.solo-mobile-invite {
		height: 100%;
		justify-content: center;
	}
</style>