<template>
	<nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark"
		 v-bind:class="{ 'navbar-call-ended': callEnded }">
		<div class="ls-container">
			<a :target="callEnded ? '' : '_blank'" :href="userSiteUrl">
				<img src="@/assets/img/ls-video-3D-white-logo.svg"
					 alt="LiveSwitch"
					 id="liveswitch-logo"
					 class="logo"
					 :class="{ 'blue-logo': callEnded }"
					 v-if="!customLogoUrl && !callEnded" />

				<img src="@/assets/img/ls-video-3D-color-logo.svg"
					 alt="LiveSwitch"
					 id="liveswitch-logo"
					 class="logo"
					 :class="{ 'blue-logo': callEnded }"
					 v-if="!customLogoUrl && callEnded" />

				<img :src="customLogoUrl" alt="LiveSwitch" class="custom-logo" v-if="customLogoUrl" />
			</a>
		</div>

		<div class="record-circle-container desktop-element" v-if="joined && meetingHandler.isRecording">
			<span class="circle"></span>
			<span class="text">REC</span>
		</div>

		<div v-show="false"
			 v-if="joined && !callEnded && meetingHandler.localAttendee"
			 style="margin-right: 10px"
			 title="Network Quality">
			<i v-if="meetingHandler.localAttendee.quality == 'low'" class="icon-wifi-2 fa-lg low-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Fair</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'medium'" class="icon-wifi-3 fa-lg medium-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Good</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'high'" class="icon-wifi-4 fa-lg high-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Great</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'unknown'" class="icon-wifi-1 fa-lg unknown-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Bad</div>
				</div>
			</i>
		</div>

		<a class="navbar-brand mr-4 waiting-pulse"
		   id="btn-waiting"
		   style="color: white; cursor: pointer"
		   v-if="joined && !callEnded && isMyRoom && haveWaiting"
		   @click="toggleWaitingRoom">
			<i class="fa-solid fa-user-clock fa-lg"></i>
		</a>

		<a 
			class="navbar-brand mr-4"
			id="btn-car-mode"
			:style="{
				'color': props.meetingHandler.carMode ? 'limegreen' : 'white',
				'cursor': 'pointer'
			}"
		   	v-if="joined && !callEnded"
			data-toggle="tooltip"
			:title="props.meetingHandler.carMode ? 'Disable Slow Internet Mode' : 'Enable Slow Internet Mode'"
			data-placement="top"
		   	@click="props.meetingHandler.toggleCarMode()">
			<i class="icon-wifi-speed" style="font-size:32px;margin-right:-8px" v-if="!useHelpers().isMobileBrowser()"></i>
			<i class="fas fa-lg fa-car" v-else></i>
		</a>

		<a class="navbar-brand mr-4"
		   id="btn-settings"
		   style="color: white; cursor: pointer"
		   v-if="joined && !callEnded"
		   @click="toggleSettings">
			<i class="fas fa-cog fa-lg"></i>
		</a>

		<div class="mobile-nav-container mobile-logo" :class="!joined && !callEnded ? 'lobby' : ''">
			<a target="_blank" :href="userSiteUrl">
				<img src="@/assets/img/logo_mobile.png" alt="LiveSwitch Video" v-if="!customLogoUrl"/>
				<img :src="customLogoUrl" alt="LiveSwitch" class="custom-logo" v-if="customLogoUrl" />
			</a>
		</div>
		<div v-if="joined"
			 id="mobile-meeting-menu"
			 class="mobile-nav-container mobile-meeting-menu"
			 @click="useEventBus().emitEvent('toggle-share-modal')">
			{{ meetingSubject ?? "LiveSwitch Video Meeting" }} <i class="fas fa-angle-down"></i>
		</div>

		<div class="mobile-nav-container" v-if="joined">
			<div class="record-circle-container" style="margin-right: 0;" v-if="meetingHandler.isRecording">
				<span class="circle"></span>
				<!--<span class="text">REC</span>-->
			</div>		
		</div>
		<div class="alert alert-success notification"
			 :class="navAlertHtml.length > 50 && useHelpers().isMobileBrowser() ? 'mobile-notification' : ''"
			 v-show="navAlert"
			 v-html="navAlertHtml"></div>
		<div class="alert alert-success notification" v-show="handRaised">
			<label><strong>{{ handRaisedUserName }}</strong> hand is raised.</label>
			<i class="fas fa-hand-paper fa-spin" style="color: #ffd279; filter: drop-shadow(0 0 1px #fff)"></i>
		</div>
		<div class="alert alert-success notification" id="recording-alert" style="display: none">
			<label id="recording-message-label"></label>
			<span class="elapsed-time-text" style="display: none" id="record-timer">00:00</span>
		</div>

		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signin"
		   v-if="showAuthSection && showLoginButtons"
		   @click="redirectToLogin">
			Sign In
		</a>

		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signup"
		   v-if="showAuthSection && showLoginButtons"
		   @click="redirectToRegistration">
			Sign Up
		</a>

		<span v-if="showAuthSection && isSignedIn && !joined && !callEnded" class="h4" style="margin-top:auto;margin-bottom:auto">Welcome, {{ firstName() }}!</span>
		
		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signin"
		   v-if="showAuthSection && isSignedIn && !joined && !callEnded"
		   @click="redirectToLogout">
			Sign Out
		</a>
	</nav>
</template>
<script lang="ts" setup>
	import { computed, onBeforeMount, onMounted, ref, type PropType, inject } from "vue";
	import useEventBus from "@/composables/useEventBus";
	import type { Attendee } from "@liveswitch/sdk";
	import type LobbyHandler from "@/classes/LobbyHandler";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useHelpers from "@/composables/useHelpers";
	import type { AuthenticationValidationResponse } from "@/classes/AuthenticationService";
	import useLocalStorage from "@/composables/useLocalStorage";
	import type { IChannelService } from "@/classes/ChannelService";
	import { channelServiceKey } from "@/composables/injectKeys";

	const channelService = inject(channelServiceKey) as IChannelService;
	const navAlerts: { text: string; timeout?: number }[] = [];

	const props = defineProps({
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		meetingSubject: {
			type: String,
			required: true,
		},
		infobox: Object as any,
		infoText: Object as any
	});

	const callEnded = ref(false);
	const joined = ref(false);
	const isSignedIn = ref(false);
	const onReferral = ref(false);
	const showAuthSection = ref(false);
	const userName = ref("");
	const firstName = () =>{
		return userName.value ? userName.value.split(' ')[0] : ''
	}
	const handRaised = ref(false);
	const navAlert = ref(false);
	const navAlertHtml = ref("");
	const handRaisedUserName = ref("");
	const eventBus = useEventBus();
	const userSiteUrl = import.meta.env.VITE_USER_SITE_URL;
	const customLogoUrl = ref("");
	let alertTimeout: number = 0;
	let handRaisedTimeout: number = 0;
	const isMyRoom = ref(false);

	//const isFreeAccount = computed(() => props?.meetingHandler.isFreeAccount);

	onBeforeMount(() => {
		const params = useHelpers().getParams();
		if (useHelpers().isAuthenticatedUser() && params.has("referral")) {
			onReferral.value = true;
		}
	});

	onMounted(() => {
		eventBus.onEvent("joined", () => {
			joined.value = true;
		});

		eventBus.onEvent("full-auth-validated", (response: AuthenticationValidationResponse) => {
			userName.value = response.DisplayName ?? response.Email ?? "Guest";
			isSignedIn.value = response.Email != undefined;
			showAuthSection.value = true;
		});

		eventBus.onEvent("leave-call", () => {
			joined.value = false;
			callEnded.value = true;
		});

		eventBus.onEvent("nav-alert", (alert: { text: string; timeout?: number }) => {
			showAlert(alert);
		});

		eventBus.onEvent("hand-raised", (attendee: Attendee) => {
			handRaised.value = true;

			if (props.meetingHandler.localAttendeeId == attendee.id) {
				handRaisedUserName.value = "Your";
			} else {
				let possesion: string = "'s";
				let lastCharacter = attendee.displayName[attendee.displayName.length - 1];

				if (lastCharacter == "s") {
					possesion = "'";
				}

				handRaisedUserName.value = `${attendee.displayName}${possesion}`;
			}

			clearTimeout(handRaisedTimeout);

			handRaisedTimeout = setTimeout(() => {
				handRaised.value = false;
			}, 3000);
		});

		eventBus.onEvent("hand-lowered", () => {
			handRaised.value = false;
		});

		eventBus.onEvent("custom-logo-updated", (logoUrl: string) => {
			customLogoUrl.value = logoUrl;
		});

		isMyRoom.value = useLocalStorage().getChannel()?.MeetingOwner ?? false;
		eventBus.onEvent("host-signed-in-mid-meeting", async () => {
			isMyRoom.value = useLocalStorage().getChannel()?.MeetingOwner ?? false;
		});
	});

	const haveWaiting = computed(() => {
		return props.lobbyHandler.lobbyUserCount > 0;
	});

	function showAlert(alert: { text: string; timeout?: number }) {
		if (alertTimeout > 0) {
			navAlerts.push(alert);
			return;
		}

		navAlertHtml.value = alert.text;
		navAlert.value = true;

		if (!alert.timeout) {
			alert.timeout = 5000;
		}

		alertTimeout = setTimeout(() => {
			navAlert.value = false;
			navAlertHtml.value = "";
			alertTimeout = 0;

			const nextAlert = navAlerts.splice(0, 1);

			if (nextAlert.length > 0) {
				showAlert(nextAlert[0]);
			}
		}, alert.timeout);
	}

	function toggleWaitingRoom() {
		props.meetingHandler.waitingRoomOpen = !props.meetingHandler.waitingRoomOpen;
	}

	function toggleSettings() {
		eventBus.emitEvent("settings-open", "settings");
	}

	function redirectToLogin() {
		window.location.assign("/login");
	}

    function redirectToLogout() {
        window.location.href = `${import.meta.env.VITE_USER_SITE_URL}/Account/SignOut`;
    }

	function redirectToRegistration() {
		window.location.assign("/register");
	}

	const showLoginButtons = computed(() => {
		return !isSignedIn.value && !joined.value && !callEnded.value && !onReferral.value;
	});
</script>

<style lang="scss" scoped>
	#liveswitch-logo {
		height: 37px;
	}

	header {
		height: 72px;
	}

	nav {
		height: 100%;
	}
	.mobile nav{
		height: 48px;
	}
	.ls-container {
		flex: 1;
	}
	.logo {
		cursor: pointer;
	}

	.custom-logo {
		max-width: 156px;
		max-height: 56px;
		cursor: pointer;
	}

	.blue-logo {
	}

	.mobile-nav-container {
		display: none;
	}

	.navbar {
		justify-content: space-between !important;
		padding: 0.5rem 1rem;
	}

	.alert-success {
		font-family: "Inter_Medium";
		margin: 0 auto;
		position: absolute;
		width: fit-content;
		width: -moz-fit-content;
		width: -webkit-fit-content;
		z-index: 10000;
		left: 0;
		right: 0;
		font-size: 18px;
		text-align: center;
		border-radius: 5px;
		border: none;
		background-color: black;
		padding: 10px 18px;
		top: 10px;
		color: white;
	}

	.alert-success .fa-hand-paper {
		margin-left: 12px;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		body {
			min-width: 100% !important;
			min-height: 100% !important;
			max-width: 100% !important;
		}

		.ls-container {
			display: none;
		}

		header {
			height: 65px;
		}

		#btn-settings {
			display: none;
		}

		.mobile-nav-container {
			display: flex;
		}

		.mobile-nav-container.mobile-logo.lobby {
			flex: 1;
		}

		.mobile-meeting-menu {
			flex: 1 1 auto;
			align-items: center;
			justify-content: center;
			color: white;
			font-family: "Inter_Light";
		}

		.mobile-meeting-menu .fa-angle-down {
			margin-left: 6px;
			margin-top: 2px;
		}

		.mobile-logo img {
			max-height: 32px;
		}

		.btn-leave {
			width: unset;
			height: unset;
			margin: 0;
		}

		.navbar {
			width: 100%;
			height: unset !important;
			padding: 0.5rem !important;
			box-shadow: 0px 1px 1px #222;
		}

		.navbar-call-ended {
			box-shadow: 0px 1px 1px #bbb;
		}

		.btn-my-world-lg {
			background-color: #346ee0 !important;
			height: 44px;
			width: 44px;
			padding: 8px !important;
			border-radius: 50% !important;
		}
		
		.alert-success {
			position: fixed;
		}

		.mobile-notification {
			font-size: smaller;
		}
	}

	.record-circle-container {
		margin-top: 0;
		margin-right: 15px;
		display: flex;
		align-items: center;
		background: #f44a4a;
		text-align: center;
		border-radius: 5px;
		padding: 8px;
	}

	.record-circle-container .circle {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background-color: white;
	}

	.record-circle-container .text {
		font-family: "Inter_Light" !important;
		margin-left: 5px;
		font-size: 16px;
		font-weight: 500;
	}

	.navbar-signin {
		margin-left: 10px;
	}

	.navbar-signin:hover {
		color: #ccc;
	}

	#btn-waiting.waiting-pulse {
		-webkit-animation: pulse 1.5s infinite;
	}

	@-webkit-keyframes pulse {
		0% {
			color: #fff;
		}
		50% {
			color: #346ee0;
		}
		100% {
			color: #fff;
		}
	}

	.low-quality,
	.unknown-quality {
		color: red;
	}

	.medium-quality {
		color: darkorange;
	}

	.high-quality {
		color: greenyellow;
	}

	.low-quality,
	.unknown-quality,
	.medium-quality,
	.high-quality {
		.tooltip-container {
			position: absolute;
			width: 50px;
			top: 45px;
			pointer-events: none;
			user-select: none;
			z-index: 1;
			display: flex;
			justify-content: center;
			.tooltip {
				background: #0f1218;
				padding: 2px 8px;
				border-radius: 10px;

				color: white;
				opacity: 0;
				transition: 0.2s display;
			}

			.tooltip-persistant {
				opacity: 1;
				font-size: 0.7rem;
			}
		}
		&:hover .tooltip {
			opacity: 1;
		}
	}
</style>
