<template>
	<div class="auth-container">
		<div class="form-group">
			<label class="form-label" for="email">Email</label>
			<input type="text" id="email" class="form-control" v-model="email" @keydown.enter="signIn('USER')" />
		</div>
		<div class="form-group">
			<label class="form-label" for="password">Password</label>
			<input
				type="password"
				id="password"
				class="form-control"
				v-model="password"
				@keydown.enter="signIn('USER')" />
		</div>
		<div class="form-group">
			<div class="row d-flex justify-content-md-end justify-content-end support-links">
				<button
					type="button"
					class="btn btn-link ls-btn-link btn-forgot-pass"
					data-test="pass-reset"
					@click="openResetPassword">
					Forgot Password?
				</button>
			</div>
		</div>
		<!--		<vue-recaptcha-->
		<!--			class="ls-captcha"-->
		<!--			ref="recaptcha"-->
		<!--			:sitekey="AuthenticationService.getCaptchaKey()"-->
		<!--			@verify="verifyCaptcha"-->
		<!--			@expire="expireCaptcha" />-->
		<div class="form-group">
			<button class="btn-primary ls-btn" @click="signIn('USER')" v-bind:disabled="email == '' || password == ''">
				Sign In
			</button>
		</div>
		<div class="or-separator">OR</div>

		<form :action="ssoAction" method="post" ref="ssoForm" id="SSO" :target="inMeeting ? 'SSO' : ''">
			<div class="form-group">
				<button type="button" class="ls-btn btn-google" @click="signInSSO(ACCOUNT_TYPE_GOOGLE)">
					<img src="../assets/img/btn_google_dark_normal_ios.svg" height="26" width="26" />
					Sign In with Google
				</button>
			</div>
			<!-- <div class="form-group">
				<button type="button" class="ls-btn btn-facebook" @click="signInSSO(ACCOUNT_TYPE_FACEBOOK)">
					<i class="fab fa-facebook-f"></i>
					Sign In with Facebook
				</button>
			</div> -->
			<div class="form-group">
				<button type="button" class="ls-btn btn-linked-in" @click="signInSSO(ACCOUNT_TYPE_LINKEDIN)">
					<i class="fab fa-linkedin-in"></i>
					Sign In with LinkedIn
				</button>
			</div>
			<input name="AccountType" type="hidden" :value="ssoAccountType" />
			<input name="SharedFingerprint" type="hidden" :value="serializedFingerprint" />
			<input name="ReturnUrl" type="hidden" :value="returnUrl" />
			<input name="InviteToken" type="hidden" :value="inviteToken" />
			<input name="Timezone" type="hidden" :value="timezone" />
		</form>
	</div>

	<div
		class="modal fade reset-password-modal"
		:class="{ show: showResetPassword }"
		id="reset-password-modal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="exampleModalCenterTitle"
		aria-hidden="true"
		data-backdrop="static"
		data-keyboard="false">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Forgot Password</h5>
					<i class="fal fa-times close" @click="closeResetPassword"></i>
				</div>
				<div class="modal-body">
					<div v-show="resetPasswordComplete" class="validation-messages-wrapper mb-2">
						<span id="resetPasswordErrorWrapper" class="validate-message"></span>
						<div id="resetPasswordInfoWrapper">
							<span>
								{{ resetPasswordMessage }}
							</span>
						</div>
					</div>
					<div v-show="!resetPasswordComplete" class="ls-form-group reset-password-wrapper">
						<label id="emailFloatingLabel" class="form-group d-block m-1">Enter email address</label>
						<input
							v-model="resetPasswordEmail"
							type="email"
							class="px-3"
							aria-labelledby="emailFloatingLabel" />
					</div>
					<input type="hidden" class="form-control" id="resetPasswordProviderName" />
					<div class="col-12">
						<button
							v-show="!resetPasswordComplete"
							@click="resetPassword"
							class="ls-btn ls-btn-blue ls-btn-main">
							Submit
						</button>
						<button
							v-show="resetPasswordComplete"
							@click="closeResetPassword"
							class="ls-btn ls-btn-blue ls-btn-main">
							OK
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ApiSignInRequest, ApiSignInResponse, IAuthenticationService } from "@/classes/AuthenticationService";
	import useEventBus from "@/composables/useEventBus";
	import { computed, ref } from "@vue/reactivity";
	import { onMounted, nextTick, inject, onUnmounted } from "vue";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import { getCookie } from "typescript-cookie";
	import type { ChannelDetails } from "@/classes/ChannelService";
	import { useGtm } from "@gtm-support/vue-gtm";
	import { authenticationServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
	import useHelpers from "@/composables/useHelpers";
	import { useCookies } from "vue3-cookies";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	let currentChannel = "";

	const ACCOUNT_TYPE_GOOGLE = "USER_GOOGLE";
	const ACCOUNT_TYPE_FACEBOOK = "USER_FACEBOOK";
	const ACCOUNT_TYPE_LINKEDIN = "USER_LINKEDIN";
	const LOGIN_METHOD_GOOGLE = "Google";
	const LOGIN_METHOD_FACEBOOK = "Facebook";
	const LOGIN_METHOD_LINKEDIN = "LinkedIn";
	const LOGIN_METHOD_USERNAME = "Username";
	const AUTHORIZATION_COOKIE_NAME = "Authorization";
	const REFRESH_TOKEN_COOKIE_NAME = "RefreshToken";
	const GOOGLE_CALENDAR_CALLBACK_URL = "googleCalendarCallbackUrl";
	const ssoForm = ref<HTMLFormElement>();
	const ssoAccountType = ref("");
	const email = ref("");
	const password = ref("");
	const confirmPassword = ref("");
	const userFingerprint = ref<Object>();
	const serializedFingerprint = ref("");
	const recaptcha = ref(null);
	const captcha = ref("");
	const inviteToken = ref("");
	const timezone = ref("");
	const showResetPassword = ref(false);
	const resetPasswordEmail = ref("");
	const resetPasswordComplete = ref(false);
	const resetPasswordMessage = ref();
	const ssoInterval = ref(0);

	onMounted(async () => {
		const queryString = window.location.search;

		if (queryString.includes("ssoComplete") || queryString.includes("ssoError")) {
			useEventBus().emitEvent("loading", "Logging in...");
		}

		appInsights.trackEvent({
			name: "SignInLoaded",
			properties: useHelpers().getLoggingProperties("SignInLoaded", "SignInLoaded", {
				referrer: document.referrer || window.frames?.top?.document.referrer,
			}),
		});

		try {
			await authenticationService.clearDeadCookies();

			if (props.action) {
				localStorage.setItem("auth-action", props.action);

				const channel: ChannelDetails = JSON.parse(localStorage.getItem("Channel"));

				if (channel) {
					currentChannel = channel.ChannelKey;
				}
			}

			let userSiteUrl = import.meta.env.VITE_USER_SITE_URL;
			let clientSiteUrl = import.meta.env.VITE_CLIENT_SITE_URL;
			let adminSiteUrl = import.meta.env.VITE_ADMIN_SITE_URL;
			let referrer = document.referrer;

			if (
				referrer !== window.location.origin &&
				referrer !== window.location.origin + "/" &&
				referrer !== window.location.href &&
				(referrer?.startsWith(userSiteUrl) ||
					referrer?.startsWith(clientSiteUrl) ||
					referrer?.startsWith(adminSiteUrl))
			) {
				localStorage.setItem("auth-referrer", referrer);
			}

			if (useHelpers().isMobileBrowser()) {
				if (referrer.includes("www.google.com") || referrer.includes("apple.com")) {
					localStorage.setItem("mobile-app-referrer", referrer);
				}
			}

			const webFingerprint = await authenticationService.getFingerprint();
			userFingerprint.value = { WebFingerprint: webFingerprint };
			userFingerprint.value.WebFingerprint.components.canvas = {};
			userFingerprint.value.WebFingerprint.components.math = {};
			userFingerprint.value.WebFingerprint.components.plugins = {};
			serializedFingerprint.value = JSON.stringify(userFingerprint.value);

			const params = new URLSearchParams(window.location.search.split("#")[0]);
			const googleCalendarCallbackUrl = params.get(GOOGLE_CALENDAR_CALLBACK_URL);
			const callbackComplete = params.get("callbackComplete");

			if (callbackComplete == "true") {
				window.close();

				const mobileAppReferrer = localStorage.getItem("mobile-app-referrer");

				if (mobileAppReferrer?.includes("www.google.com")) {
					window.location = "https://calendar.google.com";
				} else if (mobileAppReferrer?.includes("apple.com")) {
					window.location = "calshow://";
				}

				localStorage.removeItem("mobile-app-referrer");
			}

			if (googleCalendarCallbackUrl) {
				localStorage.setItem(GOOGLE_CALENDAR_CALLBACK_URL, googleCalendarCallbackUrl);
			}

			if (params.get("ssoComplete") === "true") {
				useEventBus().emitEvent("loading", "Logging in...");
				await authenticationService.clearDeadCookies();
				let ssoResponse = authenticationService.signInSSO();
				let response = await authenticationService.signIn(undefined, ssoResponse);

				if (response.isValid()) {
					if (!response.Error && response.Message?.Reason) {
						Swal.fire({
							title: "Success",
							html: response.Message?.Reason,
							icon: "success",
							confirmButtonText: "Close",
						}).then(() => {
							onAuthenticated(response, getLoginMethod());
						});
					} else {
						onAuthenticated(response, getLoginMethod());
					}
				}
			} else if (params.get("ssoError") === "true") {
				let message;
				const responseBase64 = getCookie("X-Sign-In-Response");

				if (responseBase64) {
					const responseJson = atob(responseBase64);
					message = JSON.parse(responseJson)?.Message?.Reason;
				}

				message = message ?? "An unexpected error occurred during single sign in. Please try again.";
				useEventBus().emitEvent("loading-complete");

				Swal.fire({
					title: "Error",
					html: message,
					confirmButtonText: "Close",
				});
			}

			inviteToken.value = params.get("inviteToken");
			timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;

			if (!props.action) {
				useHelpers().removeQueryParams(["ssoComplete", "ssoError"]);
			}
		} catch (error: any) {
			appInsights.trackException(
				{
					exception: error,
					id: "SSOLoginFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("SSOLoginFailed", "SSOLoginFailed", { referrer: document.referrer })
			);

			useEventBus().emitEvent("loading-complete");
		}
	});

	const props = defineProps({
		action: String,
		inMeeting: Boolean,
	});

	const emits = defineEmits(["onAuthenticated"]);

	const ssoAction = authenticationService.getApiEndpoint() + "api/Account/SSOChallenge";
	let returnUrl = window.location.href.split("#")[0];
	let index;

	if ((index = returnUrl.indexOf("ssoComplete=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	if ((index = returnUrl.indexOf("ssoError=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	returnUrl += "#";

	if (props.inMeeting) {
		returnUrl = window.location.origin + "/meetingsso";
	}

	async function signInSSO(accountType: string) {
		ssoAccountType.value = accountType;
		await nextTick();
		if (props.inMeeting) {
			window.open("", "SSO");
		}
		ssoForm.value?.submit();

		if (props.inMeeting) {
			ssoInterval.value = window.setInterval(() => {
				if (
					useCookies().cookies.isKey("X-Sign-In-Response") &&
					useCookies().cookies.isKey("Authorization") &&
					useCookies().cookies.isKey("RefreshToken")
				) {
					let loginMethod = LOGIN_METHOD_GOOGLE;
					if (ssoAccountType.value == ACCOUNT_TYPE_FACEBOOK) {
						loginMethod = LOGIN_METHOD_FACEBOOK;
					} else if (ssoAccountType.value == ACCOUNT_TYPE_LINKEDIN) {
						loginMethod = LOGIN_METHOD_LINKEDIN;
					}
					const responseBase64 = getCookie("X-Sign-In-Response");
					if (responseBase64) {
						const responseJson = atob(responseBase64);
						const jsonObject = JSON.parse(responseJson);
						if (jsonObject?.DisplayName) {
							localStorage.setItem("Username", jsonObject?.DisplayName);
						}
						if (jsonObject?.UserAccountId) {
							localStorage.setItem("UserAccountId", jsonObject?.UserAccountId);
						}
						if (jsonObject?.SelectedTenantId) {
							localStorage.setItem("TenantId", jsonObject?.SelectedTenantId);
						}
					}
					onAuthenticated({} as any, loginMethod);
					clearSSOInterval();
				}
			}, 1000);
		}
	}

	function clearSSOInterval() {
		if (ssoInterval.value != 0) {
			window.clearInterval(ssoInterval.value);
			ssoInterval.value = 0;
		}
	}

	onUnmounted(() => {
		clearSSOInterval();
	});

	function getLoginMethod(): string {
		if (document.referrer.toLowerCase().indexOf("google") != -1) {
			return LOGIN_METHOD_GOOGLE;
		} else if (document.referrer.toLowerCase().indexOf("facebook") != -1) {
			return LOGIN_METHOD_FACEBOOK;
		} else if (document.referrer.toLowerCase().indexOf("linkedin") != -1) {
			return LOGIN_METHOD_LINKEDIN;
		}

		return LOGIN_METHOD_USERNAME;
	}

	async function signIn(accountType: string) {
		if (!captcha.value && !authenticationService.isAutomatedTest()) {
			Swal.fire({
				title: "Error",
				text: "You must complete the reCAPTCHA before signing in.",
				confirmButtonText: "Close",
			});
			return;
		}

		useEventBus().emitEvent("loading", "Logging in...");

		let response = await authenticationService.signIn(
			new ApiSignInRequest({
				AccountType: accountType,
				UserName: accountType !== "ANONYMOUS" ? email.value : "Guest",
				Password: password.value,
				Platform: window.navigator.platform,
				SharedFingerprint: userFingerprint.value,
				OneTimeLogin: false,
				Captcha: captcha.value,
				CurrentChannelKey: currentChannel,
				InviteToken: inviteToken.value,
			})
		);

		if (response.isValid()) {
			onAuthenticated(response, LOGIN_METHOD_USERNAME);
		} else {
			recaptcha.value?.reset();
			Swal.fire({
				title: "Error",
				text: response.Message?.Reason ?? "An unexpected error occurred during sign in. Please refresh and try again.",
				confirmButtonText: "Close",
			});

			useEventBus().emitEvent("loading-complete");
		}
	}

	async function verifyCaptcha(response) {
		captcha.value = response;
	}

	async function expireCaptcha() {
		captcha.value = "";
	}

	function onAuthenticated(response: ApiSignInResponse, method: string) {
		let referrer = localStorage.getItem("auth-referrer");
		localStorage.removeItem("auth-referrer");

		let action = localStorage.getItem("auth-action");
		localStorage.removeItem("auth-action");

		useGtm().trackEvent({
			event: "login",
			category: "auth",
			action: "click",
			method: method,
		});

		appInsights.trackMetric({
			name: "login",
			average: 1,
		});

		if (useHelpers().isAuthReferrer(referrer)) {
			referrer = null;
		}

		const params = new URLSearchParams(window.location.search);

		if (params.get("returnUrl") != null && params.get("returnUrl") != "") {
			window.location = params.get("returnUrl");
		} else if (action) {
			emits("onAuthenticated", response);
		} else if (localStorage.getItem(GOOGLE_CALENDAR_CALLBACK_URL)) {
			// TODO: Review callback URL lifecycle.

			const isAndroid = useHelpers().isMobileBrowser() && !useHelpers().isMobileIos();
			const googleCalendarCallbackUrl = `${localStorage.getItem(
				GOOGLE_CALENDAR_CALLBACK_URL
			)}&authenticationToken=${getCookie(AUTHORIZATION_COOKIE_NAME)}&refreshToken=${getCookie(
				REFRESH_TOKEN_COOKIE_NAME
			)}&android=${isAndroid}`;

			localStorage.removeItem(GOOGLE_CALENDAR_CALLBACK_URL);

			const callbackScript = document.createElement("script");
			callbackScript.src = googleCalendarCallbackUrl;
			callbackScript.defer = true;
			//callbackScript.crossOrigin = "anonymous"; // Causes script to fail - for local testing

			callbackScript.onload = function () {
				window.close();

				if (useHelpers().isMobileBrowser()) {
					const mobileAppReferrer = localStorage.getItem("mobile-app-referrer");

					if (mobileAppReferrer?.includes("www.google.com")) {
						window.top.location = "https://calendar.google.com";
					} else if (mobileAppReferrer?.includes("apple.com")) {
						window.top.location = "calshow://";
					}

					localStorage.removeItem("mobile-app-referrer");
				}
			};

			callbackScript.addEventListener("error", (err: any) => {
				appInsights.trackException({
					exception: err,
					properties: useHelpers().getLoggingProperties("GoogleCallbackError", "GoogleCallbackError", {
						referrer: document.referrer || window.top?.frames?.document.referrer,
						error: JSON.stringify(err, ["message", "arguments", "type", "name"]),
					}),
					severityLevel: SeverityLevel.Warning,
				});

				window.location.href = googleCalendarCallbackUrl;
			});

			try {
				document.head.appendChild(callbackScript);
			} catch (err: any) {
				appInsights.trackException({
					exception: err,
					properties: useHelpers().getLoggingProperties("GoogleCallbackError", "GoogleCallbackError", {
						referrer: document.referrer || window.top?.frames?.document.referrer,
						error: JSON.stringify(err),
					}),
					severityLevel: SeverityLevel.Warning,
				});
			}
		} else if (
			referrer?.startsWith(window.location.origin) ||
			referrer?.startsWith(import.meta.env.VITE_USER_SITE_URL) ||
			referrer?.startsWith(import.meta.env.VITE_CLIENT_SITE_URL) ||
			referrer?.startsWith(import.meta.env.VITE_ADMIN_SITE_URL)
		) {
			location.href = referrer;
		} else {
			location.href = import.meta.env.VITE_USER_SITE_URL;
		}
	}

	function openResetPassword() {
		showResetPassword.value = true;
	}

	function closeResetPassword() {
		showResetPassword.value = false;
		resetPasswordComplete.value = false;
		resetPasswordEmail.value = "";
	}

	async function resetPassword() {
		useEventBus().emitEvent("loading", "Processing...");
		const response = await authenticationService.resetPasswordAsync(resetPasswordEmail.value);

		if (response) {
			if (!response.Success) {
				resetPasswordMessage.value = "Error occurred while processing the request to the server.";
			} else {
				resetPasswordMessage.value = response.Message?.Reason;
			}
		}

		resetPasswordComplete.value = true;
		useEventBus().emitEvent("loading-complete");
	}
</script>

<style scoped>
	body {
		background-color: #fff !important;
	}

	#reset-password-modal .modal-dialog {
		width: 25%;
		min-width: 250px;
		color: black;
	}

	label {
		flex-grow: 0;
		font-family: "Inter_Regular";
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #4e5d78;
		margin-bottom: 4px;
	}

	input {
		width: 100%;
		height: 45px;
		margin-bottom: 16px;
		flex-grow: 0;
		padding: 12px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px #f8f8f8;
		background-color: #ffffff;
		flex-grow: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #323b4b;
		opacity: 1;
		font-family: "Inter_Regular";
	}

	.ls-btn {
		width: 100%;
		height: 58px;
		margin-top: 16px;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-family: "Inter_Regular";
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
		background-color: #346ee0;
	}

	.ls-btn-link {
		width: auto;
		font-family: "Inter_Medium";
		font-size: 16px;
		color: #8a94a6;
		padding-top: 0px;
	}

	.btn-google {
		background-color: #4285f4;
		color: #ffffff;
	}

	.btn-facebook {
		background-color: rgba(23, 104, 225, 0.85);
		color: white;
	}

	.btn-linked-in {
		background-color: rgba(8, 101, 160, 0.85);
		color: white;
	}

	.or-separator {
		font-family: "Inter_Regular";
		font-size: 16px;
		font-weight: 600;
		color: #8a94a6;
		margin-top: 10px;
	}

	.ls-captcha {
		display: flex;
		margin-top: 16px;
		padding: 0.375rem 0.75rem;
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
