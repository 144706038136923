<template>
	<transition name="fade">
		<div
			class="custom-modal modal show fade"
			tabindex="-1"
			aria-hidden="true"
			v-show="show"
			@click="componentClick">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Report an Issue</h5>
						<i class="fal fa-times close" @click="close"></i>
					</div>
					<div class="modal-body">
						<!-- <div class="d-flex justify-content-end mb-2">
							<i class="fal fa-times close" @click="close"></i>
						</div> -->

						<div class="flex-column">
							<div class="form-group">
								<label class="form-label" for="name">Name</label>
								<input
									type="text"
									name="name"
									placeholder="John Smith"
									maxlength="50"
									class="form-control"
									v-model="issueDisplayName" />
							</div>
							<div class="form-group">
								<label class="form-label" for="email">Email</label>
								<input
									type="email"
									name="email"
									placeholder="john@smith.com"
									maxlength="50"
									class="form-control"
									v-model="issueEmail" />
							</div>
							<div class="form-group">
								<label class="form-label" for="email">Issue Category</label>
								<select
									class="form-select text-white chat-select"
									aria-label="Default select example"
									v-model="issueCategory"
									v-on:mousedown="issueCategorySelected">
									<option value="" :key="'default'" selected="true">Select a Category</option>
									<option v-for="category in categories" :key="category" :value="category">
										{{ category }}
									</option>
								</select>
							</div>
							<div class="form-group">
								<label class="form-label" for="notes">Additional Info</label>
								<textarea
									name="notes"
									placeholder="My video was blurry"
									maxlength="250"
									class="form-control"
									v-model="issueNotes"></textarea>
							</div>
							<div class="form-group">
								<button class="btn-primary ls-btn" @click="submitAVIssue">
									Report Audio/Video Issue
								</button>
							</div>
							<div class="form-group">
								<button
									class="btn-primary ls-btn"
									v-bind:disabled="submitDisabled"
									@click="submit(true)">
									Submit
								</button>
							</div>
							<div class="form-group">
								<button class="btn-light ls-btn" @click="show = false">Cancel</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
	import type { AuthenticationValidationResponse } from "@/classes/AuthenticationService";
	import type { IChannelService, IssueReport } from "@/classes/ChannelService";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import { channelServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useEventBus from "@/composables/useEventBus";
	import useHelpers from "@/composables/useHelpers";
	import useLocalStorage from "@/composables/useLocalStorage";
	import { Log, version } from "@liveswitch/sdk";
	import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
	import { computed } from "@vue/reactivity";
	import Swal from "sweetalert2";
	import { onMounted, ref, inject, type PropType } from "vue";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const channelService = inject(channelServiceKey) as IChannelService;
	const categories = ref<string[]>([
		"Joining a meeting",
		"Poor audio quality",
		"Blurry video",
		"Unable to screen share",
		"Unable to view screen share",
		"Disconnected from meeting",
		"Other",
	]);
	const issueDisplayName = ref("");
	const issueEmail = ref("");
	const issueCategory = ref("");
	const issueNotes = ref("");
	const show = ref(false);
	const meetingData = ref<any>();

	const submitDisabled = computed(() => {
		return issueDisplayName.value == "" || issueEmail.value == "" || issueCategory.value == "";
	});

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	onMounted(() => {
		useEventBus().onEvent("toggle-report-issue", () => {
			show.value = true;
			meetingData.value = props.meetingHandler.meetingDebugInfo;
		});

		useEventBus().onEvent("full-auth-validated", (response: AuthenticationValidationResponse) => {
			issueEmail.value = response.Email;
		});

		if (useHelpers().isAuthenticated()) {
			issueDisplayName.value = useLocalStorage().getUsername();
		}
	});

	function close() {
		show.value = false;
		issueCategory.value = "";
		issueNotes.value = "";
	}

	async function submitAVIssue() {
		issueCategory.value = "Audio/Video Issue - Instant Report";
		issueCategory.value = props.meetingHandler.localAttendee.displayName;
		await submit(false);
	}

	function componentClick(event: MouseEvent) {
		if ((event.target as HTMLDivElement)?.classList.contains("fade")) {
			close();
		}
	}

	async function submit(validateEmail: boolean = true) {
		if (!useHelpers().isValidEmail(issueEmail.value) && validateEmail) {
			Swal.fire({
				title: "Error",
				text: "Please enter a valid email address",
				confirmButtonText: "Close",
			});

			return;
		}

		useEventBus().emitEvent("loading");
		const issue: IssueReport = {
			accountType: useHelpers().isAuthenticatedUser() ? "Authenticated" : "Anonymous",
			attendeeCount: props.meetingHandler.attendees.length,
			attendeeId: props.meetingHandler.localAttendeeId,
			sdkVersion: version,
			category: issueCategory.value,
			displayName: issueDisplayName.value,
			email: issueEmail.value,
			meetingId: props.meetingHandler.meetingId,
			notes: issueNotes.value,
			additionalData: meetingData.value,
			reportDate: new Date(),
			roomKey: useLocalStorage().getChannelKey(),
			userAccountId: useLocalStorage().getUserAccountId(),
		};

		appInsights.trackEvent({
			name: "IssueReport",
			properties: useHelpers().getLoggingProperties("IssueReport", issue.notes, issue),
		});

		const meetingDataJson = issue.additionalData;
		issue.additionalData = JSON.stringify(meetingData.value);
		await channelService.reportIssueAsync(issue);

		const logData = issue;
		issue.additionalData = meetingDataJson;
		logData.email = "";
		Log.warn(JSON.stringify(logData));

		useEventBus().navAlert("Your issue has been reported");
		useEventBus().emitEvent("loading-complete");
		close();
	}

	function issueCategorySelected() {}
</script>

<style lang="scss" scoped>
	.custom-modal {
		.modal-dialog {
			width: 30%;
		}
	}

	@media (max-width: 834px) {
		.custom-modal {
			.modal-dialog {
				width: 100%;
			}
			.modal-body {
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}

	.btn-light.ls-btn {
		background-color: #f3f3f3;
		color: #555;
	}

	.modal-title {
		font-family: "Inter_Regular";
		font-size: 24px;
		font-weight: bold;
	}

	/* Form Elements */

	label {
		flex-grow: 0;
		font-family: "Inter_Regular";
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #4e5d78;
		margin-bottom: 4px;
	}

	input {
		width: 100%;
		height: 45px;
		margin-bottom: 16px;
		flex-grow: 0;
		padding: 12px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px #f8f8f8;
		background-color: #ffffff;
		flex-grow: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #323b4b;
		opacity: 1;
		font-family: "Inter_Regular";
	}

	select {
		margin-bottom: 16px;
	}

	.ls-btn {
		width: 100%;
		height: 58px;
		margin-top: 16px;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-family: "Inter_Regular";
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
		background-color: #346ee0;
	}
</style>
