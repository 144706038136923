<template>
	<div class="auth-container">
		<div class="form-group">
			<div class="reset-password-instructions">Please choose a unique password of at least six (6) characters.</div>
			<label class="form-label" for="password">Password</label>
			<input type="password" id="password" class="form-control" v-model="password" />
			<label class="form-label" for="confirmPassword">Confirm Password</label>
			<input type="password" id="confirmPassword" class="form-control" v-model="confirmPassword" />
			<button
				@click="doResetPassword"
				class="ls-btn ls-btn-blue ls-btn-main">
				Reset Password
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {IAuthenticationService} from "@/classes/AuthenticationService";
import {inject, onMounted, ref} from "vue";
import useEventBus from "@/composables/useEventBus";
import Swal from "sweetalert2";
import {authenticationServiceKey, InjectionKeyAppInsights} from "@/composables/injectKeys";

const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
authenticationService.appInsights = appInsights;
const platformAccountId = ref("");
const verificationCode = ref("");
const password = ref("");
const confirmPassword = ref("");

onMounted(async () => {
	const params = new URLSearchParams(window.location.search.split("#")[0]);
	const platformAccountIdParam = params.get("platformAccountId");
	const verificationCodeParam = params.get("verificationCode");
	if(!platformAccountIdParam || !verificationCodeParam) {
		Swal.fire({
			title: "Error",
			html: "The link you have used to access this page is invalid. Please reset your password again to get a new link.",
			confirmButtonText: "Return to Sign In",
		}).then(() => {
			window.location.href = window.location.origin + "/login";
		});
	}
	platformAccountId.value = platformAccountIdParam;
	verificationCode.value = verificationCodeParam;
});

async function doResetPassword() {
	if(password.value.length < 6) {
		await Swal.fire({
			title: "Error",
			html: "Your password must be at least six (6) characters long.",
			confirmButtonText: "OK",
		});
		return;
	}
	
	if(password.value !== confirmPassword.value) {
		await Swal.fire({
			title: "Error",
			html: "Password confirmation does not match.",
			confirmButtonText: "OK",
		});
		return;
	}

	useEventBus().emitEvent("loading", "Processing...");
	const response = await authenticationService.doResetPasswordAsync(platformAccountId.value, verificationCode.value, password.value);
	if(response.Status) {
		Swal.fire({
			title: "Success",
			html: "Your password has been reset successfully.",
			icon: "success",
			confirmButtonText: "Return to Sign In",
		}).then(() => {
			window.location.href = window.location.origin + "/login";
		});
	} else {
		Swal.fire({
			title: "Error",
			html: response.Reason ?? "Error occurred while processing the request to the server.",
			confirmButtonText: "Return to Sign In",
		}).then(() => {
			window.location.href = window.location.origin + "/login";
		});
	}
	useEventBus().emitEvent("loading-complete");
}
</script>

<style scoped>
body {
	background-color: #fff !important;
}

label {
	flex-grow: 0;
	font-family: "Inter_Regular";
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #4e5d78;
	margin-bottom: 4px;
}

input {
	width: 100%;
	height: 45px;
	margin-bottom: 16px;
	flex-grow: 0;
	padding: 12px;
	border-radius: 4px;
	box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
	border: solid 1px #f8f8f8;
	background-color: #ffffff;
	flex-grow: 0;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: #323b4b;
	opacity: 1;
	font-family: "Inter_Regular";
}

.ls-btn {
	width: 100%;
	height: 55px;
	margin-top: 16px;
	border-radius: 10px;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-family: "Inter_Regular";
	font-weight: bold;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	background-color: #346ee0;
}

.reset-password-instructions {
	font-family: "Inter_Regular";
	font-size: 16px;
	color: #000000;
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>
