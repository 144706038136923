import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import useDefaultInjections from "./composables/appInjections";
import { createGtm } from '@gtm-support/vue-gtm';
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/app.scss";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import useHelpers from "./composables/useHelpers";
import { v4 as uuid } from "uuid";
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { InjectionKeyAppInsights } from "./composables/injectKeys";
import { configureLocalStorage } from "./composables/useLocalStorage";
import { Vue } from "@liveswitch/sdk";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

const options = {
    confirmButtonColor: 'rgb(140, 212, 245)',
    cancelButtonColor: 'rgb(140, 212, 245)',
};

Vue.reactive = reactive;

// this junk is for firefox + fingerprintjs
// fingerprint uses an out of date function call that's deprecated
// so we override that one specific call (only) to kill it
// and return blank, which is what would get returned in the case of no debug renderer info
// and the "correct" way to do this (see https://stackoverflow.com/questions/49267764/how-to-get-the-video-card-driver-name-using-javascript-browser-side/49267844#49267844)
// will continue to work as expected; it looks like fingerprintjs may have fixed
// this in their 4.x series, but that is under a new commercial license; in reality
// we should trash fingerprintjs
if(window.WebGLRenderingContext && window.WebGLRenderingContext.prototype && window.WebGLRenderingContext.prototype.getExtension as any){
    const _oldGetExtension = WebGLRenderingContext.prototype.getExtension
    
    WebGLRenderingContext.prototype.getExtension = function(name){
        if(name === 'WEBGL_debug_renderer_info'){
            return ''
        }
        return _oldGetExtension.apply(this, [name])
    }
}

const app = createApp(App);
app.use(VueTelInput, {
    preferredCountries: ["US", "AU", "CA", "GB"],
    placeholder: "Enter a phone number",
    mode: "international",
    showDialCodeInSelection: true,
    dropdownOptions: {
        showFlags: true,
        showDialCodeInSelection: true
    },
    defaultCountry: "US",
    disabledFormatting: false,
    wrapperClasses: "country-phone-input",
    validCharactersOnly: true
});

app.use(
    createGtm({
        id: 'GTM-5829D5G',
        nonce: uuid(), // Will add `nonce` to the script tag
        debug: useHelpers().isLocal(), // Whether or not display console logs debugs (optional)
        vueRouter: router // Pass the router instance to automatically sync with router (optional)
    }),
);

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_KEY
    }
});

appInsights.loadAppInsights();

useDefaultInjections(app, appInsights);
configureLocalStorage(appInsights);

window.onerror = (evt: any, url: any, lineNumber: any, columnNumber: any, ex: Error | undefined) => {
    if (!(ex as any).aiLogged) {
        appInsights.trackException({
            exception: ex,
            severityLevel: SeverityLevel.Error
        }, useHelpers().getLoggingProperties(ex?.name, ex?.message));
    }
};

app.config.errorHandler = (ex: any, vm, info) => {
    if (!ex.aiLogged) {
        appInsights.trackException({
            exception: ex,
            severityLevel: SeverityLevel.Error
        }, useHelpers().getLoggingProperties(ex.name, ex.message));
    }

    ex.aiLogged = true;
    console.error(ex);

    if (ex.throw) {
        ex.throw = false;
        throw ex;
    }
}

app.provide(InjectionKeyAppInsights, appInsights);
app.use(router);
app.use(VueSweetalert2, options);
app.mount("#app");


/*
    This lovely chunk of code is to force Safari on iOS to do sane things
    which includes:
      a) avoid pull to refresh, which causes accidentally leaving
      b) proper height calculations, including the navigation bar
*/
const doc = document.documentElement

let ua = window.navigator.userAgent;
let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
let webkit = !!ua.match(/WebKit/i);
let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
if (true) {
    var rootEl = document.getElementById('app')
    if(rootEl && iOSSafari){
        // ios bug fix on rotate
       // rootEl.style.height = '100%'; // instead of 100vh
    }
    var appHeight = () => {
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        
    }
    window.addEventListener('resize', appHeight)
    window.addEventListener('load', appHeight)

    appHeight()
}
  
// force overflow to hide - prevents scroll via pull on mobile
doc.style.overflow = 'hidden'