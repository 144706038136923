<template>
	<div
		:id="`video-container-${video?.id}`"
		ref="videoContainer"
		class="no-padding video video-size"
		:class="videoLayout"
		:style="videoStyle"
		:type="video?.type"
		@mouseenter="hovering = true"
		@mouseleave="hovering = false">
		<video
			ref="videoElement"
			v-show="showVideoTile"
			:srcObject.prop.camel="props.video.stream"
			:type="video?.type"
			:fit="'contain'"
			:muted="true"
			:class="mirrorStyle"
			:id="`video-${video?.id}`"
			:width="videoWidth"
			:height="videoHeight"

			@click="videoClick"
			autoplay
			playsinline
			disablePictureInPicture />
		<div
			v-if="blurVideo && !isSipUser"
			class="blur-video alt-video-container"
			:class="video.isRemote ? 'remote' : ''">
			<div
				class="video-paused-background"
				:class="{
					'video-paused-medium': props.videoCount > 16,
					'video-paused-small': props.videoCount > 36,
				}">
				<div class="video-paused">
					<i class="icon-wifi"></i>
				</div>
				<div class="message-container justify-center">
					<div class="message longtip col-auto">
						{{
							video.isRemote
								? "This user's media has been paused due to poor network conditions."
								: "Your media is paused for others due to poor network conditions."
						}}
					</div>
				</div>
			</div>
		</div>
		<div v-if="showAltVideoContainer" class="alt-video-container" :class="speakingStyle">
			<div
				class="initial"
				:class="{
					'initial-medium': props.videoCount > 16,
					'initial-small': props.videoCount > 36
				}">
				<div v-show="!isSipUser && !props.video?.isDisplay">
					{{ displayInitial }}
				</div>

				<div v-show="props.video?.isDisplay">
					<i class="fas fa-display"></i>
				</div>
				<div v-show="isSipUser && !isAudioMuted">
					<i class="fas fa-phone-flip"></i>
				</div>
				<div v-show="isSipUser && isAudioMuted">
					<i class="fas fa-phone-slash audio-only-muted"></i>
				</div>
				<div v-show="video.videoTrack?.isDisabled" class="video-disabled">
					<img src="../assets/img/wifi_off.svg" alt="Video disable due to poor network conditions" />
					<div class="tooltip-container row justify-center">
						<div class="tooltip longtip col-auto">
							This video feed has been turned off due to poor network connectivity. It will be turned back
							on when network conditions improve.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="stats-overlay" v-if="showOverlay && stats" v-show="showOverlay && hovering">
			<div v-if="stats.hasAudio">
				<span class="stats-audio-bitrate">Audio Bitrate: {{ stats.audioBitrate }}</span>
			</div>
			<div v-if="stats.hasAudio">
				<span class="stats-audio-level">Audio Level: {{ stats.audioLevel }}</span>
				<span v-if="video.audioTrack?.isSpeaking"> (speaking)</span>
			</div>
			<div v-if="stats.hasAudio">
				<span class="stats-audio-muted">Audio Muted: {{ stats.audioMuted }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-bitrate">Video Bitrate: {{ stats.videoBitrate }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-frame-rate">Video Frame Rate: {{ stats.videoFrameRate }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-facing-mode">Video Facing Mode: {{ stats.videoFacingMode }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-frame-size">Video Frame Size: {{ stats.videoFrameSize }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-bitrate">Video Priority: {{ stats.videoPriority }}</span>
			</div>
			<div v-if="stats.hasVideo">
				<span class="stats-video-muted">Video Muted: {{ stats.videoMuted }}</span>
			</div>
		</div>

		<div
			class="muted-name-tag hidden"
			v-bind:class="{ 'name-tag-audio-only': video.videoTrack == null }"
			v-if="video.videoTrack?.isMuted || video.videoTrack?.isDisabled">
			<div v-show="video.videoTrack != null">
				{{ getUserNameTag() }}
			</div>
			<div v-show="video.videoTrack == null">
				<i v-show="!video.audioTrack?.isMuted" class="fas fa-phone"></i>
				<i v-show="video.audioTrack?.isMuted" class="fas fa-phone-slash name-tag-audio-only-muted"></i>
			</div>
		</div>
		<div class="host-icon" v-if="hovering && meetingHandler.admin && !self && !screenShare">
			<div class="host-icon-bg large-screen" style="width: 100%; height: 100%; position: relative">
				<div @click="toggleParticipantAudio" :class="hostAudioStyle">
					<i
						class="host-mute host-mute-audio fa"
						:class="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'"
						style="position: relative"></i>
				</div>
				<div v-if="!isSipUser" @click="toggleParticipantVideo" :class="hostVideoStyle">
					<i
						class="host-mute host-mute-video fa"
						:class="isVideoMuted ? 'fa-video-slash' : 'fa-video'"
						style="position: relative"></i>
				</div>
			</div>
		</div>
		<div
			class="icon-container"
			:class="{
				'icon-container-hidden': hideUserInfo,
				'icon-container-screen': screenShare,
				'icon-container-screen-local': screenShare && meetingHandler.localScreenSharing,
				'justify-content-end': screenShare && mobile && fullScreen,
			}"
			v-bind:id="video.id"
			:key="video.id"
			v-show="hovering || raisedHand || videoType != 'thumbnail'">
			<div
				class="user-name-label"
				:class="{ 'raise-hand': raisedHand, 'd-none': mobile && fullScreen }"
				:style="userLabelStyles">
				<span class="user-name-span">
					{{ displayName }}
				</span>
				<span v-show="raisedHand">
					<i class="fas fa-hand-paper fa-spin raised-hand-icon"></i>
				</span>
				<div class="position-relative audio-toggle-container">
					<!-- <div v-if="!isAudioMuted" class="position-absolute audio-speaking">
						<i class="fa-2 video-mic-icon video-icon-label fa fa-microphone"></i>
					</div> -->
					<div v-if="!isAudioMuted" class="position-absolute audio-background">
						<i class="fa-2 video-mic-icon video-icon-label fa fa-microphone"></i>
					</div>
					<div v-if="isAudioMuted" class="position-absolute">
						<i class="fa-2 video-mic-icon video-icon-label fa fa-microphone-slash"></i>
					</div>
				</div>
			</div>
			<span class="d-flex" v-if="screenShare && meetingHandler.localScreenSharing"> Sharing</span>
			<!-- Pinning is temporarily disabled -->
			<div class="video-thumbnail-icons-right">
				<div class="pin-container" @click.stop="pinUser" v-if="false">
					<i
						class="fas fa-thumbtack pin-view-icon icon-cursor rotate-90"
						data-toggle="tooltip"
						title=""
						data-placement="top"
						data-original-title="Pin to front"></i>
				</div>
				<div class="pin-container active" @click.stop="pinUser" v-if="false">
					<i
						class="fas fa-thumbtack pin-view-icon icon-cursor"
						:class="pinStyle"
						data-toggle="tooltip"
						title=""
						data-placement="top"
						data-original-title="Pin to front"></i>
				</div>
				<div
					v-if="showEmojis"
					class="emoji-container"
					:class="{ 'emoji-container-open': emojisOpen }"
					:style="emojiStyles">
					<div
						class="emoji first toggle"
						:class="{ 'toggle-closed': emojisOpen }"
						@click.stop="toggleEmoji('')">
						<!-- <IconEmojiInactive /> -->
						<img src="../assets/img/emoji_face.svg" alt="Emojis" />
						<div class="tooltip-container row justify-center">
							<div class="tooltip col-auto">Open emojis</div>
						</div>
					</div>
					<div class="emoji" @click.stop="toggleEmoji('❤️')" title="Heart">❤️</div>
					<div class="emoji" @click.stop="toggleEmoji('👍')" title="Thumbs Up">👍</div>
					<div class="emoji" @click.stop="toggleEmoji('👏')" title="Clap">👏</div>
					<div class="emoji" @click.stop="toggleEmoji('🙌')" title="Raising Hands">🙌</div>
					<div class="emoji" @click.stop="toggleEmoji('🤣')" title="Rolling on the Floor Laughing">🤣</div>
					<div class="emoji" @click.stop="toggleEmoji('🔥')" title="Fire">🔥</div>
					<div class="emoji">
						<i class="fal fa-times" @click.stop="closeEmojiContainer('')"></i>
					</div>
				</div>
				<div class="tooltip-haver" @click.stop="openMyWorld" v-if="!useHelpers().isMobileBrowser()">
					<button
						type="button"
						class="btn btn-my-world-video-icon d-flex justify-content-center align-items-center"
						data-toggle="tooltip"
						data-placement="top"
						v-if="!screenShare && !isSipUser">
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="#fff"
							style="pointer-events: none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15.0052 9.13821e-07C12.0381 -0.00103457 9.13725 0.877952 6.66972 2.52577C4.20218 4.1736 2.27878 6.51622 1.14282 9.25733C0.00685227 11.9984 -0.290639 15.0149 0.287972 17.9251C0.866583 20.8353 2.2953 23.5085 4.3934 25.6066C6.49151 27.7047 9.16473 29.1334 12.0749 29.712C14.9851 30.2906 18.0016 29.9931 20.7427 28.8572C23.4838 27.7212 25.8264 25.7978 27.4742 23.3303C29.1221 20.8627 30.001 17.9619 30 14.9948C29.9958 11.0192 28.4147 7.20762 25.6035 4.39646C22.7924 1.58529 18.9808 0.00415875 15.0052 9.13821e-07ZM2.02095 15.0052C2.02189 14.0363 2.13076 13.0705 2.34556 12.1257C2.41885 12.1675 12.5812 17.1099 13.3717 27.8848C10.2364 27.4872 7.35367 25.9597 5.26404 23.5886C3.17441 21.2175 2.02133 18.1657 2.02095 15.0052ZM23.9319 24.4293V23.5288C23.9319 22.7748 23.6324 22.0517 23.0993 21.5185C22.5661 20.9854 21.843 20.6859 21.089 20.6859C20.7669 20.6859 20.4579 20.5579 20.2301 20.3301C20.0023 20.1023 19.8743 19.7933 19.8743 19.4712V16.6283C19.8743 16.4151 19.8324 16.2041 19.7508 16.0072C19.6692 15.8102 19.5497 15.6313 19.399 15.4806C19.2483 15.3299 19.0693 15.2103 18.8724 15.1288C18.6755 15.0472 18.4645 15.0052 18.2513 15.0052H11.7592C11.0752 15.0057 10.414 14.7591 9.89745 14.3108C9.38085 13.8625 9.04356 13.2427 8.94765 12.5654H11.7592C11.9723 12.5654 12.1834 12.5235 12.3803 12.4419C12.5772 12.3603 12.7561 12.2408 12.9068 12.0901C13.0575 11.9394 13.1771 11.7604 13.2587 11.5635C13.3402 11.3666 13.3822 11.1555 13.3822 10.9424V9.79058C13.3822 9.23515 13.6028 8.70247 13.9956 8.30972C14.3883 7.91698 14.921 7.69633 15.4764 7.69633H17.0471C17.8002 7.69634 18.5225 7.39753 19.0555 6.86551C19.5885 6.33349 19.8887 5.61172 19.8901 4.85864V2.96859C21.9069 3.78292 23.6867 5.09102 25.066 6.77273C26.4453 8.45443 27.3799 10.4558 27.7838 12.5929C28.1878 14.7301 28.0482 16.9345 27.3779 19.0036C26.7076 21.0728 25.5281 22.9403 23.9476 24.4345L23.9319 24.4293Z" />
						</svg>
					</button>
					<div class="tooltip-container row justify-center" style="right: 20px; z-index: 101">
						<div class="tooltip col-auto">My World</div>
					</div>
				</div>
				<button
					type="button"
					class="btn btn-full-screen"
					:class="{ 'btn-full-screen-mb': fullScreen }"
					v-if="
						screenShare &&
						!fullScreen &&
						!meetingHandler.localScreenSharing &&
						!useHelpers().isSafari() &&
						!useHelpers().isMobileBrowser()
					"
					@click.stop="toggleFullScreen">
					<i class="fa-solid fa-expand"></i>
				</button>
				<button
					type="button"
					class="btn btn-full-screen"
					:class="{ 'btn-full-screen-mb': fullScreen }"
					v-if="
						screenShare &&
						fullScreen &&
						!meetingHandler.localScreenSharing &&
						!useHelpers().isSafari() &&
						!useHelpers().isMobileBrowser()
					"
					@click.stop="toggleFullScreen">
					<i class="fa-solid fa-compress"></i>
				</button>
			</div>
		</div>
		<VideoControls
			v-if="fullScreenVideoControlsEnabled"
			:meetingHandler="meetingHandler"
			:meetingSubject="'test'"
			:fullScreen="true" />
	</div>
</template>

<script lang="ts" setup>
	import { type PropType, ref, onMounted, computed, getCurrentInstance, watch, onUnmounted, reactive } from "vue";
	import { Attendee, type Media, UserMedia, LocalMedia, Vue } from "@liveswitch/sdk";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "@/composables/useEventBus";
	import useHelpers from "@/composables/useHelpers";
	import type { OverlayStat, VideoType } from "@/classes/EventContracts";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
	import { inject } from "vue";
	import { InjectionKeyAppInsights, channelServiceKey } from "@/composables/injectKeys";
	import VideoControls from "./VideoControls.vue";
	import { videoStatsEnabled } from "@/composables/useLocalStorage";
	import { sendMessageAsync } from "@/composables/useChat";
	import type EmojiReaction from "@/classes/EmojiReaction";
	import { isEmojiSupported } from "is-emoji-supported";
	import InlineLoader from "./InlineLoader.vue";
	import type { IChannelService } from "@/classes/ChannelService";
	import { blurEnabled } from "@/composables/useLocalStorage";

	const channelService = inject(channelServiceKey) as IChannelService;
	const pinned = ref(false);
	const hovering = ref(false);
	const handRaised = ref(false);
	const showOverlay = ref(import.meta.env.VITE_VIDEO_STATS_ENABLED == "true");
	const paused = ref(false);
	const fullScreen = ref(false);
	const instance = getCurrentInstance();
	const videoElement = ref<HTMLVideoElement>();
	const videoContainer = ref<HTMLDivElement>();
	const localVideo = ref();
	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const orientation = ref(screen.orientation || useHelpers().getScreenOrientation());
	const maxMobileVideos = ref(4);
	const screenHeight = ref(document.body.offsetHeight);
	const isLocalScreenShare = false;
	const emojisOpen = ref(false);
	const emojiContainerWidth = ref(168);
	const emojisSupported = ref(true);
	const emojiReactions = ["❤️", "👍", "👏", "🙌", "🤣", "🔥"];

	const emojiFontSize = ref(16);
	const emojiSize = ref(24);
	const smallEmojis = ref(false);
	const closeTimeout = ref(0);
	const lastEmojiTime = ref(0);
	const audioMedia = ref<Media>();
	const attendee = ref<Attendee | null>();

	const props = defineProps({
		video: {
			type: Object as PropType<Media>,
			required: true,
		},
		videoCount: {
			type: Number,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		videoType: {
			type: String as PropType<VideoType>,
		},
		floating: {
			type: Boolean,
		},
		localMediaBlurred: {
			type: Boolean,
		},
		testVideo: {
			type: Boolean,
		},
	});

	const videoFrameSize = computed(() => props.meetingHandler.localUserMedia?.videoTrack?.frameSize);
	const stats = computed(() => {
		let stat: OverlayStat;
		if (props.video.isLocal && props.video.isUser) {
			stat = {
				hasAudio: props.meetingHandler.localUserMedia?.audioTrack != null,
				hasVideo: props.meetingHandler.localUserMedia?.videoTrack != null,
				audioBitrate: `${props.meetingHandler.localUserMedia?.audioTrack?.bitrate?.toFixed(0)} Kbps`,
				audioLevel: props.meetingHandler.localUserMedia?.audioTrack?.level?.toFixed(3),
				audioMuted: props.meetingHandler.localUserMedia?.audioTrack?.isMuted,
				videoBitrate: `${props.meetingHandler.localUserMedia?.videoTrack?.bitrate?.toFixed(0)} Kbps`,
				videoFacingMode: (props.video as UserMedia)?.videoFacingMode,
				videoFrameRate: `${props.meetingHandler.localUserMedia?.videoTrack?.frameRate?.toFixed(3)} FPS`,
				videoFrameSize: `${props.meetingHandler.localUserMedia?.videoTrack?.frameSize?.width}x${props.meetingHandler.localUserMedia?.videoTrack?.frameSize?.height}px`,
				videoMuted: props.meetingHandler.localUserMedia?.videoTrack?.isMuted,
				videoPriority: "",
			};
		} else {
			stat = {
				hasAudio: props.video?.audioTrack != null,
				hasVideo: props.video?.videoTrack != null,
				audioBitrate: `${props.video?.audioTrack?.bitrate?.toFixed(0)} Kbps`,
				audioLevel: props.video?.audioTrack?.level?.toFixed(3),
				audioMuted: props.video?.audioTrack?.isMuted,
				videoBitrate: `${props.video?.videoTrack?.bitrate?.toFixed(0)} Kbps`,
				videoFacingMode: "",
				videoFrameRate: `${props.video?.videoTrack?.frameRate?.toFixed(3)} FPS`,
				videoFrameSize: `${props.video?.videoTrack?.frameSize?.width}x${props.video?.videoTrack?.frameSize?.height}px`,
				videoMuted: props.video?.videoTrack?.isMuted,
				videoPriority: "unknown",
				//videoPriority: props.video?.videoTrack?.prio.priority,
			};
		}
		return stat;
	});

	const showVideoTile = computed(() => {
		const show =
			(props.video?.isUser &&
				props.video?.stream &&
				props.video?.videoTrack &&
				!props.video?.videoTrack?.isDisabled &&
				!safariPermissionsDisabled.value &&
				!attendee.value?.isVideoMuted &&
				!isSipUser.value) ||
			(props.video?.isDisplay && props.video?.stream && !props.video?.videoTrack?.isDisabled);
		return show;
	});

	const showAltVideoContainer = computed(() => {
		const show =
			(props.video?.isUser &&
				(!props.video.stream ||
					!props.video.videoTrack ||
					props.video.videoTrack?.isDisabled ||
					attendee.value?.isVideoMuted ||
					isSipUser.value ||
					safariPermissionsDisabled.value)) ||
			(props.video?.isDisplay &&
				(!props.video.stream ||
					props.video.videoTrack?.isDisabled ||
					isSipUser.value ||
					safariPermissionsDisabled.value));

		return show;
	});

	const blurVideo = computed(() => {
		const video = props.video;

		if (video.isUser && video.attendee) {
			return props.meetingHandler.UserActionHandler.blurVideo(video.attendee.id);
		}

		return false;
	});

	const mobile = computed(() => {
		return useHelpers().isMobileBrowser();
	});

	const videoWidth = computed(() => {
		const stream = props.video.stream;
		return props.video?.videoTrack?.frameSize?.width;
	});

	const videoHeight = computed(() => {
		const stream = props.video.stream;
		return props.video?.videoTrack?.frameSize?.height;
	});

	const userLabelStyles = computed(() => {
		let hidden = false;
		let emojiWidth = emojiSize.value * 7;

		if (!videoContainer.value) {
			return;
		}

		if (
			emojisOpen.value &&
			videoContainer.value?.clientWidth - emojiContainerWidth.value < videoContainer.value?.clientWidth * 0.42
		) {
			hidden = true;
		}

		return {
			"--opacity": hidden ? "0" : "1",
			"--width": hidden ? "0px" : "auto",
			"--display":
				hidden &&
				(Math.ceil(Math.sqrt(props.videoCount)) > 6 || emojiWidth > videoContainer.value?.clientWidth * 0.8)
					? "none"
					: "flex",
		};
	});

	const emojiStyles = computed(() => {
		let containerWidth = "";
		let width = emojiSize.value * 7;
		if (!videoContainer.value) {
			return;
		}

		if (Math.ceil(Math.sqrt(props.videoCount)) > 6 || width > videoContainer.value?.clientWidth * 0.8) {
			containerWidth = "100%";
		} else {
			containerWidth = `${emojiSize.value * 7}px`;
		}

		return {
			"--emojiFontSize": `${emojiFontSize.value}px`,
			"--emojiContainerWidth": containerWidth,
			"--emojiIconSize": `${emojiSize.value}px`,
		};
	});

	const videoContainerObserver = new ResizeObserver((entries) => {
		entries.forEach((entry) => {
			emojiContainerWidth.value += 1;
			emojiContainerWidth.value -= 1;
		});
	});

	onUnmounted(() => {
		window.clearInterval(syncInterval);

		if (videoContainer.value) {
			videoContainerObserver.unobserve(videoContainer.value);
		}

		if (isLocalScreenShare) {
			props.meetingHandler.localScreenShareVisible = false;
		}

		if (props.video.type === "display" && props.video.isRemote) {
			watch(
				() => props.meetingHandler.remoteScreenSharing,
				(isSharing, wasSharing) => {
					if (!isSharing && wasSharing && fullScreen.value) {
						try {
							document.exitFullscreen();
							console.debug("User stopped sharing. Exited full screen mode");
						} catch (err) {
							console.log("Error exiting full screen mode", err);
						}
					}
				}
			);
		}
	});

	let syncInterval = 0;

	onMounted(() => {

		// TODO: (JV) Remove when blur is confirmed working
		// if (props.video) {
		// 	videoMedia.value = props.video;
		// 	if (props.video.isUser && props.video.isRemote) {
		// 		syncInterval = window.setInterval(function () {
		// 			// i hate that this is necessarry; but the video track is not always in sync with the attendee's muted state
		// 			// so we need to keep it in sync. it *seems* like this is only a problem when using canvas, which
		// 			// doesn't make much sense given this is on the render side, but we're fixing it this way anyway
		// 			// if in the future we pull in Mike K's improved canvas renderer, we can remove this
		// 			try {
		// 				if (props.video.stream.getVideoTracks().length > 0) {
		// 					//console.log('syncing...')
		// 					//console.log('syncing muted state from attendee to video track', props.video.attendee?.isVideoMuted, props.video.stream.getVideoTracks()[0].enabled, props.video.stream.getVideoTracks()[0].muted)
		// 					props.video.stream.getVideoTracks()[0].enabled = !props.video.attendee?.isVideoMuted;
		// 				}
		// 			} catch (e) {
		// 				console.log(e);
		// 			}
		// 		}, 1000);
		// 	}
		// }

		if (props.video?.isLocal) {
			attendee.value = props.meetingHandler.localAttendee;
			if (videoElement.value) {
				console.debug("Video element bound");
			}

			watch(
				() => attendee.value?.isVideoMuted,
				async (newValue, oldValue) => {
					if (localVideo.value) {
						await localVideo.value.setVideoMuted(newValue);
					}
				}
			);
		} else {
			if (!props.video.attendee) {
				console.log("no attendee for video!", props.video);
				let int = setInterval(() => {
					if (props.video.attendee) {
						attendee.value = props.video.attendee;
						clearInterval(int);
					} else {
						console.log("still no attendee for video", props.video);
					}
				}, 100);
			} else {
				attendee.value = props.video.attendee;
			}
		}
		console.debug(`User: ${props.video.isUser}, Remote: ${props.video.isRemote}`);
		audioMedia.value = props.meetingHandler.audibleMedias.find((x) => x.attendee?.id === attendee.value?.id);

		for (var i = 0; i < emojiReactions.length; i++) {
			const emoji = emojiReactions[i];

			if (!isEmojiSupported(emoji)) {
				console.warn(`Emoji: ${emoji} is not supported`);
				emojisSupported.value = false;
				break;
			}
		}

		if (props.videoCount > 25) {
			emojiSize.value = 20;
			emojiFontSize.value = 15;
			smallEmojis.value = true;
		}

		if (props.video.isDisplay && props.meetingHandler.localScreenSharing) {
			props.meetingHandler.localScreenShareVisible = true;
		}

		useEventBus().onEvent("toggle-video-blur", async () => {
			if (props.video.isUser && props.video.isLocal) {
				localVideo.value.toggleVideoBlur();
			}
		});

		useEventBus().onEvent("orientation-change", async (newOrientation: ScreenOrientation) => {
			orientation.value = newOrientation;
			maxMobileVideos.value = -1;
			maxMobileVideos.value = newOrientation.type.indexOf("landscape") != -1 ? 0 : 4;
			props.meetingHandler.updateAvatarUrl();
			//await instance?.proxy?.$forceUpdate();
		});

		useEventBus().onEvent("desktop-resize", async () => {
			// screenHeight.value = document.body.offsetHeight;
			//maxMobileVideos.value = newOrientation.type.indexOf("landscape") != -1 ? 0 : 4;
			//await instance?.proxy?.$forceUpdate();
		});

		useEventBus().onEvent("emoji-reaction", (model: EmojiReaction) => {
			processEmojiReaction(model);
		});

		if (videoStatsEnabled.value) {
			showOverlay.value = true;
		}

		useEventBus().onEvent("hand-raised", (evtAttendee: Attendee) => {
			if (attendee.value?.id == evtAttendee.id) {
				handRaised.value = true;
			}
		});

		useEventBus().onEvent("hand-lowered", (evtAttendee: Attendee) => {
			if (evtAttendee && attendee.value?.id == evtAttendee.id) {
				handRaised.value = false;
			}
		});

		useEventBus().onEvent("close-emoji-container", (openAttendeeId: string) => {
			closeEmojiContainer(openAttendeeId);
		});

			if (videoContainer.value) {
			videoContainerObserver.observe(videoContainer.value);

			videoContainer.value.onresize = () => {
				console.log(`video container width: ${videoContainer.value?.clientWidth}`);
			};

			watch(
				() => videoContainer.value?.clientWidth,
				(newWidth, oldWidth) => {
					console.log(`video container width: ${newWidth}`);
					emojiContainerWidth.value += 1;
					emojiContainerWidth.value -= 1;
				}
			);

			videoContainer.value.onfullscreenchange = (evt: Event) => {
				console.debug(`Full Screen Change: Event=${evt}`);

				if (document.fullscreenElement) {
					if (document.fullscreenElement.id == videoContainer.value?.id) {
						fullScreen.value = true;
						return;
					}
				}

				fullScreen.value = false;
			};
		}

		if (videoElement.value) {
			if (props.video.isLocal) {
				videoElement.value.muted = true;
			}

			/*
			 * This may have been a side effect of changes for video being paused in other scenarios.
			 * Per article: https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari#3030251
			 * Autoplay will not work when the media contains an audio track so we will leave autoplay in place and as a
			 * fallback, hook into the `onloadedmetadata` event and play the video if it is not already playing
			 */

			videoElement.value.onfullscreenchange = (evt: Event) => {
				console.log(evt);
			};

			videoElement.value.onloadedmetadata = () => {
				playVideo();
			};
		}
	});

	function randomInteger(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	async function toggleEmoji(emoji: string) {
		if (!emojisOpen.value) {
			emojisOpen.value = true;
			useEventBus().emitEvent("close-emoji-container", attendee.value?.id);
			appInsights.trackMetric(
				{
					name: "ToggleEmojiReactions",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);

			setCloseTimeout();
		} else {
			const now = new Date().getTime();
			const diff = now - lastEmojiTime.value;

			if (diff < 300) {
				return;
			}

			lastEmojiTime.value = now;

			const model = {
				command: "reaction",
				reaction: emoji,
				targetAttendeeId: attendee.value?.id,
				videoType: props.video.type,
				senderAttendeeId: props.meetingHandler.localAttendee.id,
			} as EmojiReaction;

			const message = `so_cmd${JSON.stringify(model)}`;
			sendMessageAsync(message, undefined, "default");
			appInsights.trackMetric(
				{
					name: "EmojiReactionSent",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);

			clearCloseTimeout();
			setCloseTimeout();
		}
	}

	function setCloseTimeout() {
		closeTimeout.value = window.setTimeout(() => {
			emojisOpen.value = false;
		}, 8000);
	}

	function clearCloseTimeout() {
		if (closeTimeout.value > 0) {
			clearTimeout(closeTimeout.value);
			closeTimeout.value = 0;
		}
	}

	function closeEmojiContainer(openAttendeeId: string) {
		if (!openAttendeeId) {
			clearCloseTimeout();
			emojisOpen.value = false;
		} else if (attendee.value?.id !== openAttendeeId) {
			emojisOpen.value = false;
			clearCloseTimeout();
		}
	}

	function processEmojiReaction(model: EmojiReaction) {
		const emoji = model.reaction;

		if (!isEmojiSupported(emoji)) {
			return;
		}

		if (!videoContainer.value) {
			return;
		}

		if (model.targetAttendeeId === attendee.value?.id && model.videoType === props.video.type) {
			const emojiContainer = document.createElement("div");
			emojiContainer.innerText = emoji;

			const random = randomInteger(90, 100) / 100;
			let start = videoContainer.value?.clientWidth * random;

			const duration = randomInteger(30, 60) / 10;
			let animation = "";
			let fontSize = "";
			let widthBuffer = 0;
			const sender = props.meetingHandler.attendees?.find((a) => a.id == model.senderAttendeeId)?.displayName;
			const padding = 5;
			const fontWidth = 8;
			const maxWidth =
				sender != undefined ? Math.min(sender.replace(/\s/g, "").length * fontWidth + padding, 100) : 0;

			if (props.videoCount >= 25) {
				animation = `floating-emoji-extra-small-tile ${duration}s ease-in-out`;
				fontSize = "16px";
				widthBuffer = 24 + maxWidth;
			} else if (props.videoCount >= 12 && props.videoCount < 25) {
				animation = `floating-emoji-small-tile ${duration}s ease-in-out`;
				fontSize = "18px";
				widthBuffer = 26 + maxWidth;
			} else if (props.videoCount > 6 && props.videoCount < 12) {
				animation = `floating-emoji-medium-tile ${duration}s ease-in-out`;
				fontSize = "20px";
				widthBuffer = 28 + maxWidth;
			} else {
				animation = `floating-emoji-large-tile ${duration}s ease-in-out`;
				fontSize = "22px";
				widthBuffer = 32 + maxWidth;
			}

			if (start + widthBuffer >= videoContainer.value?.clientWidth) {
				start -= widthBuffer;
			}

			emojiContainer.style.position = "absolute";
			emojiContainer.style.bottom = "-28px";
			emojiContainer.style.left = `${start}px`;
			emojiContainer.style.animation = animation;
			emojiContainer.style.fontSize = fontSize;
			emojiContainer.style.zIndex = "100";
			emojiContainer.style.display = "flex";
			emojiContainer.style.flexDirection = "column";
			emojiContainer.style.alignItems = "center";
			emojiContainer.style.justifyItems = "center";
			emojiContainer.style.width = maxWidth + "px";
			emojiContainer.style.overflow = "visible";
			emojiContainer.style.zIndex = "1";
			emojiContainer.onanimationend = () => {
				videoContainer.value?.removeChild(emojiContainer);
			};

			if (sender) {
				const nameContainer = document.createElement("div");
				nameContainer.style.fontSize = "12px";
				nameContainer.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
				nameContainer.style.padding = "2px 5px";
				nameContainer.style.borderRadius = "15px";
				nameContainer.style.whiteSpace = "nowrap";
				nameContainer.style.overflow = "hidden";
				nameContainer.style.textOverflow = "ellipsis";
				nameContainer.style.width = maxWidth + "px";
				nameContainer.style.display = "flex";
				nameContainer.style.justifyContent = "center";
				nameContainer.innerText = sender;
				emojiContainer.append(nameContainer);
			}

			videoContainer.value?.append(emojiContainer);
		}
	}

	function playVideo() {
		if (videoElement.value) {
			const videoType = props.video.isRemote ? "remote" : "local";

			try {
				console.debug(
					`Video Play Attempt: Type=${videoType}, MediaType=${props.video.type}, AttendeeId=${attendee.value?.id}`
				);

				videoElement.value.play();
				console.debug(
					`Video Play Attempt: Status=Success, Type=${videoType}, MediaType=${props.video.type}, AttendeeId=${attendee.value?.id}`
				);
			} catch (err: any) {
				const message = `Video Play Attempt: Status=Failed, Type=${videoType}, MediaType=${props.video.type}, AttendeeId=${attendee.value?.id}`;

				console.error(message, err);
				appInsights.trackException(
					{
						exception: err,
						id: "VideoPlayError",
						severityLevel: SeverityLevel.Critical,
					},
					useHelpers().getLoggingProperties("VideoPlayError", message)
				);
			}
		}
	}

	const safariPermissionsDisabled = computed(() => {
		return useHelpers().isSafari() && !props.meetingHandler.permissionsGranted;
	});

	const backFacing = computed(() => {
		const backFacing = props.meetingHandler.facingMode == "environment";
		return backFacing;
	});

	const mirrorStyle = computed(() => {
		return {
			mirror:
				props.video?.isUser &&
				!props.video?.isRemote &&
				(props.video as UserMedia).videoFacingMode !== "environment",
			speaking: props.video?.audioTrack?.isSpeaking,
			blurred: false, // hard-coded
		};
	});

	const speakingStyle = computed(() => {
		return {
			speaking: props.video?.audioTrack?.isSpeaking,
		};
	});

	const isAudioDisabled = computed(() => {
		const disabled =
			attendee.value != null
				? props.meetingHandler.UserActionHandler.isUserAudioDisabled(attendee.value.id)
				: false;
		return disabled;
	});

	const isAudioMuted = computed(() => {
		const muted =
			attendee.value != null ? props.meetingHandler.UserActionHandler.isUserAudioMuted(attendee.value.id) : false;
		return muted;
	});

	const isVideoMuted = computed(() => {
		const muted =
			attendee.value != null ? props.meetingHandler.UserActionHandler.isUserVideoMuted(attendee.value.id) : false;
		return muted;
	});

	const isVideoDisabled = computed(() => {
		const disabled =
			attendee.value != null
				? props.meetingHandler.UserActionHandler.isUserVideoDisabled(attendee.value.id)
				: false;
		return disabled;
	});

	const isSipUser = computed(() => {
		const id = props.video?.attendee?.id;
		if (id) {
			const sipUser = props.meetingHandler.UserActionHandler.isSipUser(id);
			return sipUser;
		}
		return false;
	});

	const screenShare = computed(() => {
		const screenShare = props.video?.type == "display";
		return screenShare;
	});

	const raisedHand = computed(() => {
		const handRaised = props.meetingHandler.UserActionHandler.isHandRaised(attendee.value?.id);
		return handRaised;
	});

	const self = computed(() => {
		return attendee.value?.id == props.meetingHandler.localAttendeeId;
	});

	const displayName = computed(() => {
		let displayName = attendee.value?.displayName;

		if (!displayName) {
			displayName = props.meetingHandler.UserActionHandler.getDisplayName(attendee.value?.id);
		}

		return displayName;
	});

	const displayInitial = computed(() => {
		const name = displayName.value;
		const initial = name.charAt(0);
		return initial;
	});

	const hideUserInfo = computed(() => {
		const maxUsers = Number.parseInt(import.meta.env.VITE_HIDDEN_USER_INFO_MAX_USERS);
		let hidden = props.meetingHandler.attendees?.length >= maxUsers && !hovering.value;

		if (attendee.value && attendee.value instanceof Attendee && attendee.value?.isHandRaised()) {
			hidden = false;
		}

		return hidden;
	});

	const showEmojis = computed(() => {
		let show = true;

		if (
			attendee.value?.type === "SIPINBOUND" ||
			!emojisSupported ||
			useHelpers().isMobileBrowser() ||
			props.video.isLocal ||
			!props.meetingHandler.chatEnabled ||
			(props.meetingHandler.localScreenSharing && props.video.isDisplay)
		) {
			show = false;
		}

		const overrideLocalEmoji = import.meta.env.VITE_SHOW_LOCAL_EMOJI == "true";

		if (props.video.isLocal && !useHelpers().isMobileBrowser() && !props.video.isDisplay && overrideLocalEmoji) {
			show = true;
		}

		return show;
	});

	const fullScreenVideoControlsEnabled = computed(() => {
		const enabled =
			screenShare.value &&
			props.meetingHandler.remoteScreenSharing &&
			fullScreen.value &&
			document.fullscreenElement?.id == videoContainer.value?.id;
		return enabled;
	});

	const hostAudioStyle = computed(() => {
		return {
			disabled: isAudioDisabled.value,
			muted: isAudioMuted.value && !isAudioDisabled.value,
		};
	});

	const hostVideoStyle = computed(() => {
		return {
			disabled: isVideoDisabled.value,
			muted: isVideoMuted.value && !isVideoDisabled.value,
		};
	});

	const videoLayout = computed(() => {
		return {
			thumbnail: props.videoType == "thumbnail",
			floating: props.floating,
			"floating-middle": props.floating && props.videoCount - (1 % maxMobileVideos.value) > 2
		};
	});

	const videoStyle = computed(() => {
		let columns;
		let rows;
		let height;
		let width;
		const style: any = {};
		let count = props.videoCount;
		//let count = 6;
		if (mobile.value) {
			return;
		}

		if (props.videoType == "thumbnail") {
			const thumbnailCount = count;

			if (!props.meetingHandler.remoteScreenSharing) {
				//thumbnailCount = count - 9;
			}

			let thumbnailHeight = Math.min(100 / thumbnailCount, 100 / 3);

			if (thumbnailCount == 2) {
				thumbnailHeight = 50;
			}

			const maxThumbnailHeight = 100 / 5;
			height = `${Math.max(thumbnailHeight, maxThumbnailHeight)}%`;
			width = "100%";
			style.maxHeight = height;
			style.minHeight = "20%";
		} else {
			if (props.video?.type == "display" && props.meetingHandler.remoteScreenSharing) {
				columns = 1;
				rows = 1;
			} else {
				if (props.meetingHandler.remoteScreenSharing) {
					if (orientation.value.type.indexOf("landscape") != -1) {
						maxMobileVideos.value = 0;
					} else {
						maxMobileVideos.value = parseInt(import.meta.env.VITE_MAX_MOBILE_USERS);
					}
				} else {
					maxMobileVideos.value = parseInt(import.meta.env.VITE_MAX_MOBILE_USERS);
				}

				const min = mobile.value ? maxMobileVideos.value : props.meetingHandler.useLargeGrid ? 9999 : 9;
				count = Math.min(min, count);

				const minMobileColumns = orientation.value.type.indexOf("landscape") != -1 ? 4 : 2;
				let minColumns = mobile.value ? minMobileColumns : props.meetingHandler.useLargeGrid ? 9999 : 3;

				if (count == 2 && mobile.value && document.body.offsetHeight > document.body.offsetWidth) {
					minColumns = 1;
				} else {
					minColumns = Math.min(minColumns, props.videoCount);
				}

				const columnResult = Math.ceil(Math.sqrt(count));

				if (mobile.value && orientation.value.type.indexOf("landscape") != -1) {
					columns = Math.max(columnResult, minColumns);
				} else {
					columns = Math.min(columnResult, minColumns);
				}

				//columns = Math.min(Math.ceil(Math.sqrt(count)), minColumns);
				rows = Math.ceil(count / columns);
			}

			console.log("grid", rows, columns, count);

			width = `${100 / columns}%`;
			height = `${100 / rows}%`;

			if (
				(props.video?.type != "display" || props.meetingHandler.localScreenSharing) &&
				((rows == 1 && columns == 2) || (count == 1 && mobile.value))
			) {
				if (screenHeight.value < 568 && !mobile.value) {
					height = "100%";
				} else if (screenHeight.value < 768 && !mobile.value) {
					height = "100%";
				} else if (mobile.value && screenHeight.value < document.body.offsetWidth) {
					height = "100%";
					width = "50%";
				} else if (mobile.value) {
					// height = "50%";
				} else {
					height = "50%";
				}
			}
			console.log(height, width);
		}

		style.height = height;
		style.maxHeight = height;
		style.width = width;
		style.maxWidth = width;

		if (props.videoCount <= 2) {
			style.transition = "all .02s";
			style.transitionDelay = "";
		}

		return style;
	});

	async function openMyWorld() {
		console.log("myworld");
		await props.meetingHandler?.openMyWorld(attendee.value?.id);
	}

	async function toggleFullScreen() {
		if (fullScreen.value) {
			await document.exitFullscreen();
		} else {
			videoContainer.value?.requestFullscreen();
			appInsights.trackMetric(
				{
					name: "OpenFullScreen",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);
		}
	}

	function toggleParticipantAudio() {
		props.meetingHandler?.UserActionHandler.toggleUserAudio(attendee.value?.id ?? "");
		//instance?.proxy?.$forceUpdate();
	}

	function toggleParticipantVideo() {
		props.meetingHandler?.UserActionHandler.toggleUserVideo(attendee.value?.id ?? "");
		//instance?.proxy?.$forceUpdate();
	}

	function getUserNameTag() {
		return "";
	}

	function pinUser() {
		props.meetingHandler?.UserActionHandler.pinUser(attendee.value?.id ?? "");
		pinned.value = !pinned.value;
		useEventBus().emitEvent("refresh-ui", null);
	}

	function pinStyle() {
		return {
			active: pinned.value,
		};
	}

	// function onPause() {
	// 	paused.value = true;
	// 	console.debug("Pausing video");

	// 	if (props.video.isLocal) {
	// 		try {
	// 			videoElement.value?.play();
	// 		} catch (err) {
	// 			console.error("Error playing video");
	// 		}
	// 	}
	// }

	// function onPlay() {
	// 	console.debug("Resuming from paused state");
	// 	paused.value = false;
	// }

	function loadedData() {
		alert("loaded");
	}

	async function videoClick(evt: Event) {
		if (props.floating && !props.meetingHandler.localUserMedia.isStarted) {
			await props.meetingHandler.restartLocalMedia();
		}

		if (document.fullscreenElement?.id === videoContainer.value?.id) {
			evt.preventDefault();
			return false;
		}
	}
</script>

<style scoped lang="scss">
	.video-solo {
		height: 100%;
		width: 100%;
		max-height: 100%;
		max-width: 100%;
	}

	.video {
		display: flex;
		overflow: hidden;
		align-items: center;
		flex: 1 1 auto;
		flex-direction: column;
		border: 2px solid #323b4b;
		border-radius: 10px;
		column-gap: 1px;
		position: relative;
		transition: 0.65s all;
		transition-delay: 0.1s;
	}

	.video video,
	.video canvas {
		height: 100%;
		width: 100%;
		object-fit: cover;
		display: flex;
		position: relative;
		border-radius: 8px !important;
		border: 2px solid #323b4b;
	}

	.video canvas.background-image {
	}

	.video canvas {
		z-index: 1;
	}

	.remote-connection-state {
		position: absolute;
		height: 100%;
		width: inherit;
		color: white;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.host-icon {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		position: absolute;
		z-index: 10;
	}

	.host-icon-bg {
		width: fit-content !important;
		width: -moz-min-content !important;
		width: -webkit-min-content !important;
		height: fit-content !important;
		height: -moz-min-content !important;
		height: -webkit-min-content !important;
		background-color: rgba(38, 38, 38, 0.77);
		border-radius: 10px;
		text-align: center;
		padding: 0 20px;
		margin: auto;
		font-size: 2.5em;
		display: inline-flex;
	}

	.large-screen {
		padding: 0 20px;
		margin: auto;
		font-size: 2.5em;
		display: inline-flex;
		justify-content: unset;
		width: fit-content !important;
		width: -moz-min-content !important;
		width: -webkit-min-content !important;
	}

	.icon-container {
		align-items: center;
		z-index: 10;
		bottom: 0;
		position: absolute;
		width: 100%;
		margin-left: 2px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.icon-container-hidden {
		display: none;
	}

	.icon-container-screen {
		text-align: center;
		opacity: 0.9;
		color: white;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		padding-top: 4px;
	}

	.icon-container-screen-local {
		background-color: darkblue;
	}

	.thumbnail video {
		object-fit: cover;
		height: 100%;
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	.user-name-label .audio-toggle-container {
		height: 100%;
	}
	/* TODO: MERGE */
	.user-name-label {
		height: 30px;
		width: var(--userLabelWidth);
		opacity: var(--opacity);
		max-width: 75%;
		background-color: rgba(0, 0, 0, 0.4);
		color: white;
		border-radius: 6px;
		font-weight: normal;
		font-size: 13px;
		font-family: "Inter_Light";
		padding: 6px 8px;
		display: var(--display);
		vertical-align: middle;
		align-items: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: 4px;
		margin-left: 3px;
		transition: opacity 0.35s;
	}

	.user-name-span {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.2;
	}

	.icon-container .raised-hand-icon {
		color: rgb(255, 210, 121);
		margin-left: 5px;
		text-shadow: rgb(0 0 0) 0px 0px 3px;
		padding: 2px;
		font-size: 18px;
		filter: drop-shadow(0 0 1px #000);
	}

	.video-thumbnail-icons-right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 5px;
	}

	.btn-my-world-video-icon {
		background-color: #346ee0 !important;
		height: 28px;
		width: 28px;
		padding: 4px !important;
		border-radius: 50% !important;
		margin-right: 8px;
		margin-left: 6px;
		z-index: 1100;
	}

	.btn-my-world-video-icon:hover {
		background: #2b56ab !important;
		cursor: pointer !important;
	}

	.pin-container:hover {
		background-color: #3a455e;
		cursor: pointer !important;
	}

	.active:hover {
		background: white !important;
		color: #2b56ab !important;
	}

	.pin-container .inactive {
		background-color: black;
		color: white;
	}

	.pin-container {
		width: 28px;
		height: 28px;
		margin-top: unset;
		margin-right: 8px;
	}
	/* TODO: MERGE*/
	.pin-container {
		margin-top: 4px;
		color: white;
		font-weight: bold;
		font-size: 15px;
		border-radius: 50%;
		display: flex !important;
		align-items: center;
		justify-content: center;
		background-color: black;
		cursor: pointer;
	}

	.video-mic-icon {
		margin-left: 6px;
		font-size: 14px;
	}

	.active {
		background: white;
		color: #2b56ab;
		transition: 0.05s all;
	}

	video[type="display"] {
		object-fit: contain;
		background: black;
	}

	video[type="user"] {
		object-fit: cover;
	}

	video[type="user"][fit="contain"] {
		object-fit: contain;
		background: #434c5c;
	}

	.mirror {
		-webkit-transform: scale(-1, 1); /* Safari and Chrome */
		-moz-transform: scale(-1, 1); /* Firefox */
		-ms-transform: scale(-1, 1); /* Internet Explorer */
		-o-transform: scale(-1, 1); /* Opera */
		transform: scale(-1, 1); /* Standard syntax */
	}

	.host-mute {
		margin: 16px;
		cursor: pointer;
	}

	.large-screen-media-icons {
		font-size: 2.5rem;
	}

	.raise-hand {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
		transform: scale(1);
		animation: pulse 2s infinite;
	}

	.disabled {
		color: grey !important;
	}

	.speaking {
		border-color: #ffd279 !important;
	}

	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 #ffd279;
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 5px #ffd279;
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 #ffd279;
		}
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.video-solo {
			height: 40%;
			width: 100%;
			max-height: 40%;
			max-width: 100%;
		}

		.btn-full-screen-mb {
			margin-bottom: 72px;
		}
	}

	@media (orientation: landscape) and (max-height: 420px) {
		.btn-full-screen-mb {
			margin-bottom: 72px;
		}
	}

	.stats-overlay {
		background-color: rgba(255, 255, 255, 0.3);
		position: absolute;
		z-index: 2;
		height: 100%;
		width: 100%;
		padding: 8px;
		font-size: 12px;
		border-radius: 8px;
	}

	.stats-overlay div {
		line-height: 1.25;
	}

	.btn-full-screen {
		height: 32px;
		width: 32px;
		margin-left: 8px;
		margin-right: 8px;
		padding: 0;
		background: none;
		font-size: 20px;
		color: white;
		background-color: #0c0f13;
		border-radius: 50%;
	}

	.btn-full-screen:hover {
		background-color: #3a455e;
		color: white !important;
	}

	video::-webkit-media-controls-fullscreen-button:hover {
		border-radius: 50%;
		background-color: #3a455e !important;
	}

	video::-webkit-media-controls-mute-button,
	video::-webkit-media-controls-overlay-play-button,
	video::-webkit-media-controls-play-button,
	video::-webkit-media-controls-timeline-container,
	video::-webkit-media-controls-current-time-display,
	video::-webkit-media-controls-time-remaining-display,
	video::-webkit-media-controls-timeline,
	video::-webkit-media-controls-volume-slider,
	video::-webkit-media-controls-seek-back-button,
	video::-webkit-media-controls-seek-forward-button,
	video::-webkit-media-controls-rewind-button {
		display: none !important;
	}

	.alt-video-container {
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		background: black;
		border-radius: 8px;
		border: 2px solid #323b4b;
	}

	.initial {
		font-size: 2em;
		background: #191d25;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		min-width: 48px;
		min-height: 48px;
		padding: 10px;
		color: white;
		line-height: 1;
		box-sizing: content-box;
	}

	.initial-medium {
		font-size: 1.75em;
		min-width: 36px;
		min-height: 36px;
	}

	.initial-small {
		font-size: 1.25em;
		min-width: 24px;
		min-height: 24px;
	}

	.alt-video-container .initial:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		padding-top: 100%;
		height: 0;
	}

	.audio-only-muted {
		color: #ff715b;
	}

	.emoji-container {
		z-index: 100;
		height: 28px;
		width: 28px;
		background: rgba(255, 255, 255, 1);
		border-radius: 14px;
		justify-content: center;
		align-items: center;
		display: flex;
		transition: all 0.35s ease-in-out;
	}

	.emoji-container:hover {
		cursor: pointer;
	}

	.emoji-container .emoji {
		font-size: var(--emojiFontSize);
		opacity: 0;
		display: flex;
		justify-content: center;
		width: 0px;
		height: var(--emojiIconSize);
		transition: all 0.35s ease-in-out;
	}
	.emoji-container .emoji .fa-times {
		display: none !important;
	}

	.emoji-container-open .emoji .fa-times {
		display: flex !important;
	}

	.emoji-container .emoji.first {
		opacity: 1;
		width: 24px;
		height: 24px;
	}

	.emoji-container-open {
		// width: 168px;
		width: var(--emojiContainerWidth);
		background: rgba(0, 0, 0, 0.3);
		justify-content: space-between;
	}

	.emoji-container-open .emoji {
		opacity: 1;
		width: var(--emojiIconSize);
		border-radius: 50%;
	}

	.emoji-container-open .emoji:hover {
		cursor: pointer;
		background: rgba(0, 0, 0, 0.3);
	}

	.emoji .fa-times {
		width: unset;
		height: unset;
		font-size: unset !important;
	}

	.emoji.toggle {
		align-items: center;
		width: 28px;
		height: 28px;
		z-index: 100;
	}

	.emoji.toggle-closed {
		width: 0 !important;
	}

	.emoji,
	.tooltip-haver,
	.video-disabled {
		.tooltip-container {
			position: absolute;
			top: -18px;
			pointer-events: none;
			user-select: none;
			z-index: 1;
			.tooltip,
			.tooltip-persistant {
				background: #0f1218;
				padding: 2px 8px;
				border-radius: 10px;

				color: white;
				opacity: 0;
				transition: 0.2s display;
			}

			.tooltip-persistant {
				opacity: 1;
				font-size: 0.7rem;
			}
		}
		&:hover {
			.tooltip {
				opacity: 1;
			}
			.longtip {
				min-width: 200px;
				position: absolute;
				right: 0;
				top: 20px;
				text-align: center;
			}
		}
	}

	.floating {
		position: fixed;
		bottom: 12%;
		left: 0;
		width: 96px !important;
		height: 96px !important;
		border-radius: 50% !important;
		border: none;
		z-index: 100;
		transition: 0.5s all ease-in-out;
		transition-delay: 0.1s;
	}

	.floating video {
		border-radius: 50% !important;
		object-fit: cover !important;
	}

	.floating .icon-container {
		display: none !important;
	}

	.floating-middle {
		top: 50%;
	}

	.video-disabled {
		position: absolute;
		top: 0;
		right: 8px;
		z-index: 999;
	}
	.video-disabled img {
		width: 36px;
		height: 36px;
	}

	.video-paused-background {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 100;

		.message-container {
			display: flex;
			justify-content: center;
			max-width: 100%;
			pointer-events: none;
			user-select: none;
			z-index: 1;
			.message {
				background: #0f1218;
				padding: 4px 6px;
				border-radius: 10px;
				color: white;
				opacity: 1;
				transition: 0.2s display;
				font-size: 0.8rem;
				line-height: 1.1;
			}
			.longtip {
				margin-top: 8px;
				max-width: 95%;
				text-align: center;
			}
		}
	}

	.video-paused-medium {
		.video-paused {
			font-size: 1.25em !important;
			min-width: 20px;
			min-height: 20px;
			max-width: 20px;
			max-height: 20px;
		}

		.message {
			font-size: 0.75rem !important;
		}
	}

	.video-paused-small {
		.video-paused {
			font-size: 1em !important;
			min-width: 16px;
			min-height: 16px;
			max-width: 16px;
			max-height: 16px;
		}

		.message {
			font-size: 0.7rem !important;
		}
	}

	.video-paused {
		font-size: 1.5em;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;
		padding: 10px;
		color: #000;
		line-height: 1;
		box-sizing: content-box;

		z-index: 999;
		img {
			width: 48px;
		}
	}
	.blur-video {
		position: absolute;
		background-color: rgba(255, 255, 255, 0.3);
	}

	.blur-video.remote {
		backdrop-filter: blur(10px);
	}

</style>
