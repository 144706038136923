import APIServiceClient from "@/api/api-service";
import { useCookies } from "vue3-cookies";

export interface IMeetingInviteService {
    sendAsync(model: MeetingInviteModel): Promise<Object | undefined>;
    sendSmsAsync(request: SmsInviteRequest): Promise<any>;
    getMembersAsync(tenantId: string): Promise<TenantMember[]>;
}

export class MeetingInviteService implements IMeetingInviteService {
    private static SendPath: string = 'api/channel/invite';
    private static resolutionApiParams = new URLSearchParams([['code', import.meta.env.VITE_RESOLUTION_CODE]]);

    private static readonly resolutionServiceClient: APIServiceClient = new APIServiceClient(import.meta.env.VITE_RESOLUTION_URL);
    private static readonly apiServiceClient: APIServiceClient = new APIServiceClient(MeetingInviteService.getApiEndpoint());

    public static getApiEndpoint(): string {
        //const endpoint = MeetingInviteService.apiEndpoints[window.location.host];
        const endpoint = import.meta.env.VITE_API_URL;

        if (endpoint) {
            return endpoint;
        }

        throw `API endpoint not found for ${window.location.host}.`;
    }

    async sendAsync(model: MeetingInviteModel): Promise<Object | undefined> {
        let path: string = `${MeetingInviteService.SendPath}?code=${import.meta.env.VITE_RESOLUTION_CODE}`;

        try {
            let response = await MeetingInviteService.resolutionServiceClient.executeRequest<Object>(path, 'POST', model, {
                'Authorization': `bearer ${useCookies().cookies.get('Authorization')}`
            });
            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.resolve(undefined);
        }
    }

    async sendSmsAsync(request: SmsInviteRequest): Promise<any> {
        const path = "api/channel/invite/sms";
        try {
            const response = await MeetingInviteService.apiServiceClient.executeRequest<TenantMember[]>(
                path,
                "POST",
                request,
                {
                    Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
                }
            );

            return response.data;
        } catch (err: any) {
            return {
                error: true,
                message: err.message,
                exception: err
            }
        }
    }

    async getMembersAsync(tenantId: string): Promise<TenantMember[]> {
        if (!tenantId || tenantId === "null") {
            return [];
        }

        const path = `api/TenantUserAccount/${tenantId}/Search`;
        const response = await MeetingInviteService.apiServiceClient.executeRequest<TenantMember[]>(
            path,
            "GET",
            null,
            {
                Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
            }
        );

        return response.data;
    }
}

export interface MeetingInviteModel {
    receiverEmails: string[];
    inviteUrl: string;
    channelPasscode: string;
    inboundNumber: string;
    inviterName: string;
    channelId: string;
    serviceNodeId: string;
    channelKey: string;
    invitedUserAccountIds: string[];
    messageHTML: string;
    messageText: string;
}

export interface SmsInviteRequest {
    PhoneNumber: string;
    Message: string;
}

export interface TenantMember {
    Id: string;
    DisplayName: string;
    UserEmail: string;
}