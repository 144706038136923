<template>
	<div class="text-center date-label" v-if="dateLabelRequired">
		{{ getDate(message.timestamp).toDateString() }}
	</div>
	<div class="chat-message-container d-flex align-items-start flex-column" :class="{ mine: thisMyMessage }">
		<div class="chat-username" v-text="messageDisplayName"></div>
		<div v-if="message.isText" class="chat-message" v-html="messageWithLinks(message.text)"></div>
		<div v-else class="chat-message">
			<a v-if="fileName" class="file-name" v-text="fileName" :href="attachmentSource" :download="fileName" target="_blank"></a>
			<img v-if="message.isImage" :alt="fileName" @click="openPreview" :src="attachmentSource" class="message-image" />
			<video v-if="message.isVideo" @click="openPreview" :src="attachmentSource" class="message-video"></video>
			<div class="message-file">
				<a
					v-if="message.file && message.file?.size > 0"
					class="message-file"
					:href="attachmentSource"
					:download="message.file.name"
					target="_blank">
					{{ `Download` || message.text }}
				</a>
				<span v-else-if="message.isFile && !message.file?.size">Uploading...</span>
			</div>
		</div>
		<div class="chat-date">
			{{ formatDate(message.timestamp) }}
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { computed, type PropType, ref, onMounted } from "vue";
	import type { ChatMessage } from "@liveswitch/sdk";
	import { getAttendeeDisplayName as displayName, localAttendeeId } from "@/composables/useChat";

	const props = defineProps({
		message: {
			type: Object as PropType<ChatMessage>,
			required: true,
		},
		previousMessage: {
			type: Object as PropType<ChatMessage>,
		},
	});

	const emits = defineEmits(["preview"]);
	const thisMyMessage = computed(() => props.message.attendee?.id === localAttendeeId);
	const fileName = ref("");
	const fileExtension = ref("");
	const messageDisplayName = ref("");

	function messageWithLinks(text: string) {
		const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
		return text.replace(exp, "<a href='$1' target='_blank'>$1</a>");
	}

	function setupProperties() {
		fileName.value = props.message.file?.name;
		fileExtension.value = fileName.value?.substring(fileName.value.lastIndexOf(".") + 1)?.toLowerCase() ?? "";
	}

	const dateLabelRequired = computed(
		() =>
			!props.previousMessage || // First message
			getDate(props.previousMessage.timestamp).toDateString() !== getDate(props.message.timestamp).toDateString()
	);

	const attachmentSource = computed(() => props.message.inlineContent ?? URL.createObjectURL(props.message.file));

	onMounted(() => {
		setupProperties();
		messageDisplayName.value = displayName(props.message?.attendee);
	});

	function getDate(date: string | Date): Date {
		let messageDate: Date = new Date();

		if (typeof date === "string") {
			messageDate = new Date(date);
		} else if (date instanceof Date) {
			messageDate = date;
		}

		return messageDate;
	}

	function formatDate(date: any) {
		let messageDate: Date = getDate(date);

		return messageDate.toLocaleTimeString([], {
			hour: "numeric",
			minute: "2-digit",
		});
	}


	if (props.message.type !== "TEXT") {
		const interval = setInterval(() => {
			if (props.message.isFile && props.message.inlineContent) {
				clearInterval(interval);
				setupProperties();
			}
		}, 1000);
	}

    function createObjectURL(file: File) {
        return URL.createObjectURL(file);
    }

	function openPreview() {
		emits("preview", props.message);
	}
</script>
<style lang="scss">
	.chat-message-container {
		justify-items: start;
		&.mine.align-items-start {
			align-items: end !important;
		}
	}

	.mine .chat-message {
		background-color: #346ee0;
		color: white;

		.message-file {
			text-align: center;
			clear: both;
			a {
				color: white;
				text-decoration: none;
			}
		}
		a {
			color: whitesmoke;
			&:hover {
				color: white;
			}
		}
	}

	.chat-message {
		padding: 0.7em;
		font-family: "Inter_Medium";
		background-color: #fff2d8;
		color: #333;
		border-radius: 10px;
		word-wrap: break-word;
		word-break: break-word;

		.message-file {
			text-align: center;

			a {
				color: #333;
				text-decoration: none;
			}
		}
	}

	.chat-username {
		color: #333;
		font-family: "Inter_Medium";
		margin-bottom: 4px;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;
	}

	.chat-date {
		color: #333;
		font-size: 10px;
		margin-bottom: 0.75em;
	}

	img.message-image,
	video.message-video {
		max-width: 100%;
		max-height: 300px;
		cursor: pointer;
	}

	.file-name {
		font-size: small;
		float: right;
		color: currentColor;
		word-break: break-all;

	}
	.file-name::after {
		content: "";
  		display: block;
	}

	.date-label {
		position: sticky;
		top: 0;
		background-color: white;
	}
</style>
