<template>
	<div class="chat-notification">
		<div>
			<div class="attendee">{{ message.attendee.displayName }}</div>
			<div v-if="message.isText" class="message">{{ message.text }}</div>
			<div v-else-if="message.isImage" class="message">Sent an image.</div>
			<div v-else-if="message.isVideo" class="message">Sent a video.</div>
			<div v-else class="message">Sent a file.</div>
		</div>
		<div>
			<button class="outline-btn" @click="openMessage">Reply</button>
			<button type="button" class="close" @click="dismissMessage">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { ChatMessage } from "@liveswitch/sdk";	
	import type { PropType } from "vue";

	const props = defineProps({
		message: {
			type: Object as PropType<ChatMessage>,
			required: true,
		},
		channelId: {
			type: String,
			required: true,
		},
	});

	const emits = defineEmits(["handleMessage"]);

	const dismissMessage = () => {
		emits("handleMessage", props.channelId, false);
	}

	const openMessage = () => {
		emits("handleMessage", props.channelId, true);
	};
</script>

<style scoped>
	.chat-notification {
		width: 250px;
		height: 70px;
		border-radius: 12px;
		background-color: #fff;
		color: #000;
		padding: 10px 0px 10px 10px;
		display: flex;
		margin-top: 15px;
	}

	.chat-notification > div:first-child {
		width: 65%;
	}

	.chat-notification > div:not(:first-child) {
		display: flex;
    justify-content: space-between;
    align-items: end;
	}

	.chat-notification > div:not(:first-child) > button:not(:first-child) {
		margin-bottom: 5px;
	}

	.attendee {
		color: #346ee0;
		font-weight: bold;
	}

	.message {
		text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    white-space: nowrap;
	}

	.outline-btn {
		width: 100%;
    height: 28px;
    border-radius: 10px;
    border: 1px solid transparent;
    padding: 4px 8px;
    font-size: 12px;
    font-family: "Inter_Regular";
    font-weight: bold;
    text-align: center;
    color: #346ee0;
    cursor: pointer;
    background-color: #fff;
		border: 1px solid #346ee0;
		margin-right: 10px;
	}

	@media (max-width: 768px) {
		.chat-notification {
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.28);
		}
	}
</style>