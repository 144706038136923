import APIServiceClient from "@/api/api-service";
import { useCookies } from "vue3-cookies";

export interface LobbyWebhookRequest {
	ChannelKey: string;
	AtteneeName: string;
}

export interface ILobbyWebhookService {
  sendAsync(request: LobbyWebhookRequest): Promise<any>;
}

export class LobbyWebhookService implements ILobbyWebhookService {
  private static SendPath: string = "api/channellobby/webhook";

  private static readonly apiServiceClient: APIServiceClient = new APIServiceClient(LobbyWebhookService.getApiEndpoint());

    public static getApiEndpoint(): string {
        const endpoint = import.meta.env.VITE_API_URL;

        if (endpoint) {
            return endpoint;
        }

        throw `API endpoint not found for ${window.location.host}.`;
    }

    async sendAsync(request: LobbyWebhookRequest): Promise<any> {
      const path: string = LobbyWebhookService.SendPath;

      try {
        const response = await LobbyWebhookService.apiServiceClient.executeRequest<Channel>(
            path,
            "POST",
            request,
            {
                Authorization: `Bearer ${useCookies().cookies.get("Authorization")}`,
            }
        );

        return response.data;
      } catch (err: any) {
          return {
              error: true,
              message: err.message,
              exception: err
          }
      }
  }
}