<template>
	<transition name="fade">
		<div class="custom-modal modal show fade" tabindex="-1" aria-hidden="true" v-show="show">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-body">
						<div class="d-flex justify-content-end mb-2">
							<i class="fal fa-times close" @click="close"></i>
						</div>

						<div class="flex-column">
							<div class="d-flex flex-column" v-if="!showLogin && !showRegistration">
								<span class="guest-sign-in">Looks like you aren't logged in</span>
								<div class="form-content">
									<button id="sign-in-call" class="in-call-world-btn" @click="clickLogin">
										Sign In
									</button>
									<button id="join-call" class="in-call-world-btn" @click="clickRegistration">
										Sign Up
									</button>
									<button id="join-call-guest" class="in-call-world-btn" @click="close">
										{{ joined ? "Continue as a Guest" : "Join Now as Guest" }}
									</button>
								</div>
							</div>
							<div class="d-flex flex-column" v-if="showLogin">
								<div class="main-modal-title-centered">Welcome back to LiveSwitch</div>
								<SignIn
									@onAuthenticated="authenticated"
									:action="'close-modal'"
									:in-meeting="inMeeting" />
							</div>
							<div class="d-flex flex-column" v-if="showRegistration">
								<div class="main-modal-title-centered register-title">Create an Account</div>
								<div class="modal-subtitle">Almost There!</div>
								<SignUp
									@onAuthenticated="authenticated"
									:action="'close-modal'"
									:in-meeting="inMeeting" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import SignIn from "@/components/SignIn.vue";
	import SignUp from "@/components/SignUp.vue";
	import useEventBus from "@/composables/useEventBus";
	import type { ApiSignInResponse } from "@/classes/AuthenticationService";
	const props = defineProps(["show", "body", "actionText", "joined", "inMeeting"]);
	const emits = defineEmits(["execute", "update:show", "update:displayName", "update:externalKey"]);

	const showLogin = ref(false);
	const showRegistration = ref(false);

	function close() {
		emits("update:show", false);
		showLogin.value = false;
		showRegistration.value = false;
	}

	function clickLogin() {
		if (props.inMeeting) {
			showLogin.value = true;
		} else {
			redirectToLogin();
		}
	}

	function clickRegistration() {
		if (props.inMeeting) {
			showRegistration.value = true;
		} else {
			redirectToRegistration();
		}
	}

	function authenticated(response: ApiSignInResponse) {
		emits("update:displayName", localStorage.getItem("Username"));
		emits("update:externalKey", localStorage.getItem("UserAccountId"));
		useEventBus().emitEvent("loading-complete");
		close();
		useEventBus().emitEvent("signed-in-mid-meeting");
		// window.location.reload();
	}

	function redirectToLogin() {
		window.location.assign("/login");
	}

	function redirectToRegistration() {
		window.location.assign("/register");
	}
</script>
<style lang="scss" scoped>
	.custom-modal {
		.modal-dialog {
			width: 50%;
		}

		.modal-title {
			font-family: OrpheusPro_Bold !important;
		}

		.modal-footer,
		.modal-header {
			border: none;
		}

		.modal-body {
			color: #444;
			padding: 1em 4em 2.5em;
		}
		button {
			width: 45%;
			padding: 10px;
		}
	}

	.flex-column {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.form-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	.guest-sign-in {
		font-size: 40px;
		font-family: "Inter_Medium";
		text-align: center;
	}

	.guest-sign-in-info {
		font-size: 20px;
		font-family: "Inter_Medium";
		color: #8a94a6;
		margin-top: 24px;
	}

	#guest-return-btn {
		font-family: "Inter_Medium";
		margin-bottom: 12px;
		text-align: center;
		cursor: pointer;
	}

	.guest-return {
		font-size: 18px;
		font-family: "Inter_Bold";
		color: #346ee0;
	}

	.modal-subtitle {
		font-family: "Inter_Regular";
		font-size: 20px;
		text-align: center;
		color: #8a94a6;
		display: block;
		margin-bottom: 24px;
	}

	#sign-in-call {
		margin-top: 12px;
		margin-bottom: 12px;
	}

	#join-call {
		margin-top: 12px;
		margin-bottom: 24px;
	}

	.guest-or-lbl {
		font-size: 16px;
		font-family: "Inter_Medium";
		color: #8a94a6;
	}

	.in-call-world-btn {
		width: 386px;
		height: 65px;
		flex-grow: 0;
		border-radius: 10px;
		background-color: #346ee0;
		font-size: 18px !important;
		font-family: "Inter_Bold";
		color: white;
		border: none;
	}

	.main-modal-title-centered {
		font-family: "Inter_Medium";
		font-size: 40px;
		text-align: center;
		color: #323b4b;
		margin-bottom: 24px;
	}

	.register-title {
		margin-bottom: 8px;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		.custom-modal {
			.modal-dialog {
				width: 100%;
			}

			.modal-body {
				padding-left: 1em;
				padding-right: 1em;
			}

			button {
				width: 75%;
			}
		}
	}

	@media (max-width: 834px) {
		.custom-modal {
			.modal-dialog {
				width: 100%;
			}
			.modal-body {
				padding-left: 1em;
				padding-right: 1em;
			}
			#sign-in-call,
			#join-call,
			#join-call-guest {
				width: 75%;
			}
		}
	}

	@media (min-width: 576px) and (orientation: landscape) {
		.modal-dialog {
			width: 70%;
		}
	}
</style>
