export class VirtualBackgroundImage{
    public src: string = ''
    public image?: HTMLImageElement;
    public orientation: 'landscape' | 'portrait' = 'landscape'

    public constructor(init?:Partial<VirtualBackgroundImage>) {
        Object.assign(this, init);
        this.image = new Image()
        this.image.src = this.src
    }
}