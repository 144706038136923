<template>
	<div class="debug-container" v-if="containerEnabled && showContainer">
		<div>
			Meeting State:
			<span class="value">
				{{ props.meeting?.state }}
			</span>
		</div>
		<div>
			Meeting Id:
			<span class="value">
				{{ props.meeting?.id }}
			</span>
		</div>
		<div>
			SIP Enabled:
			<span class="value">
				{{ props.meeting?.isSipEnabled }}
			</span>
		</div>
		<div>
			Chat Enabled:
			<span class="value">
				{{ props.meeting?.isChatEnabled }}
			</span>
		</div>
		<div>
			Mute Audio On Join:
			<span class="value">
				{{ props.meeting?.isAudioMutedOnJoin }}
			</span>
		</div>
		<div>
			Mute Video On Join:
			<span class="value">
				{{ props.meeting?.isVideoMutedOnJoin }}
			</span>
		</div>
		<div>
			Unmute Audio Disabled On Join:
			<span class="value">
				{{ props.meetingHandler.meetingAudioDisabled }}
			</span>
		</div>
		<div>
			Unmute Video Disabled On Join:
			<span class="value">
				{{ props.meetingHandler?.meetingVideoDisabled }}
			</span>
		</div>
		<div>
			Recording Enabled:
			<span class="value">
				{{ props.meeting?.isRecordingEnabled }}
			</span>
		</div>
		<div>
			Recording:
			<span class="value">
				{{ props.meeting?.isRecording }}
			</span>
		</div>
		<div>
			Room Id:
			<span class="value">
				{{ props.meeting?.roomId }}
			</span>
		</div>
		<div>
			Room Key:
			<span class="value">
				{{ props.meeting?.roomKey }}
			</span>
		</div>
		<div>
			Local Attendee Id:
			<span class="value">
				{{ props.meetingHandler.localAttendeeId }}
			</span>
		</div>
		<div>
			Local Media Id:
			<span class="value">
				{{ props.meetingHandler.localUserMedia.id }}
			</span>
		</div>
		<div>
			Remote Attendees:
			<span class="value">
				{{
					props.meetingHandler.attendees?.filter((x) => x.id !== props.meetingHandler.localAttendeeId).length
				}}
			</span>
		</div>
		<div>
			Remote Active Attendees:
			<span class="value">
				{{
					props.meetingHandler.attendees?.filter(
						(x) => x.id !== props.meetingHandler.localAttendeeId && x.status === "ACTIVE"
					).length
				}}
			</span>
		</div>
		<div>
			Remote Inactive Attendees:
			<span class="value">
				{{
					props.meetingHandler.attendees?.filter(
						(x) => x.id !== props.meetingHandler.localAttendeeId && x.status === "INACTIVE"
					).length
				}}
			</span>
		</div>
		<div>
			Remote SIP Attendees:
			<span class="value">
				{{
					props.meetingHandler.attendees?.filter(
						(x) => x.id !== props.meetingHandler.localAttendeeId && x.type === "SIPINBOUND"
					).length
				}}
			</span>
		</div>
		<div>
			Visible User Medias:
			<span class="value">
				{{ props.meetingHandler.visibleUserMedias?.length }}
			</span>
		</div>
		<div>
			Visible Display Medias:
			<span class="value">
				{{ props.meetingHandler.visibleDisplayMedias?.length }}
			</span>
		</div>
		<div>
			Remote Screen Sharing:
			<span class="value">
				{{ props.meetingHandler.remoteScreenSharing }}
			</span>
		</div>
		<div>
			Local Screen Sharing:
			<span class="value">
				{{ props.meetingHandler.localScreenSharing }}
			</span>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { onMounted, ref, type PropType } from "vue";
	import { videoStatsEnabled } from "@/composables/useLocalStorage";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import type { Meeting } from "@liveswitch/sdk";

	const containerEnabled = ref(import.meta.env.VITE_VIDEO_STATS_ENABLED == "true" || videoStatsEnabled.value);
	const showContainer = ref(false);

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		meeting: {
			type: Object as PropType<Meeting>,
			rquired: true,
		},
	});

	onMounted(() => {
		document.onkeyup = function (e) {
			if (e.ctrlKey && e.key == "9") {
				showContainer.value = !showContainer.value;
			}
		};
	});
</script>
<style scoped>
	.debug-container {
		background: white;
		color: black;
		font-size: 12px;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1000;
	}

	.debug-container .value {
		font-weight: bold;
	}
</style>
