import { BadRequestError } from "@liveswitch/sdk";

export default function useErrorHelpers() {
	const cameraErrorMessage = "Your camera might already be use. Please close any applications that are using your camera and try again."
	const microphoneErrorMessage = "Your microphone might already be use. Please close any applications that are using your microphone and try again."
	const mediaErrorMessage = "Your camera or microphone might already be use. Please close any applications that are using your camera or microphone and try again."
	const notFoundErrorMessage = "Your camera or microphone could not be started. Please make sure the devices are plugged in and try again.";

	const audioStartError = /Could not start audio source|Starting audioinput failed/i;
	const videoStartError = /Could not start video source|Starting videoinput failed/i;
	const deviceNotFoundError = /Requested device not found/i;
	const notFoundError = "NotFoundError";
	const notReadbleError = "NotReadableError";
	const abortError = "AbortError";
	const blockedFromLobbyMessage = "Blocked from lobby.";
	const badRequestError = "BadRequestError";

	return {
		isMediaError(exception: Error): boolean {
			const name = exception.name;

			if (name === notFoundError || name === notReadbleError || name == abortError) {
				return true;
			}

			return false;
		},
		getMediaErrorMessage(exception: Error): string {
			const name = exception.name;
			const message = exception.message;
			let errorMessage = "";

			if (name === notFoundError) {
				errorMessage = notFoundErrorMessage;
			} else if (name === abortError || name === notReadbleError) {
				if (audioStartError.test(message)) {
					errorMessage = microphoneErrorMessage;
				} else if (videoStartError.test(message)) {
					errorMessage = cameraErrorMessage;
				} else {
					errorMessage = mediaErrorMessage;
				}
			}

			return errorMessage;
		},
		isWrongPasscodeError(exception: any): boolean {
			return (
				exception instanceof BadRequestError &&
				exception.requestFields.filter((r) => r.messages.indexOf("The room requires a passcode.") !== -1)
					.length > 0
			);
		}, 
		isBlockedFromLobbyError(exception: Error): boolean { 
			const message = exception.message; 
 
			if (message === blockedFromLobbyMessage) { 
				return true; 
			} 
 
			return false; 
		}
	};
}